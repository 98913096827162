var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('div',{staticClass:"back"},[_c('v-row',{staticClass:"ml-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{staticClass:"mr-2",style:({'color': _vm.link}),attrs:{"to":{ name: 'reports' }}},[_vm._v("Reportes de usuarios")])],1)],1)],1),_c('br'),_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('p',{staticClass:"font-weight-light title pt-1",attrs:{"aria-label":"tabla métricas"}},[_vm._v("Reportes de "+_vm._s(this.$route.params.name)+" "+_vm._s(this.$route.params.surname))]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar plan de intervención"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ 'background-color': _vm.color}),attrs:{"dark":"","search":_vm.search,"headers":_vm.headers,"items":_vm.interventionplan,"items-per-page":5,"footer-props":{'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Registros por página',
      'items-per-page-options': [5,10, -1],
      'show-current-page': true,
      'pagination': {}
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.videogame_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.videogames),function(videogame){return _c('div',{key:videogame.id},[(videogame.id === item.videogame_id)?_c('div',[_vm._v(_vm._s(videogame.name))]):_vm._e()])})}},{key:"item.session",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reporte de sesiones del usuario","to":{name: 'session-reports', params:{id_iplan:item.id, game_name: item.videogame_id,  ability_id: item.ability_id}}}},[_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Tabla de sesiones")])]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reporte gráfico de sesiones del usuario","to":{name: 'session-graphic', params:{  id_iplan:item.id, game_name: item.videogame_id,    ability_id: item.ability_id}}}},[_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-poll-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Gráfico de sesiones")])])]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{style:({'color': _vm.icon_color}),attrs:{"aria-label":"Enlace que redirige a la página de reporte de evaluación del usuario","to":{name: 'printeable-reports', params:
          {
            id_iplan:item.id,
            game_name: item.videogame_id,
            ability_id: item.ability_id
          }
          }}},[_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Tabla de evaluaciones")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }