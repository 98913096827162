import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getAbilities(id) {
    return apiClient.get('/organizations/' + id + '/abilities')
  },
  postAbility(id, ability) {
    return apiClient.post('/organizations/' + id + '/abilities', ability)
  },
  putAbility(ability) {
    return apiClient.put('/abilities/' + ability.id, ability)
  },
  deleteAbility(ability) {
    return apiClient.delete('/abilities/' + ability.id)
  }
}
