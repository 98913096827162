import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getGamesGraphicReports(item) {

        return apiClient.get('/gameGraphicReport/' + item.player_id + '&' + item.period_id + "/graphic-report")
    },
    getGamesGraphicFiltredReports(item) {
        if (item.game_id) {
            return apiClient.get('/gameGraphicReport/' + item.player_id + '&' + item.period_id + "/graphic-report" + "?game_id=" + item.game_id)
        }
        else {
            return apiClient.get('/gameGraphicReport/' + item.player_id + '&' + item.period_id + "/graphic-report")
        }
    }
}