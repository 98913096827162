<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-text-field
                  :color="icon_color"
                  v-model="item.name"
                  label="Forma de evaluacion"
                  v-validate="'required|max:45'"
                  data-vv-name="name"
                  :error-messages="errors.collect('name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1
        ? 'Nueva métrica de evaluación'
        : 'Editar métrica de evaluación'
    },
  },
  data() {
    return {
      dialog: false,
      dictionary: {
        custom: {
          name: {
            required: () => 'El nombre de la métrica no puede ser vacio',
            max: () => 'El campo debe tener máximo 45 caracteres',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          // eslint-disable-next-line
          console.log(this.item)
          this.$store.dispatch(
            'qualificationscale/updateQualification_Scales',
            this.item
          )
        } else {
          this.$store.dispatch(
            'qualificationscale/createQualification_Scales',
            this.item
          )
          // eslint-disable-next-line
          console.log(this.item)
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>