import PeriodService from '@/services/manager/PeriodService'

export const namespaced = true

export const state = {
    periods: [],
    period: {}
}

export const mutations = {
    UPDATE_PERIOD(state, period) {
        state.periods = [
            ...state.periods.filter(
                element => element.id !== period.id
            ),
            period
        ]
    },
    DELETE_PERIOD(state, period) {
        const index = state.periods.indexOf(period)
        state.periods.splice(index, 1)
    },
    SET_PERIODS(state, periods) {
        state.periods = periods
    },
    SET_PERIOD(state, period) {
        state.period = period
    }
}

export const actions = {
    createPeriod({ dispatch }, period) {
        return PeriodService.postPeriod(
            period.id,
            period.item
        )
            .then(() => {
                dispatch('fetchPeriods', period.id)
            })
            .catch(error => {
                throw error
            })
    },
    updatePeriod({ commit }, period) {
        return PeriodService.putPeriod(period)
            .then(() => {
                commit('UPDATE_PERIOD', period)
            })
            .catch(error => {
                throw error
            })
    },
    deletePeriod({ commit }, period) {
        return PeriodService.deletePeriod(period)
            .then(() => {
                commit('DELETE_PERIOD', period)
            })
            .catch(error => {
                throw error
            })
    },
    fetchPeriods({ commit }, id) {
        PeriodService.getPeriods(id)
            .then(response => {
                commit('SET_PERIODS', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}