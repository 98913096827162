<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de reportes"
        >
          <p>Reportes</p>
        </caption>
        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar usuario"
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        v-model="selected"
        :items="players"
        :items-per-page="10"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [10, 20, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.first_name="{ item }">
          <div>{{ item.first_name }} {{ item.last_name }}</div>
        </template>
        <template v-slot:item.ability="{ item }">
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'abilityReport',
              params: {
                player_id: item.id,
                period_id: lastPeriod,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-file-document</v-icon
                >
              </template>
              <span>Reporte de habilidades</span>
            </v-tooltip>
          </router-link>
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'abilityGraphicReport',
              params: {
                player_id: item.id,
                period_id: lastPeriod,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-poll-box</v-icon
                >
              </template>
              <span>Reporte gráfico de habilidades</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.games="{ item }">
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'gameReport',
              params: {
                player_id: item.id,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-file-document</v-icon
                >
              </template>
              <span>Reporte de videojuegos</span>
            </v-tooltip>
          </router-link>
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'gameGraphicReport',
              params: {
                player_id: item.id,
                period_id: lastPeriod,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-poll-box</v-icon
                >
              </template>
              <span>Reporte gráfico de videojuegos</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.plans="{ item }">
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'interventionPlanReport',
              params: {
                player_id: item.id,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-file-document</v-icon
                >
              </template>
              <span>Reporte de planes de intervención</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.abilitypergame="{ item }">
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'abilityPerGameReport',
              params: {
                player_id: item.id,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-poll-box</v-icon
                >
              </template>
              <span>Reporte gráfico de habilidades por juego</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.work="{ item }">
          <router-link
            aria-label="Enlace que redirige a la página de reportes del usuario"
            :to="{
              name: 'workReport',
              params: {
                player_id: item.id,
                period_id: lastPeriod,
              },
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-file-document</v-icon
                >
              </template>
              <span>Reporte de trabajo</span>
            </v-tooltip>
          </router-link>
        </template>

        <template slot="items" slot-scope="props">
          <tr :active="props.selected" @click="select(props.item)">
            <td>{{ props.item.name }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-right">{{ props.item.id }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'

export default {
  mixins: [deleteMixin],
  data() {
    return {
      search: '',
      selected: [],
      num: '',
      dialog: false,
      headers: [
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'first_name',
        },
        {
          text: 'HABILIDADES',
          value: 'ability',
          align: 'right',
          width: '150px',
        },
        {
          text: 'VIDEOJUEGOS',
          value: 'games',
          align: 'right',
          width: '150px',
        },
        {
          text: 'HABILIDAD POR JUEGO',
          value: 'abilitypergame',
          align: 'right',
          width: '150px',
        },
        {
          text: 'PLANES DE INTERVENCIÓN',
          value: 'plans',
          width: '200px',
          align: 'right',
        },
        {
          text: 'TRABAJO',
          value: 'work',
          align: 'right',
          width: '100px',
        },
      ],
    }
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'tutor-layout')
    this.$store.dispatch('player/fetchPlayers', this.$route.params.id)
    this.$store.dispatch('period/fetchPeriods', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      players: (state) => state.player.players,
      periods: (state) => state.period.periods,
    }),
    lastPeriod() {
      var lastPeriod = 0
      if (this.periods[this.periods.length - 1]) {
        lastPeriod = this.periods[this.periods.length - 1].id
      }
      return lastPeriod
    },
  },
  methods: {
    log() {
      // eslint-disable-next-line
      console.log('click en columna')
    },
  },
}
</script>
<style scoped></style>
