import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getVideogames() {
    return apiClient.get('/videogames')
  },

  getVideogame(id) {
    return apiClient.get('/videogames/' + id)
  },

  postVideogame(videogame) {
    return apiClient.post('/videogames', videogame)
  },

  putVideogame(videogame) {
    return apiClient.put('/videogames/' + videogame.id, videogame)
  },

  deleteVideogame(videogame) {
    return apiClient.delete('/videogames/' + videogame.id)
  }
}
