var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"tabla de reportes"}},[_c('p',[_vm._v("Reportes")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar usuario"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","search":_vm.search,"headers":_vm.headers,"items":_vm.players,"items-per-page":10,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [10, 20, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]}},{key:"item.ability",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'abilityReport',
            params: {
              player_id: item.id,
              period_id: _vm.lastPeriod,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Reporte de habilidades")])])],1),_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'abilityGraphicReport',
            params: {
              player_id: item.id,
              period_id: _vm.lastPeriod,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-poll-box")])]}}],null,true)},[_c('span',[_vm._v("Reporte gráfico de habilidades")])])],1)]}},{key:"item.games",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'gameReport',
            params: {
              player_id: item.id,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Reporte de videojuegos")])])],1),_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'gameGraphicReport',
            params: {
              player_id: item.id,
              period_id: _vm.lastPeriod,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-poll-box")])]}}],null,true)},[_c('span',[_vm._v("Reporte gráfico de videojuegos")])])],1)]}},{key:"item.plans",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'interventionPlanReport',
            params: {
              player_id: item.id,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Reporte de planes de intervención")])])],1)]}},{key:"item.abilitypergame",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'abilityPerGameReport',
            params: {
              player_id: item.id,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-poll-box")])]}}],null,true)},[_c('span',[_vm._v("Reporte gráfico de habilidades por juego")])])],1)]}},{key:"item.work",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reportes del usuario","to":{
            name: 'workReport',
            params: {
              player_id: item.id,
              period_id: _vm.lastPeriod,
            },
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Reporte de trabajo")])])],1)]}},{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected},on:{"click":function($event){return _vm.select(props.item)}}},[_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.id))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }