import DailyPlanService from '@/services/manager/DailyPlanService'

export const namespaced = true

export const state = {
  daily_plans: []
}

export const mutations = {
  SET_DAILY_PLANS(state, daily_plans) {
    state.daily_plans = daily_plans
  }
}

export const actions = {
  fetchDailyPlans({ commit }, dailyplan) {
    DailyPlanService.getDailyPlan(dailyplan)
      .then(response => {
        commit('SET_DAILY_PLANS', response.data)
        // eslint-disable-next-line
        console.log(response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
