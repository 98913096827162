import VocabularyService from '@/services/laboral-inclusion/VocabularyService'

export const namespaced = true

export const state = {
  vocabulary: [],
  word: {}
}
export const mutations = {
  SET_VOCABULARY(state, vocabulary) {
    state.vocabulary = vocabulary
  },
  SET_WORD(state, word) {
    state.word = word
  },
  UPDATE_VOCABULARY(state, word) {
    state.vocabulary = [
      ...state.vocabulary.filter(
        element => element.id !== word.id
      ),
      word
    ]
  }
  ,
  DELETE_VOCABULARY(state, word) {
    const index = state.vocabulary.indexOf(word)
    state.vocabulary.splice(index, 1)
  }
}
export const actions = {
  createVocabulary({ dispatch, commit }, word) {
    return VocabularyService.postVocabulary(word.id, word.item)
      .then(response => {
        dispatch('fetchVocabulary', word.id)
        commit('SET_WORD', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  createImage({ dispatch, commit }, image) {
    return VocabularyService.postImage(image.id, image.formData).then(
      response => {
        dispatch('fetchVocabulary', image.history_id)
        //eslint-disable-next-line
        // console.log(response.data)
        commit('UPDATE_VOCABULARY', response.data[0])
      }
    )
  },
  fetchVocabulary({ commit }, id) {
    VocabularyService.getVocabulary(id)
      .then(response => {
        commit('SET_VOCABULARY', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  fetchAllVocabulary({ commit }) {
    VocabularyService.getAllVocabulary()
      .then(response => {
        commit('SET_VOCABULARY', response.data)
      })
      .catch(error => {
        throw error
      })
  }
  ,
  updateVocabulary({ commit }, word) {
    return VocabularyService.putVocabulary(word)
      .then(() => {
        commit('UPDATE_VOCABULARY', word)
      })
      .catch(error => {
        throw error
      })
  },
  deleteVocabulary({ commit }, word) {
    return VocabularyService.deleteVocabulary(word)
      .then(() => {
        commit('DELETE_VOCABULARY', word)
      })
      .catch(error => {
        throw error
      })
  }
}