import apiClient from '../configServices'

export default {
  getVocabulary(id) {
    return apiClient.get('/histories/' + id + '/words')
  },
  getAllVocabulary() {
    return apiClient.get('/words')
  },
  postVocabulary(id, vocabulary) {
    return apiClient.post('/histories/' + id + '/words', vocabulary)
  },
  postImage(id, formData) {
    return apiClient.post('/words/uploads/' + id, formData)
  },
  putVocabulary(vocabulary) {
    return apiClient.put('/words/' + vocabulary.id, vocabulary)
  },
  deleteVocabulary(vocabulary) {
    return apiClient.delete('/words/' + vocabulary.id)
  }
}