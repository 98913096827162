import SessionService from '@/services/manager/SessionService'

export const namespaced = true

export const state = {
  sessions: [],
  session: {}
}
export const mutations = {
  UPDATE_SESSION(state, session) {
    state.sessions = [
      ...state.sessions.filter(element => element.id !== session.id),
      session
    ]
  },
  DELETE_SESSION(state, session) {
    const index = state.sessions.indexOf(session)
    state.sessions.splice(index, 1)
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions
  },
  SET_SESSION(state, session) {
    state.session = session
  }
}
export const actions = {
  createSession({ dispatch }, session) {
    return SessionService.postSession(session.id, session.item)
      .then(() => {
        dispatch('fetchSessions', session.id)
      })
      .catch(error => {
        throw error
      })
  },
  updateSession({ commit }, session) {
    return SessionService.putSession(session)
      .then(() => {
        commit('UPDATE_SESSION', session)
      })
      .catch(error => {
        throw error
      })
  },
  deleteSession({ commit }, session) {
    return SessionService.deleteSession(session)
      .then(() => {
        commit('DELETE_SESSION', session)
      })
      .catch(error => {
        throw error
      })
  },
  fetchSessions({ commit }, id) {
    SessionService.getSession(id)
      .then(response => {
        commit('SET_SESSIONS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}