import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getEvaluations(id) {
    return apiClient.get('/intervention-plans/' + id + '/evaluations')
  },
  postEvaluation(id, evaluation) {
    return apiClient.post(
      '/intervention-plans/' + id + '/evaluations',
      evaluation
    )
  }
}
