<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="900px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form
            acction="/organizations"
            method="post"
            enctype="multipart/form-data"
          >
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :color="icon_color"
                  v-model="item.name"
                  label="Nombre"
                  v-validate="'required|max:100'"
                  data-vv-name="name"
                  :error-messages="errors.collect('name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  :color="icon_color"
                  v-model="item.address"
                  label="Dirección"
                  v-validate="'required|max:100'"
                  data-vv-name="address"
                  :error-messages="errors.collect('address')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.email"
                  label="Correo"
                  v-validate="'email'"
                  data-vv-name="email"
                  :error-messages="errors.collect('email')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.country"
                  :items="countries"
                  label="País"
                  item-text="option"
                  item-value="value"
                  dense
                  v-validate="'max:30'"
                  data-vv-name="country"
                  :error-messages="errors.collect('country')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.organization_type_id"
                  label="Tipo de organización"
                  :items="selectOrganizations"
                  item-text="label"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="organization_type_id"
                  :error-messages="errors.collect('organization_type_id')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  maxlength="9"
                  :color="icon_color"
                  v-model="item.telephone"
                  label="Teléfono"
                  v-validate="'numeric|digits:9'"
                  data-vv-name="telephone"
                  type="number"
                  step=".1"
                  :error-messages="errors.collect('telephone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  maxlength="10"
                  :color="icon_color"
                  v-model="item.cellphone"
                  label="Celular"
                  v-validate="'numeric|digits:10'"
                  data-vv-name="cellphone"
                  :error-messages="errors.collect('cellphone')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.representative_name"
                  label="Nombre del representante"
                  v-validate="'max:60|alpha_spaces'"
                  data-vv-name="representative_name"
                  :error-messages="errors.collect('representative_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.contact_name"
                  label="Nombre del contacto"
                  v-validate="'max:60|alpha_spaces'"
                  data-vv-name="contact_name"
                  :error-messages="errors.collect('contact_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.contact_email"
                  label="Correo del contacto"
                  v-validate="'email'"
                  data-vv-name="contact_email"
                  :error-messages="errors.collect('contact_email')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-textarea
                  rows="3"
                  :color="icon_color"
                  v-model="item.description"
                  label="Misión"
                  v-validate="'max:1024'"
                  data-vv-name="description"
                  :error-messages="errors.collect('description')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-file-input
                  :color="icon_color"
                  prepend-icon="mdi-cloud-upload"
                  accept="image/*"
                  v-model="file"
                  label="Logo"
                  value="file"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <p>Activar</p>
                <v-switch
                  :color="icon_color"
                  v-model="item.state"
                  aria-label="activar estado de organización"
                ></v-switch>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import countries from '../../Catalogue/countries'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  created() {
    this.$store.dispatch('organizationtype/fetchOrganizationTypes')
    this.$store.dispatch('organization/fetchOrganizations')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      organizationTypes: (state) => state.organizationtype.organizationTypes,
      organization: (state) => state.organization.organization,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1 ? 'Nueva organización' : 'Editar organización'
    },
    selectOrganizations() {
      return this.organizationTypes.map((element) => ({
        value: element.id,
        label: element.name,
      }))
    },
  },
  data() {
    return {
      dialog: false,
      file: [],
      countries: countries,
      dictionary: {
        custom: {
          name: {
            required: () => 'El campo nombre no puede ser vacío',
            max: () => 'El nombre puede tener máximo 100 caracteres',
          },
          address: {
            required: () => 'El campo dirección no puede ser vacío',
            max: () => 'La dirección puede tener máximo 100 caracteres',
          },
          email: {
            email: () => 'El correo debe ser un correo válido',
          },
          country: {
            max: () => 'El nombre de páis puede tener máximo 30 caracteres',
          },
          organization_type_id: {
            required: () => 'Debe seleccionar una opción',
          },
          telephone: {
            numeric: () => 'El campo debe contener solo números',
            digits: () => 'El campo debe contener 9 dígitos.',
          },
          cellphone: {
            numeric: () => 'El campo debe contener solo números',
            digits: () => 'El campo debe contener 10 dígitos.',
          },
          representative_name: {
            max: () => 'El respresentante puede tener máximo 60 caracteres',
            alpha_spaces: () => 'El nombre debe contener solo letras',
          },
          contact_name: {
            max: () => 'El contacto puede tener máximo 60 caracteres',
            alpha_spaces: () => 'El nombre debe contener solo letras',
          },
          contact_email: {
            email: () => 'El correo debe ser un correo válido',
          },
          description: {
            max: () => 'El respresentante puede tener máximo 1024 caracteres',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          await this.$store.dispatch(
            'organization/updateOrganization',
            this.item
          )

          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('organization/createImage', {
                id: this.item.id,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        } else {
          await this.$store.dispatch(
            'organization/createOrganization',
            this.item
          )
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('organization/createImage', {
                id: this.organization.insertId,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped></style>
