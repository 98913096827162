import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getPeriods(id) {
        return apiClient.get('/organizations/' + id + '/periods')
    },
    postPeriod(id, period) {
        return apiClient.post('/organizations/' + id + '/periods', period)
    },
    putPeriod(period) {
        return apiClient.put('/periods/' + period.id, period)
    },
    deletePeriod(period) {
        return apiClient.delete('/periods/' + period.id)
    }
}
