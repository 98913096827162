<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12">
          <router-link
            :to="{ name: 'qualification-scale' }"
            :style="{ color: link }"
            >Métricas de evaluación</router-link
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-1"
          aria-label="tabla métricas"
        >
          <p>Valores {{ this.$route.params.name }}</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            small
            fab
            dark
            class="ml-2"
            @click="newItem"
            aria-label="Crear nueva métrica"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear valor</span>
            </v-tooltip>
          </v-btn>
          <QualificationCodeForm
            :item="editedItem"
            v-model="dialog"
            :index="editedIndex"
            :disabled="disabled"
          />
        </template>
        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar valores"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        :items="evaluationCode"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon small :color="icon_color" v-on="on" @click="remove(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import QualificationCodeForm from '../../components/admin/QualificationCodeForm'

export default {
  mixins: [deleteMixin],
  components: {
    QualificationCodeForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'administrator-layout')
    this.$store.dispatch(
      'qualificationcode/fetchQualification_Code',
      this.$route.params.id
    )
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      evaluationCode: (state) => state.qualificationcode.qualification_codes,
    }),
  },
  data() {
    return {
      search: '',
      dialog: false,
      disabled: false,
      headers: [
        {
          text: 'VALOR',
          align: 'left',
          value: 'value',
        },
        { text: 'DESCRIPCIÓN', value: 'description' },
        { text: 'ACCIONES', value: 'action', sortable: false, width: '100px' },
      ],
      register: 'qualificationcode/deleteQualification_Code',
      editedIndex: -1,
      defaultItem: {
        id: null,
        qualification_scale_id: '',
        value: '',
        description: '',
      },
      editedItem: {
        id: null,
        qualification_scale_id: '',
        value: '',
        description: '',
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.evaluationCode.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.disabled = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      this.disabled = false
    },
    remove(item) {
      if (item.state === 1 || item.state === true) {
        this.num = 1
        this.deleteItem(item, this.register, item.value, this.num)
      } else {
        this.num = 0
        this.deleteItem(item, this.register, item.value, this.num)
      }
    },
  },
}
</script>

<style scoped>
</style>