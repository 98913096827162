import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getAbilityPerGameReports(item) {
        return apiClient.get('/player/' + item.player_id + '/period/' + item.period_id + "/ability/" + item.ability_id +
            "/game/" + item.game_id + "/ability-game-graphic-report")
    }
}