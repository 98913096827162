import AbilityReportService from '@/services/reports/AbilityReportService'

export const namespaced = true

export const state = {
    report: {},
    filterdReport: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    },
    SET_FILTERED_REPORT(state, filterdReport) {
        state.filterdReport = filterdReport
    }
}

export const actions = {
    fetchAbilityreport({ commit }, id) {
        AbilityReportService.getAbilitiesReports(id)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
    ,
    fetchAbilityFilteredReport({ commit }, item) {
        AbilityReportService.getFilteredReport(item)
            .then(response => {
                commit('SET_FILTERED_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}