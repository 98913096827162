import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  NProgress.set(1)
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getOrganizations() {
    return apiClient.get('/organizations')
  },
  getOrganization(id) {
    return apiClient.get('/organizations/' + id)
  },
  postImage(id, formData) {
    return apiClient.post('/organizations/uploads/' + id, formData)
  },
  postOrganization(organization) {
    return apiClient.post('/organizations', organization)
  },
  putOrganization(organization) {
    return apiClient.put('/organizations/' + organization.id, organization)
  },
  deleteOrganization(organization) {
    return apiClient.delete('/organizations/' + organization.id)
  }
}
