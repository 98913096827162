import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getTutors(id) {
    return apiClient.get('/organizations/' + id + '/tutors')
  },
  postTutor(id, tutor) {
    return apiClient.post('/organizations/' + id + '/tutors', tutor)
  },
  putTutor(tutor) {
    return apiClient.put('/tutors/' + tutor.id, tutor)
  },
  deleteTutor(tutor) {
    return apiClient.delete('/tutors/' + tutor.id)
  }
}
