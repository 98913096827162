import AbilityService from '@/services/manager/AbilityService'

export const namespaced = true

export const state = {
  abilities: [],
  ability: {}
}

export const mutations = {
  UPDATE_ABILITY(state, ability) {
    state.abilities = [
      ...state.abilities.filter(
        element => element.id !== ability.id
      ),
      ability
    ]
  },
  DELETE_ABILITY(state, ability) {
    const index = state.abilities.indexOf(ability)
    state.abilities.splice(index, 1)
  },
  SET_ABILITIES(state, abilities) {
    state.abilities = abilities
  },
  SET_ABILITY(state, ability) {
    state.ability = ability
  }
}

export const actions = {
  createAbility({ dispatch }, ability) {
    return AbilityService.postAbility(
      ability.id,
      ability.item
    )
      .then(() => {
        dispatch('fetchAbilities', ability.id)
      })
      .catch(error => {
        throw error
      })
  },
  updateAbility({ commit }, ability) {
    return AbilityService.putAbility(ability)
      .then(() => {
        commit('UPDATE_ABILITY', ability)
      })
      .catch(error => {
        throw error
      })
  },
  deleteAbility({ commit }, ability) {
    return AbilityService.deleteAbility(ability)
      .then(() => {
        commit('DELETE_ABILITY', ability)
      })
      .catch(error => {
        throw error
      })
  },
  fetchAbilities({ commit }, id) {
    AbilityService.getAbilities(id)
      .then(response => {
        commit('SET_ABILITIES', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
