import GameReportService from '@/services/reports/GameReportService'

export const namespaced = true

export const state = {
    report: {}
    ,
    filterdReport: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    }
    ,
    SET_FILTERED_REPORT(state, filterdReport) {
        state.filterdReport = filterdReport
    }
}

export const actions = {
    fetchGameReport({ commit }, id) {
        GameReportService.getGameReport(id)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchGameFilteredReport({ commit }, item) {
        GameReportService.getFilteredReport(item)
            .then(response => {
                commit('SET_FILTERED_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}