<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col v-if="this.index > -1" cols="12" xs="6" sm="12">
                <v-file-input
                  v-model="file"
                  label="Imagen"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  prepend-icon="mdi-camera"
                  v-validate="'size:2000'"
                  :color="icon_color"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
              <v-col cols="12" xs="6" sm="12" v-else>
                <v-file-input
                  v-model="file"
                  label="Imagen"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  prepend-icon="mdi-camera"
                  :color="icon_color"
                  v-validate="'required|size:2000'"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-textarea
                  v-model="item.description"
                  label="Descripción"
                  auto-grow
                  rows="4"
                  row-height="30"
                  data-vv-name="description"
                  v-validate="'required|max:1024'"
                  maxlength="1024"
                  :color="icon_color"
                  :error-messages="errors.collect('description')"
                  hint="obligatorio"
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12" v-if="index === -1">
                <v-select
                  v-model="item.order"
                  :items="orderNumberAviable()"
                  label="Orden"
                  item-text="label"
                  item-value="value"
                  data-vv-name="order"
                  v-validate="'required'"
                  :error-messages="errors.collect('order')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" xs="6" sm="12" v-else>
                <v-select
                  v-model="item.order"
                  :items="options"
                  label="Orden"
                  item-text="label"
                  item-value="value"
                  data-vv-name="order"
                  v-validate="'required'"
                  :error-messages="errors.collect('order')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" xs="6" sm="12">
                <span class="text-left mb-0 blue--text">{{ information }}</span>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      sequence: (state) => state.sequence.Sequence,
      lamine: (state) => state.sequence.lamine,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.description[0]
    },
    formTitle() {
      return this.index === -1 ? 'Nueva Lámina' : 'Editar Lámina'
    },
  },
  created() {
    this.$store.dispatch('sequence/fetchLamines', this.$route.params.history_id)
  },
  data() {
    return {
      file: [],
      max: 30,
      information:
        '* El tamaño de la imagen debe ser menor a 2MB, y con dimensión de 750px X 500px',
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      fileRules: [
        (value) =>
          !value || value.size < 2000000 || 'La imagen debe ser menor de 2 MB!',
      ],
      dialog: false,
      dictionary: {
        custom: {
          description: {
            required: () => 'La descripción de la lámina no puede ser vacío',
            max: () =>
              'La descripción de la lámina debe ser máximo de 1024 caracteres',
          },
          order: {
            required: () => 'Selecionar el orden de la lámina',
          },
          image: {
            required: () => 'La imagen no puede ser vacía',
            size: () => 'El tamaño de la imagen debe ser menor a 2MB',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.file = []
      this.item.description = ''
      this.item.order = []
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          let lamine = {
            id: this.item.id,
            image: this.item.image,
            description: this.item.description,
            order: this.item.order,
          }
          await this.$store.dispatch('sequence/updateLamines', lamine)
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('sequence/createImage', {
                id: this.item.id,
                history_id: this.$route.params.history_id,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        } else {
          let lamine = {
            description: this.item.description,
            order: this.item.order,
          }
          await this.$store.dispatch('sequence/createLamines', {
            id: this.$route.params.history_id,
            item: lamine,
          })
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('sequence/createImage', {
                id: this.lamine.insertId,
                history_id: this.$route.params.history_id,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        }
        this.$validator.reset()
        this.close()
      }
    },
    orderNumberAviable() {
      var numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      for (var i = 0; i < numbers.length; i++) {
        for (var j = 0; j < this.sequence.length; j++) {
          if (this.sequence[j].order === numbers[i]) {
            numbers.splice(i, 1)
          }
        }
      }
      return numbers
    },
  },
}
</script>
<style scoped>
</style>