<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-2"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  :color="icon_color"
                  id="text-field"
                  v-model="item.name"
                  label="Nombre"
                  v-validate="'required|max:100'"
                  data-vv-name="name"
                  :error-messages="errors.collect('name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  created() {
    this.$store.dispatch('ability/fetchAbilities', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      abilities: (state) => state.ability.abilities,
    }),
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1 ? 'Nueva habilidad' : 'Editar habilidad'
    },
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.name[0]
    },
  },
  data() {
    return {
      dialog: false,
      dictionary: {
        custom: {
          name: {
            required: () => 'El campo nombre no puede ser vacío',
            max: () => 'El nombre debe ser máximo de 100 caracteres',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
    this.$validator.reset()
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          await this.$store.dispatch('ability/updateAbility', this.item)
        } else {
          await this.$store.dispatch('ability/createAbility', {
            id: this.$route.params.id,
            item: this.item,
          })
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>