import InterventionPlanService from '@/services/reports/InterventionPlanService'

export const namespaced = true

export const state = {
    report: {},
    filterdReport: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    }
    ,
    SET_FILTERED_REPORT(state, filterdReport) {
        state.filterdReport = filterdReport
    }
}

export const actions = {
    fetchInterventionPlanReport({ commit }, id) {
        InterventionPlanService.getInterventionPlanReport(id)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchInterventionPlanFilteredReport({ commit }, item) {
        InterventionPlanService.getFilteredReport(item)
            .then(response => {
                commit('SET_FILTERED_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}