var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('div',{staticClass:"back"},[_c('v-row',{staticClass:"ml-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('router-link',{staticClass:"mr-2",style:({ color: _vm.link }),attrs:{"to":{ name: 'gamecustomizer' }}},[_vm._v("Personalizar Juegos")])],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('router-link',{staticClass:"mr-5",style:({ color: _vm.link }),attrs:{"to":{ name: 'workshop' }}},[_vm._v("/Talleres")])],1),_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}})],1)],1),_c('br'),_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"tabla de historias"}},[_c('p',[_vm._v("Historias")])]),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":_vm.icon_color,"fab":"","small":"","dark":""},on:{"click":_vm.newItem}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-plus")])]}}])},[_c('span',[_vm._v("Crear Historia")])])],1),_c('HistoryForm',{attrs:{"item":_vm.editedItem,"disabled":_vm.disabled,"index":_vm.editedIndex,"lamines":_vm.lamines},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar Historia"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","headers":_vm.headers,"items":_vm.histories,"search":_vm.search,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
      var item = ref.item;
return [(item.image)?_c('v-img',{staticClass:"ma-2 ml-5",attrs:{"src":item.image,"height":"80px","width":"80px","aspect-ratio":"2","contain":""}}):_c('p',[_vm._v("cargando img")])]}},{key:"item.dificulty",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.sequence),function(lamine){return _c('div',{key:lamine.id},[(lamine.history_id === item.id)?_c('div',[(lamine.lamines_number < 6)?_c('div',[_vm._v("Fácil")]):(lamine.lamines_number < 10)?_c('div',[_vm._v("Normal")]):_c('div',[_vm._v("Difícil")])]):_vm._e()])})}},{key:"item.published",fn:function(ref){
      var item = ref.item;
return [(item.published === 1 || item.published === true)?_c('div',[_vm._v("Sí")]):_c('div',[_vm._v("No")])]}},{key:"item.lamine_number",fn:function(ref){
      var item = ref.item;
return [(item.id)?_c('div',[(_vm.selectedLamine(item.id) == false)?_c('div',[_c('router-link',{style:({ color: _vm.icon_color }),attrs:{"aria-label":"Enlace que redirige a la página de parametrizacion de Láminas","to":{
                name: 'sequence',
                params: { history_id: item.id },
              }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-center ma-2"},on),[_vm._v("0")])]}}],null,true)},[_c('span',[_vm._v("Añadir Lámina")])])],1)],1):_c('div',_vm._l((_vm.sequence),function(lamine){return _c('div',{key:lamine.id},[(lamine.history_id === item.id)?_c('div',[_c('router-link',{style:({ color: _vm.icon_color }),attrs:{"aria-label":"Enlace que redirige a la página de parametrizacion de Láminas","to":{
                    name: 'sequence',
                    params: { history_id: item.id },
                  }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-center ma-2"},on),[_vm._v(" "+_vm._s(lamine.lamines_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Añadir Lámina")])])],1)],1):_vm._e()])}),0)]):_vm._e()]}},{key:"item.vocabulary",fn:function(ref){
                  var item = ref.item;
return [(item.id)?_c('div',[(_vm.selected(item.id) == false)?_c('div',[_c('router-link',{style:({ color: _vm.icon_color }),attrs:{"aria-label":"Enlace que redirige a la página de parametrizacion de juego","to":{
                name: 'vocabulary',
                params: { history_id: item.id },
              }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-center ma-2"},on),[_vm._v("0")])]}}],null,true)},[_c('span',[_vm._v("Añadir Vocabulario")])])],1)],1):_c('div',_vm._l((_vm.vocabulary),function(word){return _c('div',{key:word.id},[(word.history_id === item.id)?_c('div',[_c('router-link',{style:({ color: _vm.icon_color }),attrs:{"aria-label":"Enlace que redirige a la página de parametrizacion de juego","to":{
                    name: 'vocabulary',
                    params: { history_id: item.id },
                  }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-center ma-2"},on),[_vm._v(" "+_vm._s(word.words_number)+" ")])]}}],null,true)},[_c('span',[_vm._v("Añadir Vocabulario")])])],1)],1):_vm._e()])}),0)]):_vm._e()]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(
            _vm.selectedLamine(item.id) == false && _vm.selected(item.id) == false
          )?_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.deleteItem(item, _vm.register, item.name)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.cantErraseMessage(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }