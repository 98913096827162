<template>
  <v-app id="base" :style="background">
    <v-main app>
      <router-view />
    </v-main>
    <FooterTransparent style="backgroundcolor: transparent" />
  </v-app>
</template>

<script>
import FooterTransparent from '../../components/FooterTransparent'
export default {
  components: {
    FooterTransparent,
  },
  computed: {
    background() {
      return {
        backgroundImage: 'url(' + '/img/loginAssets/Background.png' + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }
    },
  },
}
</script>



