<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import BaseLayout from '../src/components/layouts/BaseLayout'
import AdministratorLayout from '../src/components/layouts/AdministratorLayout'
import OrganizationLayout from '../src/components/layouts/OrganizationLayout'
import TutorLayout from '../src/components/layouts/TutorLayout'
import VideogameListLayout from '../src/components/layouts/VideogameListLayout'

export default {
  name: 'App',
  components: {
    BaseLayout,
    AdministratorLayout,
    OrganizationLayout,
    TutorLayout,
    VideogameListLayout,
  },
  computed: {
    ...mapState({
      layout: (state) => state.layout.layout,
    }),
  },
}
</script>
<style scoped>
</style>
