<template >
  <div class="home">
    <div class="title pa-3" style="min-height: 100px">
      <h1
        class="display-2 text-center font-weight-bold pt-5 ml-n12"
        style="color: #3b007e"
      >
        {{ title }}
      </h1>
      <h1
        class="display-2 text-center font-weight-bold pt-6 mt-n8 ml-12"
        style="color: #3b007e"
      >
        {{ subtitle }}
      </h1>
    </div>
    <v-row no-gutters justify="center">
      <v-col cols="5">
        <div class="logo pt-5 text-cente">
          <v-img
            position="left"
            alt="imagen central de la pantalla de bienvenida"
            src="/img/loginAssets/LUDO.png"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <v-card
            flat
            id="login-card"
            class="mx-auto"
            max-width="370"
            color="transparent"
            @submit.prevent
          >
            <v-card-title
              class="ml-8 align-start fill-height display-1 font-weight-bold"
              style="color: #3b007e"
              >Iniciar Sesión</v-card-title
            >
            <v-card-actions class="pt-0">
              <v-select
                outlined
                filled
                dense
                v-model="user.typeUser"
                :items="usertypes"
                label="Tipo de usuario"
                item-text="option"
                item-value="value"
                v-validate="'required'"
                data-vv-name="usertype"
                :error-messages="errors.collect('usertype')"
                :color="icon_color"
              ></v-select>
            </v-card-actions>
            <v-card-actions>
              <v-text-field
                outlined
                filled
                name="email"
                type="email"
                label="Correo electrónico"
                append-icon="mdi-email-outline"
                v-model="user.email"
                v-validate="'required|email'"
                data-vv-name="email"
                :error-messages="errors.collect('email')"
                :color="icon_color"
              ></v-text-field>
            </v-card-actions>
            <v-card-actions>
              <v-text-field
                style="color: #352266"
                class="custom-label-color"
                outlined
                filled
                name="password"
                :append-icon="
                  showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                v-model="user.password"
                label="Contraseña"
                v-validate="'required'"
                data-vv-name="password"
                :error-messages="errors.collect('password')"
                :color="icon_color"
              ></v-text-field>
            </v-card-actions>
            <v-card-actions>
              <v-btn block :color="icon_color" dark @click="submit"
                >Comenzar</v-btn
              >
            </v-card-actions>
            <v-card-actions>
              <v-card-text :style="{ color: icon_color }">
                Si olvidaste tu contraseña ingresa
                <router-link
                  :style="{ color: link }"
                  to="/users/forget-password"
                  >aquí</router-link
                >.
              </v-card-text>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import usertypes from '../../Catalogue/usertypes'

export default {
  data() {
    return {
      title: 'Te damos la',
      subtitle: ' bienvenida a',
      id: '',
      tutor_credentials: {},
      admin_credentials: {},
      manager_credentials: {},
      user: {
        typeUser: '',
        email: '',
        password: '',
      },
      usertypes: usertypes,
      showpass: false,
      dictionary: {
        custom: {
          email: {
            required: () => 'El correo no debe ser vacío',
            email: () => 'El correo debe ser un correo válido',
          },
          password: {
            required: () => 'La contraseña no debe ser vacía',
          },
          usertype: {
            required: () => 'Debe seleccionar el tipo de usuario',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'base-layout')
    this.$store.commit('auth/SET_USER')
  },
  computed: {
    ...mapState({
      icon_color: (state) => state.layout.purple,
      link: (state) => state.layout.link,
      color: (state) => state.layout.color,
      userlogued: (state) => state.auth.user,
    }),
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.$store.dispatch('auth/login', this.user).then(() => {
          if (
            this.user.typeUser === 'administrator' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.admin_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              state: this.userlogued[0].state,
              type: 'administrator',
              id: this.userlogued[0].id,
            }
            localStorage.setItem('user', JSON.stringify(this.admin_credentials))
            this.$router.push({ name: 'organizationstypes' })
          } else if (
            this.user.typeUser === 'manager' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.id = this.userlogued[0].organization_id
            this.manager_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              state: this.userlogued[0].state,
              type: 'manager',
              id: this.userlogued[0].id,
            }
            localStorage.setItem(
              'user',
              JSON.stringify(this.manager_credentials)
            )
            this.$router.push(`/organizations/${this.id}/tutors`)
          } else if (
            this.user.typeUser === 'tutor' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.id = this.userlogued[0].organization_id
            this.$router.push(`/tutor/${this.id}/reports`)
            this.tutor_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              plan: this.userlogued[0].allow_create_intervention_plan,
              state: this.userlogued[0].state,
              type: 'tutor',
              id: this.userlogued[0].id,
            }
            localStorage.setItem('user', JSON.stringify(this.tutor_credentials))
          } else {
            this.$swal({
              html:
                `<span style="color: #0288D1; font-family: Helvetica"> ${this.user.email}</span>` +
                `<br>` +
                `<span style="color: white; font-family: Helvetica">Verifica que el correo y la contraseña sean válidos.<span>`,
              type: 'error',
              animation: false,
              borderImageSlice: '100',
              background:
                'url(/img/loginAssets/Background.png) no-repeat center',
              width: '350px',
              confirmButtonColor: '#1E87F7',
              showConfirmButton: true,
              confirmButtonText:
                "<span style='font-family: Helvetica'>Aceptar</span>",
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.custom-label-color .v-label {
  color: red;
  opacity: 1;
}
.custom-placeholer-color input,
.custom-label-color input {
  color: red !important;
}
#login-card {
  border: 20px solid transparent;
  border-image-width: 50px;
  padding: 5px;
  border-image-source: url('/img/loginAssets/border.png');
  border-image-slice: 100;
  border-radius: 200;
}
#button::placeholder {
  color: pink;
}
#button {
  border-radius: 15px;
  background-color: #83609e;
  border-width: 20px;
  border: 2px solid #ad2233;
  color: #00fcf3;
  margin: auto;
}
.v-text-field.outlined {
  border: 1px solid currentColor;
  color: #83609e;
  height: 56px;
  width: 56px;
}

.v-text-field.placeholder {
  color: #ad2233;
}
</style>