export default [
  { id: 1, value: 'Argentina', option: 'Argentina' },
  { id: 2, value: 'Bolivia', option: 'Bolivia' },
  { id: 3, value: 'Chile', option: 'Chile' },
  { id: 4, value: 'Colombia', option: 'Colombia' },
  { id: 5, value: 'Ecuador', option: 'Ecuador' },
  { id: 6, value: 'Paraguay', option: 'Paraguay' },
  { id: 7, value: 'Perú', option: 'Perú' },
  { id: 8, value: 'Uruguay', option: 'Uruguay' },
  { id: 9, value: 'venezuela', option: 'Venezuela' }
]
