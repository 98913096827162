import OrganizationService from '@/services/admin/OrganizationService'

export const namespaced = true

export const state = {
  organizations: [],
  organization: {}
}

export const mutations = {
  UPDATE_ORGANIZATION(state, organization) {
    state.organizations = [
      ...state.organizations.filter(element => element.id !== organization.id),
      organization
    ]
  },
  DELETE_ORGANIZATION(state, organization) {
    const index = state.organizations.indexOf(organization)
    state.organizations.splice(index, 1)
  },
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations
  },
  SET_ORGANIZATION(state, organization) {
    state.organization = organization
  }
}

export const actions = {
  createOrganization({ dispatch, commit }, organization) {
    return OrganizationService.postOrganization(organization)
      .then(response => {
        dispatch('fetchOrganizations')
        commit('SET_ORGANIZATION', response.data)
        // eslint-disable-next-line
        console.log(response.data)
      })
      .catch(error => {
        throw error
      })
  },

  createImage({ dispatch, commit }, image) {
    return OrganizationService.postImage(image.id, image.formData).then(
      response => {
        dispatch('fetchOrganizations')
        //eslint-disable-next-line
        // console.log(response.data)
        commit('UPDATE_ORGANIZATION', response.data[0])
      }
    )
  },
  updateOrganization({ commit }, organization) {
    return OrganizationService.putOrganization(organization)
      .then(() => {
        commit('UPDATE_ORGANIZATION', organization)
      })
      .catch(error => {
        throw error
      })
  },
  deleteOrganization({ commit }, organization) {
    return OrganizationService.deleteOrganization(organization)
      .then(() => {
        commit('DELETE_ORGANIZATION', organization)
      })
      .catch(error => {
        throw error
      })
  },
  fetchOrganizations({ commit }) {
    OrganizationService.getOrganizations()
      .then(response => {
        commit('SET_ORGANIZATIONS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
