<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12" md="2" sm="2">
          <router-link
            class="mr-2"
            :to="{ name: 'gamecustomizer' }"
            :style="{ color: link }"
            >Personalizar Juegos</router-link
          >
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <router-link
            class="mr-5"
            :to="{ name: 'workshop' }"
            :style="{ color: link }"
            >/Talleres</router-link
          >
        </v-col>
        <v-col cols="12" md="8" sm="8">
          <router-link
            class="mr-5"
            :to="{ name: 'history' }"
            :style="{ color: link }"
            >/Historias</router-link
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de vocabulario"
        >
          <p>Vocabulario</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            fab
            small
            dark
            class="ml-2"
            @click="newItem"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear Palabra</span>
            </v-tooltip>
          </v-btn>
          <VocabularyForm
            :item="editedItem"
            v-model="dialog"
            :disabled="disabled"
            :index="editedIndex"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Palabra"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :headers="headers"
        :items="vocabulary"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image"
            :src="item.image"
            height="80px"
            width="80px"
            aspect-ratio="2"
            class="ma-2 ml-5"
            contain
          ></v-img>
          <p v-else>cargando img</p>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                @click="deleteItem(item, register, item.name)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import VocabularyForm from '../../components/laboral-inclusion/VocabularyForm'

export default {
  mixins: [deleteMixin],
  components: {
    VocabularyForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
    this.$store.dispatch(
      'vocabulary/fetchVocabulary',
      this.$route.params.history_id
    )
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.primary,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      vocabulary: (state) => state.vocabulary.vocabulary,
    }),
  },
  data() {
    return {
      history_id: '',
      search: '',
      dialog: false,
      disabled: false,
      headers: [
        {
          text: 'IMAGEN',
          align: 'left',
          value: 'image',
          width: '400px',
        },
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'name',
          width: '150px',
        },
        {
          text: 'TIPO DE PALABRA',
          align: 'center',
          value: 'word_type',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'vocabulary/deleteVocabulary',
      editedIndex: -1,
      defaultItem: {
        id: null,
        name: '',

        description: '',
        lamine_number: '3',
      },
      editedItem: {
        id: null,
        name: '',

        description: '',
        lamine_number: '',
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.vocabulary.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.disabled = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      this.disabled = false
    },
  },
}
</script>

<style scoped>
</style>