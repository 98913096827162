<template>
  <v-footer app :style="background" elevation="10">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        color="#352266"
        :href="link.url"
        target="_blank"
        text
        rounded
        class="font-weight-light caption"
      >{{ link.name }}</v-btn>
      <v-col class="text-center" cols="12" color="#352266">
        <div class="font-weight-light caption" color="#352266" style="color:#352266">
          &copy; {{ year }}, hecho con
          <v-icon small color="red" class="pr-1">mdi-heart</v-icon>para
          una mejor educación.
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      links: [
        { name: 'EPN', url: 'https://www.epn.edu.ec/' },
        { name: 'PUCE', url: 'https://www.puce.edu.ec/' },
        { name: 'UDLA', url: 'https://www.udla.edu.ec/' }
      ]
    }
  },
  computed: {
    background() {
      return {
        background: 'transparent'
      }
    }
  }
}
</script>
<style scoped>
.copyright {
  color: #352266;
}
</style>
