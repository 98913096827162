<template>
  <div class="title pa-2" style="min-height:100px">
    <v-row>
      <v-col cols="12" sm="4" md="4" v-for="videogame in videogames" :key="videogame.id">
        <v-card max-width="300" :color="color" elevation="10" dark>
          <v-img
            class="white--text align-end"
            max-width="350"
            max-height="250"
            :aspect-ratio="1"
            :src="image ? videogame.img : img"
          ></v-img>
          <v-card-title class="pl-3 font-weight-bold">{{videogame.name}}</v-card-title>
          <v-card-text class="text--white" min-height="90">
            <div>{{videogame.description}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn :color="link" text :href="videogame.url" target="_blank">Ir a jugar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'videogame-list-layout')
    this.$store.dispatch('videogame/fetchVideogames')
  },
  computed: {
    ...mapState({
      color: state => state.layout.color,
      icon_color: state => state.layout.icon_color,
      link: state => state.layout.link,
      videogames: state => state.videogame.videogames
    })
  },
  data() {
    return {
      title: 'Videojuegos Web',
      image: '',
      img: 'img/image.png'
    }
  }
}
</script>