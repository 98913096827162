var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('caption',{staticClass:"font-weight-regular pt-1 headline",attrs:{"aria-label":"Cronograma diario"}},[_c('p',[_vm._v("Cronograma diario")])])]),_c('v-card-text',{staticClass:"white--text"},[_c('v-row',[_c('v-col',{attrs:{"cols":"0","sm":"0","md":"0"}},_vm._l((_vm.organizations),function(organization,index){return _c('div',{key:("organization-" + index)},[(organization.id == _vm.$route.params.id)?_c('p',{staticClass:"text-padding"},[(organization.logo_url != '')?[_c('v-img',{attrs:{"height":"200","width":"200","src":organization.logo_url}})]:[_c('v-img',{attrs:{"height":"150","width":"150","src":'/img/camera-off.png'}})]],2):_vm._e()])}),0),_c('v-col',{staticClass:"ml-4",attrs:{"cols":"15","sm":"8","md":"8"}},[_c('v-row',[_c('p',{staticClass:"font-weight-regular title"},[_vm._v("Nombre Organización:")]),_c('p',{staticClass:"font-weight-light ml-2 title"},[_vm._v(" "+_vm._s(this.getorganization()[0].name)+" ")])]),_c('v-row',[_c('p',{staticClass:"font-weight-regular title"},[_vm._v("Dirección:")]),_c('p',{staticClass:"font-weight-light ml-2 title"},[_vm._v(" "+_vm._s(this.getorganization()[0].address)+" ")])])],1)],1)],1),_c('v-card-actions',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mr-5 ml-1",attrs:{"dark":"","color":_vm.icon_color,"label":"Consultar fecha","append-icon":"mdi-calendar-month-outline","data-vv-name":"date","error-messages":_vm.errors.collect('date')},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":_vm.icon_color,"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-btn',{attrs:{"color":_vm.icon_color,"dark":""},on:{"click":_vm.buscar}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Buscar ")],1),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 ml-2",style:({ 'background-color': _vm.link }),attrs:{"color":_vm.icon_color,"dark":"","depressed":""},on:{"click":_vm.download}},on),[_c('v-icon',{attrs:{"center":""}},[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Imprimir")])])],1),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"item-key":"dailyplans","dark":"","headers":_vm.headers,"items":_vm.dailyplans,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }