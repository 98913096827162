<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de usuarios"
        >
          <p>Lista de usuarios</p>
        </caption>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar usuario"
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :headers="headers"
        :items="players"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.last_name="{ item }"
          >{{ item.first_name }} {{ item.last_name }}</template
        >
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <router-link
                :to="{
                  name: 'userplay',
                  params: {
                    id: item.organization_id,
                    player_id: item.id,
                    name: item.first_name,
                    surname: item.last_name,
                  },
                }"
                :style="{ color: link }"
                >Iniciar sesión</router-link
              >
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'tutor-layout')
    this.$store.dispatch('player/fetchPlayers', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      players: (state) => state.player.players,
    }),
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'last_name',
        },
        {
          text: 'ACCIONES',
          value: 'id',
          width: '200px',
          align: 'center',
        },
      ],
    }
  },
}
</script>
