<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col v-if="this.index > -1" cols="12" xs="6" sm="12">
                <v-file-input
                  v-model="file"
                  label="Imagen"
                  :color="icon_color"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  prepend-icon="mdi-camera"
                  v-validate="'size:2000'"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
              <v-col cols="12" xs="6" sm="12" v-else>
                <v-file-input
                  v-model="file"
                  :color="icon_color"
                  label="Imagen"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  prepend-icon="mdi-camera"
                  v-validate="'required|size:2000'"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-text-field
                  :color="icon_color"
                  v-model="item.name"
                  label="Titulo"
                  data-vv-name="workshop_title"
                  v-validate="'required|max:30'"
                  :maxlength="max"
                  :error-messages="errors.collect('workshop_title')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-textarea
                  v-model="item.description"
                  label="Descripción"
                  auto-grow
                  :color="icon_color"
                  rows="4"
                  row-height="30"
                  data-vv-name="description"
                  v-validate="'required|max:1024'"
                  maxlength="1024"
                  :error-messages="errors.collect('description')"
                  hint="obligatorio"
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <span class="text-left mb-0 blue--text">{{ information }}</span>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      workshop: (state) => state.workshop.workshop,
    }),
    formTitle() {
      this.$validator.reset()
      return this.index === -1 ? 'Nuevo Taller' : 'Editar Taller'
    },
  },
  created() {
    this.$store.dispatch('workshop/fetchWorkshops', this.$route.params.id)
  },
  data() {
    return {
      file: [],
      max: 30,
      information:
        '* El tamaño de la imagen debe ser menor a 2MB, y con dimensión de 650px X 325px',
      fileRules: [
        (value) =>
          !value || value.size < 2000000 || 'La imagen debe ser menor de 2 MB!',
      ],
      dialog: false,
      dictionary: {
        custom: {
          workshop_title: {
            required: () => 'El título del taller no puede ser vacío',
            max: () => 'El título debe ser máximo de 30 caracteres',
          },
          description: {
            required: () => 'La descripción no puede ser vacia',
            max: () => 'La descripción no debe exceder de 1024caracteres',
          },
          image: {
            required: () => 'La imagen no puede ser vacia',
            size: () => 'El tamaño de la imagen debe ser menor a 2MB',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.file = []
      this.item.name = ''
      this.item.description = ''
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          //eslint-disable-next-line
          console.log(this.item)
          await this.$store.dispatch('workshop/updateWorkshops', this.item)
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('workshop/createImage', {
                id: this.item.id,
                formData: formData,
                organization_id: this.$route.params.id,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        } else {
          let workshop = {
            name: this.item.name,
            description: this.item.description,
          }
          await this.$store.dispatch('workshop/createWorkshop', {
            id: this.$route.params.id,
            item: workshop,
          })
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('workshop/createImage', {
                id: this.workshop.insertId,
                organization_id: this.$route.params.id,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        }

        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>