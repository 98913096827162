import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getQualification_Scales() {
    return apiClient.get('/qualification-scales/')
  },
  postQualification_Scales(evaluationWay) {
    return apiClient.post('/qualification-scales/', evaluationWay)
  },
  putQualification_Scales(evaluationWay) {
    return apiClient.put(
      '/qualification-scales/' + evaluationWay.id,
      evaluationWay
    )
  },
  deleteQualification_Scales(evaluationWay) {
    return apiClient.delete('/qualification-scales/' + evaluationWay.id)
  }
}
