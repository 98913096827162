<template>
  <div class="login pt-4">
    <v-row class="text-right">
      <v-col cols="2" class="ml-auto">
        <v-img src="/img/loginAssets/LudomingaLogo2.png" />
      </v-col>
    </v-row>
    <h1 class="title font-weight-light pl-2" style="color: #3b007e"></h1>
    <div class="white--text pt-8">
      <v-card
        id="login-card"
        class="mx-auto"
        color="transparent"
        max-width="450"
        flat
        @submit.prevent
      >
        <v-card-title
          class="ml-8 align-start fill-height display-1 font-weight-bold"
          style="color: #3b007e"
          >Confirmación</v-card-title
        >
        <v-divider class="mx-4"></v-divider>
        <v-card-text style="color: #3b007e" class="mb-n10">
          La contraseña debe tener de 8 a 16 caracteres y contener una
          combinación de letras, números y al menos una mayúscula.
        </v-card-text>
        <v-card-actions class="pt-10">
          <v-text-field
            maxlength="16"
            v-validate="{
              required: true,
              min: 8,
              max: 16,
              regex: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
            }"
            v-model="credentials.password"
            @click:append="showpass = !showpass"
            :append-icon="showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showpass ? 'text' : 'password'"
            label="Contraseña"
            ref="password"
            data-vv-name="password"
            :error-messages="errors.collect('password')"
            outlined
            filled
            dense
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            maxlength="16"
            v-validate="'required|confirmed:password'"
            v-model="confirmpassword"
            :append-icon="showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            label="Confirmar contraseña"
            data-vv-name="confirmation"
            :error-messages="errors.collect('confirmation')"
            outlined
            filled
            dense
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-btn block :color="icon_color" dark @click="submit">Enviar</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-card-text style="color: #3b007e">
            Por favor ingresa tu nueva contraseña. Si quieres ir a la página de
            inicio haz clic
            <router-link :style="{ color: link }" to="/">aquí</router-link>.
          </v-card-text>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'base-layout')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.purple,
      link: (state) => state.layout.link,
    }),
  },
  data() {
    return {
      credentials: {
        id: this.$route.query.user,
        password: '',
        typeUser: this.$route.query.typeUser,
      },
      confirmpassword: '',
      showpass: false,
      dictionary: {
        custom: {
          password: {
            required: () => 'Contraseña no puede ser vacío',
            min: () => 'La contraseña debe tener mínimo 8 caracteres',
            max: () => 'La contraseña debe tener máximo 16 caracteres',
            regex: () =>
              'Contraseña debe tener almenos una letra mayúscula, una minúscula y un número.',
          },
          confirmation: {
            required: () => 'Confirmación contraseña no puede ser vacío',
            confirmed: () => 'Las contraseñas no coinciden',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.$store.dispatch('confirm/confirm', this.credentials).then(() => {
          this.$swal({
            html:
              '<p style="color: white; font-family: Helvetica">Contraseña confirmada</p>' +
              '<br>',
            type: 'success',
            animation: false,
            borderImageSlice: '100',
            background: 'url(/img/loginAssets/Background.png) no-repeat center',
            width: '350px',
            confirmButtonColor: '#1E87F7',
            showConfirmButton: true,
            confirmButtonText:
              "<span style='font-family: Helvetica' href='/'>Aceptar</span>",
          }).then(function () {
            window.location = '/'
          })
        })
      }
    },
  },
}
</script>

<style scoped>
#login-card {
  border: 20px solid transparent;
  border-image-width: 50px;
  padding: 5px;
  border-image-source: url('/img/loginAssets/border.png');
  border-image-slice: 100;
  border-radius: 200;
}
</style>