<template>
  <div class="login pt-5">
    <div>
      <v-row no-gutters class="ml-10">
        <v-col cols="2">
          <v-icon dark :style="{ color: 'white' }" large
            >mdi-chevron-left</v-icon
          >
          <router-link
            to="{ name: 'home' }"
            :style="{ color: 'white', 'font-size': '25px' }"
            >Regresar</router-link
          >
        </v-col>
        <v-col cols="2" class="ml-auto">
          <v-img src="/img/loginAssets/LudomingaLogo2.png" />
        </v-col>
      </v-row>
    </div>
    <div class="white--text pt-12">
      <v-card
        id="login-card"
        class="mx-auto"
        max-width="450"
        color="transparent"
        flat
        @submit.prevent
      >
        <v-card-title
          class="ml-8 align-start fill-height display-1 font-weight-bold"
          style="color: #3b007e"
          >Recuperación</v-card-title
        >
        <v-divider class="mx-5"></v-divider>
        <v-card-actions class="pt-8">
          <v-select
            outlined
            filled
            dense
            v-model="user.typeUser"
            :items="usertypes"
            label="Tipo de usuario"
            item-text="option"
            item-value="value"
            v-validate="'required'"
            data-vv-name="usertype"
            :error-messages="errors.collect('usertype')"
            :color="icon_color"
          ></v-select>
        </v-card-actions>
        <v-card-actions class="pt-1">
          <v-text-field
            outlined
            filled
            dense
            v-validate="'required|email'"
            v-model="user.email"
            label="Correo"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>

        <v-card-actions class="pt-2">
          <v-btn block :color="icon_color" dark @click="submit">Enviar</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-card-text :style="{ color: icon_color }">
            Por favor ingresa la cuenta de correo registrada en la plataforma y
            el rol que ejerce. Si quieres ir a la página de inicio haz clic
            <router-link :style="{ color: link }" to="/">aquí</router-link>.
          </v-card-text>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import usertypes from '../../Catalogue/usertypes'
export default {
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'base-layout')
    this.$store.commit('auth/SET_USER')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.purple,
      link: (state) => state.layout.link,
      userlogued: (state) => state.auth.user,
    }),
  },
  data() {
    return {
      id: '',
      usertypes: usertypes,
      tutor_credentials: {},
      admin_credentials: {},
      manager_credentials: {},
      user: {
        typeUser: '',
        email: '',
      },
      dictionary: {
        custom: {
          email: {
            required: () => 'El correo no puede ser vacío',
            email: () => 'El correo electrónico debe ser válido.',
          },
          usertype: {
            required: () => 'Debe seleccionar el tipo de usuario',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (isValidForm) {
          this.$store.dispatch('auth/resetPassword', this.user).then(() => {
            if (
              this.userlogued.length !== 0 &&
              this.user.email === this.userlogued[0].email
            ) {
              this.$swal({
                html:
                  `<span style="color: #0288D1; font-family: Helvetica"> ${this.user.email}</span>` +
                  `<br>` +
                  `<span style="color: white; font-family: Helvetica">Se ha enviado un mail a ` +
                  `la dirección registrada con información para restablecer la contraseña<span>`,
                type: 'success',
                animation: false,
                borderImageSlice: '100',
                background:
                  'url(/img/loginAssets/Background.png) no-repeat center',
                width: '350px',
                confirmButtonColor: '#1E87F7',
                showConfirmButton: true,
                confirmButtonText:
                  "<span style='font-family: Helvetica'>Aceptar</span>",
              }).then(function () {
                window.location = '/'
              })
            } else {
              this.$swal({
                html:
                  `<span style="color: #0288D1; font-family: Helvetica"> ${this.user.email}</span>` +
                  `<br>` +
                  `<span style="color: white; font-family: Helvetica">El correo no se encuentra registrado o activado.<span>`,
                type: 'error',
                animation: false,
                borderImageSlice: '100',
                background:
                  'url(/img/loginAssets/Background.png) no-repeat center',
                width: '350px',
                confirmButtonColor: '#1E87F7',
                showConfirmButton: true,
                confirmButtonText:
                  "<span style='font-family: Helvetica'>Aceptar</span>",
              })
            }
          })
        }
      }
    },
  },
}
</script>

<style scoped>
#login-card {
  border: 20px solid transparent;
  border-image-width: 50px;
  padding: 5px;
  border-image-source: url('/img/loginAssets/border.png');
  border-image-slice: 100;
  border-radius: 200;
}
</style>