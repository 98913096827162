import InterventionPlanService from '@/services/manager/InterventionPlanService'

export const namespaced = true

export const state = {
  intervention_plans: [],
  intervention_plan: {},
  error: ''
}
export const mutations = {
  UPDATE_INTERVENTION_PLAN(state, intervention_plan) {
    state.intervention_plans = [
      ...state.intervention_plans.filter(
        element => element.id !== intervention_plan.id
      ),
      intervention_plan
    ]
  },
  DELETE_INTERVENTION_PLAN(state, intervention_plan) {
    const index = state.intervention_plans.indexOf(intervention_plan)
    state.intervention_plans.splice(index, 1)
  },
  SET_INTERVENTION_PLANS(state, intervention_plans) {
    state.intervention_plans = intervention_plans
  },
  SET_INTERVENTION_PLAN(state, intervention_plan) {
    state.intervention_plan = intervention_plan
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}
export const actions = {
  createInterventionPlan({ dispatch }, intervention_plan) {
    return InterventionPlanService.postInterventionPlan(
      intervention_plan.id,
      intervention_plan.item
    )
      .then(() => {
        dispatch('fetchInterventionPlans', intervention_plan.id)
      })
      .catch(error => {
        throw error
      })
  },
  updateInterventionPlan({ commit }, intervention_plan) {
    return InterventionPlanService.putInterventionPlan(intervention_plan)
      .then(response => {
        commit('UPDATE_INTERVENTION_PLAN', intervention_plan)
        commit('SET_INTERVENTION_PLAN', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  updateCancelInterventionPlan({ commit }, intervention_plan) {
    return InterventionPlanService.putCancelInterventionPlan(intervention_plan)
      .then(() => {
        commit('UPDATE_INTERVENTION_PLAN', intervention_plan)
      })
      .catch(error => {
        throw error
      })
  },
  updateAddSessionInterventionPlan({ commit }, intervention_plan) {
    return InterventionPlanService.putAddSessionInterventionPlan(
      intervention_plan
    )
      .then(() => {
        commit('UPDATE_INTERVENTION_PLAN', intervention_plan)
      })
      .catch(error => {
        throw error
      })
  },
  deleteInterventionPlan({ commit }, intervention_plan) {
    return InterventionPlanService.deleteInterventionPlan(intervention_plan)
      .then(response => {
        if (response.data.error) {
          commit('SET_ERROR', response.data.error)
        } else {
          commit(
            'SET_ERROR',
            'Se ha eliminado el plan de intervención ya que no contiene datos relacionados.'
          )
          commit('DELETE_INTERVENTION_PLAN', intervention_plan)
        }
      })

      .catch(response => {
        throw response
      })
  },
  fetchInterventionPlans({ commit }, id) {
    InterventionPlanService.getInterventionPlans(id)
      .then(response => {
        commit('SET_INTERVENTION_PLANS', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  fetchInterventionPlansPerPlayer({ commit }, intervention_plan) {
    InterventionPlanService.getInterventionPlansPerPlayer(
      intervention_plan.id,
      intervention_plan.player_id
    )
      .then(response => {
        commit('SET_INTERVENTION_PLANS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
