import AuthService from '../../services/AuthService'

export const namespaced = true

export const state = {
  user: {}
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user
  }
}

export const actions = {
  login({ commit }, user) {
    return AuthService.postUser(user)
      .then(response => {
        commit('SET_USER', response.data)
        // console.log(response.data)
      })
      .catch(error => {
        throw error
      })
  },
  resetPassword({ commit }, user) {
    return AuthService.postPasswordReset(user)
      .then(response => {
        commit('SET_USER', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
