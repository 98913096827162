import PlayerService from '@/services/manager/PlayerService'

export const namespaced = true

export const state = {
  players: [],
  player: {}
}
export const mutations = {
  UPDATE_PLAYER(state, player) {
    state.players = [
      ...state.players.filter(element => element.id !== player.id),
      player
    ]
  },
  DELETE_PLAYER(state, player) {
    const index = state.players.indexOf(player)
    state.players.splice(index, 1)
  },
  SET_PLAYERS(state, players) {
    state.players = players
  },
  SET_PLAYER(state, player) {
    state.player = player
  }
}
export const actions = {
  createPlayer({ dispatch }, player) {
    return PlayerService.postPlayer(player.id, player.item)
      .then(() => {
        dispatch('fetchPlayers', player.id)
      })
      .catch(error => {
        throw error
      })
  },
  updatePlayer({ commit }, player) {
    return PlayerService.putPlayer(player)
      .then(() => {
        commit('UPDATE_PLAYER', player)
      })
      .catch(error => {
        throw error
      })
  },
  deletePlayer({ commit }, player) {
    return PlayerService.deletePlayer(player)
      .then(() => {
        commit('DELETE_PLAYER', player)
      })
      .catch(error => {
        throw error
      })
  },
  fetchPlayers({ commit }, id) {
    PlayerService.getPlayers(id)
      .then(response => {
        commit('SET_PLAYERS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
