<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12">
          <router-link :to="{ name: 'userlogin' }" :style="{ color: link }"
            >Lista de usuarios</router-link
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption class="font-weight-light title pt-2">
          {{
            this.$route.params.name
          }}
          {{
            this.$route.params.surname
          }}
        </caption>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar videojuego"
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :headers="headers"
        :items="interventionplan"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.videogame_id="{ item }">
          <div v-for="videogame in videogames" :key="videogame.id">
            <div v-if="videogame.id === item.videogame_id">
              {{ videogame.name }}
            </div>
          </div>
        </template>

        <template v-slot:item.time="{ item }">
          {{ item.time + ' ' + 'min' }}
        </template>

        <template v-slot:item.days="{ item }">
          <div v-for="(day, index) in item.days" :key="index">
            {{ day.day }}
          </div>
        </template>

        <template v-slot:item.number_session="{ item }">
          {{ item.number_session }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <div
                v-if="item.number_session === 0 || item.state === 'cancelado'"
              >
                <v-icon
                  disabled
                  size="30px"
                  :color="icon_color"
                  v-on="on"
                  class="mr-2"
                  @click="play(item)"
                  >mdi-arrow-right-drop-circle-outline</v-icon
                >
              </div>
              <div v-else>
                <v-icon
                  size="30px"
                  :color="icon_color"
                  v-on="on"
                  class="mr-2"
                  @click="play(item)"
                  >mdi-arrow-right-drop-circle-outline</v-icon
                >
              </div>
            </template>
            <span>Jugar</span>
          </v-tooltip>
          <SessionForm
            :item="editedItem"
            v-model="dialog"
            :index="editedIndex"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SessionForm from '../../components/tutor/SessionForm'

export default {
  components: {
    SessionForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'tutor-layout')
    this.$store.dispatch('interventionplan/fetchInterventionPlansPerPlayer', {
      id: this.$route.params.id,
      player_id: this.$route.params.player_id,
    })
    this.$store.dispatch('videogame/fetchVideogames')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      interventionplan: (state) => state.interventionplan.intervention_plans,
      videogames: (state) => state.videogame.videogames,
    }),
  },
  data() {
    return {
      id: 0,
      dialog: false,
      disabled: false,
      search: '',
      headers: [
        {
          text: 'VIDEOJUEGO',
          align: 'left',
          value: 'videogame_id',
        },
        {
          text: 'DURACIÓN',
          align: 'left',
          value: 'time',
        },
        {
          text: 'DÍAS',
          align: 'left',
          value: 'days',
        },
        {
          text: 'SESIONES RESTANTES',
          align: 'center',
          value: 'number_session',
          width: '200',
        },

        {
          text: 'ACCIONES',
          value: 'action',
          sortable: false,
          width: '200px',
          align: 'center',
        },
      ],
      editedIndex: -1,
      defaultItem: {
        id: null,
        organization_id: null,
        player_id: null,
        ability_id: null,
        videogame_id: null,
        qualification_scale_id: null,
        number_session: '',
        time: 0,
        start_date: '',
        code: '',
        days: '',
        state: false,
      },
      editedItem: {
        id: null,
        organization_id: null,
        player_id: null,
        ability_id: null,
        videogame_id: null,
        qualification_scale_id: null,
        number_session: '',
        time: 0,
        start_date: '',
        code: '',
        days: '',
        state: false,
      },
    }
  },

  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  methods: {
    play(item) {
      this.editedIndex = this.interventionplan.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
  },
}
</script>
