<template>
  <div class="login pt-4">
    <div class="back">
      <v-row no-gutters class="ml-10">
        <v-col cols="12">
          <v-icon dark :style="{ color: link }">mdi-chevron-left</v-icon>
          <router-link to="{ name: 'home' }" :style="{ color: link }"
            >Regresar</router-link
          >
        </v-col>
      </v-row>
    </div>
    <div class="white--text pa-2">
      <v-card
        class="mx-auto"
        max-width="370"
        :color="color"
        elevation="24"
        @submit.prevent
      >
        <v-img
          class="white--text"
          height="100px"
          :src="require('../../../public/img/card-info.png')"
          position="center"
          alt
        >
          <v-card-title
            class="align-start fill-height display-1 font-weight-bold"
            >Iniciar Sesión</v-card-title
          >
        </v-img>
        <v-card-actions class="pt-10">
          <v-select
            dark
            dense
            v-model="user.typeUser"
            :items="usertypes"
            label="Tipo de usuario"
            item-text="option"
            item-value="value"
            v-validate="'required'"
            data-vv-name="usertype"
            :error-messages="errors.collect('usertype')"
            :color="icon_color"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            name="email"
            type="email"
            label="Correo electrónico"
            append-icon="mdi-email-outline"
            v-model="user.email"
            v-validate="'required|email'"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
            dark
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            name="password"
            :append-icon="showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            v-model="user.password"
            label="Contraseña"
            v-validate="'required'"
            data-vv-name="password"
            :error-messages="errors.collect('password')"
            dark
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-btn block :color="icon_color" dark @click="submit">Comenzar</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-card-text class="white--text">
            Si olvidaste tu contraseña ingresa
            <router-link :style="{ color: link }" to="/users/forget-password"
              >aquí</router-link
            >.
          </v-card-text>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import usertypes from '../../Catalogue/usertypes'

export default {
  data() {
    return {
      id: '',
      tutor_credentials: {},
      admin_credentials: {},
      manager_credentials: {},
      user: {
        typeUser: '',
        email: '',
        password: '',
      },
      usertypes: usertypes,
      showpass: false,
      dictionary: {
        custom: {
          email: {
            required: () => 'El correo no debe ser vacío',
            email: () => 'El correo debe ser un correo válido',
          },
          password: {
            required: () => 'La contraseña no debe ser vacía',
          },
          usertype: {
            required: () => 'Debe seleccionar el tipo de usuario',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'base-layout')
    this.$store.commit('auth/SET_USER')
  },
  computed: {
    ...mapState({
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      color: (state) => state.layout.color,
      userlogued: (state) => state.auth.user,
    }),
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.$store.dispatch('auth/login', this.user).then(() => {
          if (
            this.user.typeUser === 'administrator' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.admin_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              state: this.userlogued[0].state,
            }
            localStorage.setItem('user', JSON.stringify(this.admin_credentials))
            this.$router.push({ name: 'organizationstypes' })
          } else if (
            this.user.typeUser === 'manager' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.id = this.userlogued[0].organization_id
            this.manager_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              state: this.userlogued[0].state,
              type: 'manager',
            }
            localStorage.setItem(
              'user',
              JSON.stringify(this.manager_credentials)
            )
            this.$router.push(`/organizations/${this.id}/tutors`)
          } else if (
            this.user.typeUser === 'tutor' &&
            this.userlogued.length !== 0 &&
            this.user.email === this.userlogued[0].email &&
            this.user.password === this.userlogued[0].password
          ) {
            this.id = this.userlogued[0].organization_id
            this.$router.push(`/tutor/${this.id}/userlogin`)
            this.tutor_credentials = {
              full_name:
                this.userlogued[0].first_name +
                ' ' +
                this.userlogued[0].last_name,
              plan: this.userlogued[0].allow_create_intervention_plan,
              state: this.userlogued[0].state,
              type: 'tutor',
            }
            localStorage.setItem('user', JSON.stringify(this.tutor_credentials))
          } else {
            this.$swal({
              html:
                `<span style="color: #0288D1; font-family: Helvetica"> ${this.user.email}</span>` +
                `<br>` +
                `<span style="color: white; font-family: Helvetica">Verifica que el correo y la contraseña sean válidos.<span>`,
              type: 'error',
              animation: false,
              background: '#1e1e2f',
              width: '350px',
              confirmButtonColor: '#1E87F7',
              showConfirmButton: true,
              confirmButtonText:
                "<span style='font-family: Helvetica'>Aceptar</span>",
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped>
</style>