export default [
    { id: 1, value: '#2762ba', option: '1' },
    { id: 2, value: '#f07979', option: '2' },
    { id: 3, value: '#9c2828', option: '3' },
    { id: 4, value: '#f87011', option: '4' },
    { id: 5, value: '#6cad2a', option: '5' },
    { id: 6, value: '#EBF172', option: '6' },
    { id: 7, value: '#FBC02D', option: '7' },
    { id: 8, value: '#1976D2', option: '8' },
    { id: 9, value: '#9DB393', option: '9' },
    { id: 10, value: '#825A25', option: '10' },
    { id: 11, value: '#FF0000', option: '11' },
    { id: 12, value: '#00FF00', option: '12' },
    { id: 13, value: '#FF00FF', option: '13' },
    { id: 14, value: '#00FFFF', option: '14' },
    { id: 15, value: '#008000', option: '15' },
    { id: 16, value: '#800000', option: '16' },
    { id: 17, value: '#0000FF', option: '17' },
    { id: 18, value: '#800080', option: '18' },
    { id: 19, value: '#CD5C5C', option: '19' },
    { id: 20, value: '#AD93F3', option: '20' },
]