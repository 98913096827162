const deleteMixin = {
  data() {
    return {}
  },
  methods: {
    deleteItem(item, register, name, num) {
      this.$swal({
        html:
          `<span style="color: white; font-family: Helvetica">Seguro quieres eliminar a:<span>` +
          `<br>` +
          `<span style="color: #FBC02D"> ${name}</span>`,
        type: 'warning',
        animation: false,
        showCancelButton: true,
        cancelButtonColor: '#757575',
        cancelButtonText:
          "<span style='font-family: Helvetica'>Cancelar</span>",
        showConfirmButton: true,
        confirmButtonColor: '#FBC02D',
        confirmButtonText:
          "<span style='font-family: Helvetica'>Eliminar</span>",
        focusConfirm: false,
        focusCancel: true,
        background: '#0B5683',
        width: '350px'
      }).then(result => {
        if (result.value) {
          if (num === item.id || num === 1) {
            this.$swal({
              html:
                "<span style='color: white; font-family: Helvetica'>No se puede eliminar primero se debe desactivar el elemento </span>" +
                `<br>` + `<br>` +
                `<span style="color: #FBC02D; font-family: Helvetica"> ${name}</span>`,
              type: 'error',
              showConfirmButton: false,
              timer: 2000,
              animation: false,
              background: '#0B5683',
              width: '350px'
            })
          } else {
            this.$swal({
              html:
                "<span style='color: white; font-family: Helvetica'>Eliminado</span>",
              type: 'success',
              showConfirmButton: false,
              timer: 1500,
              background: '#0B5683 ',
              width: '400px'
            })
            this.$store.dispatch(register, item)
          }
        }
      })
    }
  }
}

export default deleteMixin
