import QualificationScaleService from '@/services/admin/QualificationScaleService'

export const namespaced = true

export const state = {
  qualification_scales: [],
  qualification_scale: {}
}

export const mutations = {
  UPDATE_QUALIFICATION_SCALES(state, evaluationWay) {
    state.qualification_scales = [
      ...state.qualification_scales.filter(
        element => element.id !== evaluationWay.id
      ),
      evaluationWay
    ]
  },
  SET_QUALIFICATION_SCALES(state, qualification_scales) {
    state.qualification_scales = qualification_scales
  },
  DELETE_QUALIFICATION_SCALES(state, evaluationWay) {
    const index = state.qualification_scales.indexOf(evaluationWay)
    state.qualification_scales.splice(index, 1)
  }
}

export const actions = {
  createQualification_Scales({ dispatch }, evaluationWay) {
    return QualificationScaleService.postQualification_Scales(evaluationWay)
      .then(() => {
        dispatch('fetchQualification_Scales')
      })
      .catch(error => {
        throw error
      })
  },
  fetchQualification_Scales({ commit }) {
    QualificationScaleService.getQualification_Scales()
      .then(response => {
        commit('SET_QUALIFICATION_SCALES', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  updateQualification_Scales({ commit }, evaluationWay) {
    return QualificationScaleService.putQualification_Scales(evaluationWay)
      .then(() => {
        commit('UPDATE_QUALIFICATION_SCALES', evaluationWay)
      })
      .catch(error => {
        throw error
      })
  },
  deleteQualification_Scales({ commit }, evaluationWay) {
    return QualificationScaleService.deleteQualification_Scales(evaluationWay)
      .then(() => {
        commit('DELETE_QUALIFICATION_SCALES', evaluationWay)
      })
      .catch(error => {
        throw error
      })
  }
}
