import WorkshopService from '@/services/laboral-inclusion/WorkshopService'

export const namespaced = true

export const state = {
    workshops: [],
    workshop: {}
}
export const mutations = {
    SET_WORKSHOPS(state, workshops) {
        state.workshops = workshops
    }
    ,
    SET_WORKSHOP(state, workshop) {
        state.workshop = workshop
    }
    ,

    UPDATE_WORKSHOP(state, workshop) {
        state.workshops = [
            ...state.workshops.filter(
                element => element.id !== workshop.id
            ),
            workshop
        ]
    }
    ,
    DELETE_WORKSHOP(state, workshop) {
        const index = state.workshops.indexOf(workshop)
        state.workshops.splice(index, 1)
    }
}
export const actions = {
    createWorkshop({ dispatch, commit }, workshop) {
        return WorkshopService.postWorkshop(workshop.id, workshop.item)
            .then(response => {
                dispatch('fetchWorkshops', workshop.id)
                commit('SET_WORKSHOP', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchWorkshops({ commit }, id) {
        WorkshopService.getWorkshops(id)
            .then(response => {
                commit('SET_WORKSHOPS', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    createImage({ dispatch, commit }, image) {
        return WorkshopService.postImage(image.id, image.formData).then(
            response => {
                dispatch('fetchWorkshops', image.organization_id)
                //eslint-disable-next-line
                // console.log(response.data)
                commit('UPDATE_WORKSHOP', response.data[0])
            }
        )
    },
    updateWorkshops({ commit }, workshop) {
        return WorkshopService.putworkshop(workshop)
            .then(() => {
                commit('UPDATE_WORKSHOP', workshop)
            })
            .catch(error => {
                throw error
            })
    },
    deleteWorkshops({ commit }, workshop) {
        return WorkshopService.deleteWorkshop(workshop)
            .then(() => {
                commit('DELETE_WORKSHOP', workshop)
            })
            .catch(error => {
                throw error
            })
    }
}