<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      @input="$emit('input')"
      max-width="800px"
      :retain-focus="false"
    >
      <v-card :color="color" dark>
        <v-card-title>
          <span class="headline">Formulario de evaluación</span>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <v-container>
            <p>{{ formTitle }}</p>
            <form action>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <div v-for="player in players" :key="player.id">
                    <div v-if="player.id === item.player_id">
                      <v-text-field
                        disabled
                        label="Nombre"
                        v-model="player.first_name"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div v-for="player in players" :key="player.id">
                    <div v-if="player.id === item.player_id">
                      <v-text-field
                        disabled
                        label="Apellido"
                        v-model="player.last_name"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <div v-for="videogame in videogames" :key="videogame.id">
                    <div v-if="videogame.id === item.videogame_id">
                      <v-text-field
                        disabled
                        label="Videojuego"
                        v-model="videogame.name"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div v-for="ability in abilities" :key="ability.id">
                    <div v-if="ability.id === item.ability_id">
                      <v-text-field
                        disabled
                        label="Habilidad"
                        v-model="ability.name"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    disabled
                    label="Acción"
                    v-model="action"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    :color="icon_color"
                    v-model="evaluation.progress"
                    :items="selectQualificationCodes"
                    label="Progreso"
                    item-text="label"
                    item-value="value"
                    no-data-text="No hay datos ingresados"
                    dense
                    v-validate="'required|max:60'"
                    data-vv-name="progress"
                    :error-messages="errors.collect('progress')"
                    hint="obligatorio"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <div v-if="action === 'Evaluar' || action === 'Suspender'">
                    <v-text-field
                      disabled
                      label="Sesiones aumentadas"
                      v-model="evaluation.session"
                    ></v-text-field>
                  </div>
                  <div v-else>
                    <v-select
                      :color="icon_color"
                      v-model="evaluation.session"
                      :items="sessions"
                      label="Sesiones"
                      item-text="option"
                      item-value="value"
                      dense
                      v-validate="'required|numeric'"
                      data-vv-name="session"
                      :error-messages="errors.collect('session')"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="evaluation.observation"
                    label="Observaciones"
                    rows="3"
                    :color="icon_color"
                    v-validate="'required|max:1024'"
                    data-vv-name="observation"
                    :error-messages="errors.collect('observation')"
                    hint="obligatorio"
                    persistent-hint
                  ></v-textarea>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn :color="link" text @click="save">Guardar</v-btn>
          <v-btn :color="link" text @click="close">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import sessions from '../../Catalogue/sessions'

export default {
  props: {
    value: Boolean,
    index: Number,
    item: Object,
    action: String,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      videogames: (state) => state.videogame.videogames,
      players: (state) => state.player.players,
      abilities: (state) => state.ability.abilities,
    }),
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.action === 'Evaluar'
        ? ''
        : '* Para suspender o aumentar sesiones en este plan de intervención es necesario hacer una evaluación previa.'
    },
    selectQualificationCodes() {
      return this.$store.state.qualificationcode.qualification_codes.map(
        (element) => ({
          value: element.value,
          label: element.value,
        })
      )
    },
  },
  data() {
    return {
      sessions: sessions,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
      evaluation: {
        action: '',
        date: '',
        progress: '',
        session: 0,
        observation: '',
      },
      dictionary: {
        custom: {
          progress: {
            required: () => 'El campo progreso no puede ser vacío',
            max: () => 'EL campo no puede tener más de 60 caracteres',
          },
          session: {
            required: () => 'El campo sesión no puede ser vacío',
          },
          observation: {
            required: () => 'El campo observación no puede ser vacío',
            max: () => 'El campo no puede tener más de 1024 caracteres',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog_two(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.$validator.reset()
        this.evaluation.action = this.action
        this.evaluation.date = `${this.year}-${this.month + 1}-${this.day}`
        this.$store.dispatch('evaluation/createEvaluation', {
          id: this.item.id,
          item: this.evaluation,
        })
        if (this.action === 'Suspender') {
          this.$store.dispatch(
            'interventionplan/updateCancelInterventionPlan',
            this.item
          )
        } else if (this.action === 'Aumentar sesiones') {
          this.item.number_session =
            this.item.number_session + this.evaluation.session
          this.$store.dispatch(
            'interventionplan/updateAddSessionInterventionPlan',
            this.item,
            this.item.number_session
          )
        }
        this.close()
        this.evaluation = {
          action: '',
          progress: '',
          session: 0,
          observation: '',
        }
        this.evaluation.session = 0
      }
    },
  },
}
</script>