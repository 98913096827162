<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.player_id"
                  :items="selectPlayers"
                  label="Usuario"
                  item-text="label"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="player_id"
                  :error-messages="errors.collect('player_id')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.qualification_scale_id"
                  no-data-text="No hay datos ingresados"
                  :items="selectScale"
                  label="Evaluación"
                  item-text="label"
                  item-value="value"
                  dense
                  v-validate="'required'"
                  data-vv-name="qualification_id"
                  :error-messages="errors.collect('qualification_id')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.videogame_id"
                  :items="selectVideogames"
                  label="Videojuego"
                  item-text="label"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="videogame_id"
                  :error-messages="errors.collect('videogame_id')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :color="icon_color"
                  v-model="item.ability_id"
                  :items="selectAbilities"
                  label="Habilidad"
                  item-text="label"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="ability"
                  :error-messages="errors.collect('ability')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  maxlength="9"
                  :color="icon_color"
                  v-model="item.number_session"
                  label="Sesiones"
                  v-validate="'required|numeric'"
                  data-vv-name="number_session"
                  :error-messages="errors.collect('number_session')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  class="mt-5"
                  :color="icon_color"
                  v-model="item.time"
                  :items="play_time"
                  label="Tiempo"
                  item-text="option"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="salida"
                  :error-messages="errors.collect('salida')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :color="icon_color"
                      :value="slice(item.start_date)"
                      label="Fecha de inicio"
                      append-icon="mdi-calendar-month-outline"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-name="start_date"
                      :error-messages="errors.collect('start_date')"
                      hint="obligatorio"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :color="icon_color"
                    v-model="item.start_date"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  class="mt-5"
                  :color="icon_color"
                  v-model="item.period_id"
                  :items="selectPeriods"
                  label="Periodo"
                  item-text="label"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  v-validate="'required'"
                  data-vv-name="period_id"
                  :error-messages="errors.collect('period_id')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" class="ml-3">
                <v-row>
                  <v-select
                    class="mt-5"
                    v-model="values"
                    :items="selectDays"
                    item-text="label"
                    item-value="value"
                    no-data-text="No hay datos ingresados"
                    dense
                    chips
                    label="Días de terapia"
                    multiple
                  ></v-select>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="pt-5 ml-5">
                <v-textarea
                  class="mx-3"
                  auto-grow
                  v-model="item.observation"
                  label="Observación"
                  rows="1"
                  :color="icon_color"
                  v-validate="'max:1024'"
                  data-vv-name="observation"
                  :error-messages="errors.collect('observation')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="pt-5 ml-5">
                <v-text-field
                  :color="icon_color"
                  v-model="item.code"
                  label="Código"
                  v-on="on"
                  v-validate="'required'"
                  data-vv-name="code"
                  :error-messages="errors.collect('code')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import time from '../../Catalogue/time'
import days from '../../Catalogue/days'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
    day: Array,
  },

  created() {
    this.$store.dispatch('player/fetchPlayers', this.$route.params.id)
    this.$store.dispatch('ability/fetchAbilities', this.$route.params.id)
    this.$store.dispatch('period/fetchPeriods', this.$route.params.id)
    this.$store.dispatch('videogame/fetchVideogames')
    this.$store.dispatch('qualificationscale/fetchQualification_Scales')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      players: (state) => state.player.players,
      videogames: (state) => state.videogame.videogames,
      abilities: (state) => state.ability.abilities,
      qualification_scales: (state) =>
        state.qualificationscale.qualification_scales,
      periods: (state) => state.period.periods,
      interventionplan: (state) => state.interventionplan.intervention_plan,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.code[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1
        ? 'Nuevo plan de intervención'
        : 'Editar plan de intervención'
    },
    text() {
      return this.item.email_confirmation === 0
        ? '* El correo no está confirmado'
        : ''
    },
    selectPeriods() {
      return this.periods.map((element) => ({
        value: element.id,
        label: element.name,
      }))
    },
    selectPlayers() {
      return this.players
        .filter(function (task) {
          return task.state === 1
        })
        .map((element) => ({
          value: element.id,
          label: element.first_name + ' ' + element.last_name,
        }))
    },
    selectVideogames() {
      return this.videogames.map((element) => ({
        value: element.id,
        label: element.name,
      }))
    },
    selectAbilities() {
      return this.abilities.map((element) => ({
        value: element.id,
        label: element.name,
      }))
    },
    selectScale() {
      return this.qualification_scales.map((element) => ({
        value: element.id,
        label: element.name,
      }))
    },
    selectDays() {
      return this.days.map((element) => ({
        value: element.value,
        label: element.option,
      }))
    },
    values: {
      get() {
        return this.day
      },
      set(newValue) {
        this.$emit('update:day', newValue)
      },
    },
  },
  data() {
    return {
      dialog: false,
      days: days,
      play_time: time,
      on: '',
      dialog_two: false,
      menu: false,
      modal: false,
      menu2: false,
      dictionary: {
        custom: {
          ability: {
            required: () => 'Debe seleccionar una habilidad',
          },
          player_id: {
            required: () => 'Debe seleccionar el usuario',
          },
          qualification_id: {
            required: () => 'Debe seleccionar una evaluación',
          },
          number_session: {
            required: () => 'Sesiones no debe ser vacío',
            numeric: () => 'Ingrese solo números',
          },
          videogame_id: {
            required: () => 'Debe seleccionar el videojuego',
          },
          period_id: {
            required: () => 'Debe seleccionar el periodo',
          },
          salida: {
            required: () => 'Debe seleccionar tiempo',
          },
          start_date: {
            required: () => 'Fecha no debe ser vacío',
          },
          observation: {
            max: () => 'La descripción no debe exceder de 1024 caracteres',
          },
          code: {
            required: () => 'El código no debe ser vacío',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    slice(item) {
      if (item) {
        return item.slice(0, 10)
      } else {
        return ''
      }
    },
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },

    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.item.days = this.values
          this.item.start_date = await new Date(this.item.start_date)
            .toISOString()
            .substring(0, 10)
          await this.$store.dispatch(
            'interventionplan/updateInterventionPlan',
            this.item
          )
          if (this.interventionplan) {
            location.reload()
          }
        } else {
          this.item.days = this.values
          this.$store.dispatch('interventionplan/createInterventionPlan', {
            id: this.$route.params.id,
            item: this.item,
          })
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>