import apiClient from '../configServices'

export default {
  getHistories(id) {
    return apiClient.get('/workshops/' + id + '/histories')
  },
  getAllHistories() {
    return apiClient.get('/histories')
  },
  postImage(id, formData) {
    return apiClient.post('/histories/uploads/' + id, formData)
  },
  postHistories(id, workshop) {
    return apiClient.post('/workshops/' + id + '/histories', workshop)
  },
  putHistories(workshop) {
    return apiClient.put('/histories/' + workshop.id, workshop)
  },
  deleteHistories(workshop) {
    return apiClient.delete('/histories/' + workshop.id)
  }
}