import AbilityPerGameReportService from '@/services/reports/AbilityPerGameReportService'

export const namespaced = true

export const state = {
    report: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    }
}

export const actions = {
    fetchAbilityPerGameReport({ commit }, item) {
        AbilityPerGameReportService.getAbilityPerGameReports(item)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}