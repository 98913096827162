<template>
  <v-app id="videogameslist" :style="background">
    <v-navigation-drawer
      v-model="drawer"
      :color="icon_color"
      dark
      width="238px"
      app
      clipped
    >
      <template v-slot:prepend>
        <Menuheader :title="titleMenu" />
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          :to="option.path"
          @click.stop
        >
          <v-list-item-action>
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark :color="color" class="pl-7 pr-7">
      <v-app-bar-nav-icon
        elevation="0"
        @click.stop="drawer = !drawer"
        aria-label="menu izquierdo de la lista de videojuegos"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <h1 class="title">Lista de videojuegos</h1>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn :color="icon_color" dark @click="$router.push({ name: 'login' })"
        >Iniciar sesión</v-btn
      >
    </v-app-bar>

    <v-main app>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Menuheader from '../../components/MenuHeader'
import Footer from '../../components/Footer'

export default {
  components: {
    Menuheader,
    Footer,
  },
  data() {
    return {
      drawer: null,
      titleMenu: 'Videojuegos',
      username: 'Jena Pérez',
      photo: '',
      options: [
        {
          title: 'Videojuegos',
          icon: 'mdi-gamepad-variant-outline',
          path: { name: 'videogameslist' },
        },
        {
          title: 'Inicio',
          icon: 'mdi-home',
          path: '/',
        },
      ],
    }
  },
  computed: {
    background() {
      return {
        background: this.$vuetify.theme.themes.dark.background,
      }
    },
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      primary: (state) => state.layout.primary,
    }),
  },
}
</script>