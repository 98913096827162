<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">Sesión del videojuego</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :color="icon_color"
                  label="Tiempo jugado"
                  v-model="session.time_played"
                  v-validate="'required|numeric'"
                  data-vv-name="time_played"
                  :error-messages="errors.collect('time_played')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :color="icon_color"
                  label="Aciertos"
                  v-model="session.success"
                  v-validate="'required|numeric'"
                  data-vv-name="success"
                  :error-messages="errors.collect('success')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :color="icon_color"
                  label="Omisiones"
                  v-model="session.omissions"
                  v-validate="'required|numeric'"
                  data-vv-name="omissions"
                  :error-messages="errors.collect('omissions')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :color="icon_color"
                  label="Fallos"
                  v-model="session.failures"
                  v-validate="'required|numeric'"
                  data-vv-name="failures"
                  :error-messages="errors.collect('failures')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-textarea
                  v-model="session.observation"
                  label="Observaciones"
                  rows="3"
                  :color="icon_color"
                  v-validate="'required|max:1024'"
                  data-vv-name="observation"
                  :error-messages="errors.collect('observation')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="pt-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :color="icon_color"
                      v-model="session.date"
                      label="Fecha en que se realizó la sesión"
                      append-icon="mdi-calendar-month-outline"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-name="date"
                      :error-messages="errors.collect('date')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :color="icon_color"
                    v-model="session.date"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  created() {
    this.$store.dispatch('session/fetchSessions', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      sessions: (state) => state.session.sessions,
    }),
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,
      session: {
        intervention_plan_id: null,
        observation: '',
        time_played: '',
        success: '',
        omissions: '',
        failures: '',
        date: '',
      },
      dictionary: {
        custom: {
          time_played: {
            required: () => 'Tiempo jugado no debe ser vacío',
            numeric: () => 'Ingrese solo números',
          },
          success: {
            required: () => 'Aciertos no debe ser vacío',
            numeric: () => 'Ingrese solo números',
          },
          omissions: {
            required: () => 'Omisiones no debe ser vacío',
            numeric: () => 'Ingrese solo números',
          },
          failures: {
            required: () => 'Fallos no debe ser vacío',
            numeric: () => 'Ingrese solo números',
          },
          date: {
            required: () => 'Fecha no debe ser vacío',
          },
          observation: {
            required: () => 'Observaciones no debe ser vacío',
            max: () => 'El camposolo puede tener 1024 caracteres',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()

      if (isValidForm) {
        if (this.index > -1) {
          this.item.start_date = await new Date(this.item.start_date)
            .toISOString()
            .substring(0, 10)

          this.$store.dispatch('session/createSession', {
            id: this.item.id,
            item: this.session,
          })
          this.session = {
            intervention_plan_id: null,
            observation: '',
            time_played: '',
            success: '',
            omissions: '',
            failures: '',
            date: '',
          }

          this.item.days = this.item.days.map((element) => element.day)
          this.item.number_session = this.item.number_session - 1
          this.$store.dispatch(
            'interventionplan/updateInterventionPlan',
            this.item
          )
        }
      }
      this.$validator.reset()
      this.close()
      location.reload()
    },
  },
}
</script>