import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getOrganizationTypes() {
    return apiClient.get('/organization-types')
  },
  getOrganizationType(id) {
    return apiClient.get('/organization-types/' + id)
  },
  postOrganizationType(organizationType) {
    return apiClient.post('/organization-types', organizationType)
  },
  putOrganizationType(organizationType) {
    return apiClient.put(
      '/organization-types/' + organizationType.id,
      organizationType
    )
  },
  deleteOrganizationType(organizationType) {
    return apiClient.delete('/organization-types/' + organizationType.id)
  }
}
