import EvaluationService from '@/services/manager/EvaluationService'

export const namespaced = true

export const state = {
  evaluations: [],
  evaluation: {}
}
export const mutations = {
  SET_EVALUATION(state, evaluation) {
    state.evaluations = evaluation
  }
}
export const actions = {
  createEvaluation({ dispatch }, evaluation) {
    return EvaluationService.postEvaluation(evaluation.id, evaluation.item)
      .then(() => {
        dispatch('fetchEvaluations', evaluation.id)
      })
      .catch(error => {
        throw error
      })
  },

  fetchEvaluations({ commit }, id) {
    EvaluationService.getEvaluations(id)
      .then(response => {
        commit('SET_EVALUATION', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
