<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de videojuegos"
        >
          <p>Videojuegos</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            small
            fab
            dark
            class="ml-2"
            @click="newItem"
            aria-label="Crear videojuego"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear videojuego</span>
            </v-tooltip>
          </v-btn>
          <VideogameForm
            :item="editedItem"
            v-model="dialog"
            :index="editedIndex"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar videojuego"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        :items="videogames"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.state="{ item }">
          <div v-if="item.state === 1 || item.state === true">Sí</div>
          <div v-else>No</div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon small :color="icon_color" v-on="on" @click="remove(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import VideogameForm from '../../components/admin/VideogameForm'

export default {
  mixins: [deleteMixin],
  components: {
    VideogameForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'administrator-layout')
    this.$store.dispatch('videogame/fetchVideogames')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      videogames: (state) => state.videogame.videogames,
    }),
  },
  data() {
    return {
      search: '',
      dialog: false,
      headers: [
        {
          text: 'TIPO',
          align: 'left',
          value: 'type',
        },
        {
          text: 'VIDEOJUEGO',
          align: 'left',
          value: 'name',
        },
        {
          text: 'ACTIVO',
          align: 'left',
          value: 'state',
        },
        {
          text: 'AUTOR',
          align: 'left',
          value: 'author',
        },
        {
          text: 'ACCIONES',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'videogame/deleteVideogame',
      editedIndex: -1,
      defaultItem: {
        id: null,
        type: '',
        name: '',
        author: '',
        url: '',
        description: '',
        note: '',
        image: '',
        state: false,
      },
      editedItem: {
        id: null,
        type: '',
        name: '',
        author: '',
        url: '',
        description: '',
        note: '',
        image: '',
        state: false,
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.videogames.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    remove(item) {
      if (item.state === 1 || item.state === true) {
        this.num = 1
        this.deleteItem(item, this.register, item.name, this.num)
      } else {
        this.num = 0
        this.deleteItem(item, this.register, item.name, this.num)
      }
    },
  },
}
</script>

<style scoped>
</style>