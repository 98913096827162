import HistoryService from '@/services/laboral-inclusion/HistoryService'

export const namespaced = true

export const state = {
  histories: [],
  history: {}
}
export const mutations = {
  SET_HISTORIES(state, histories) {
    state.histories = histories
  },
  SET_HISTORY(state, history) {
    state.history = history
  },
  UPDATE_HISTORY(state, history) {
    state.histories = [
      ...state.histories.filter(
        element => element.id !== history.id
      ),
      history
    ]
  }
  ,
  DELETE_HISTORY(state, history) {
    const index = state.histories.indexOf(history)
    state.histories.splice(index, 1)
  }
}
export const actions = {
  createHistory({ dispatch, commit }, history) {
    return HistoryService.postHistories(history.id, history.item)
      .then(response => {
        dispatch('fetchHistories', history.id)
        commit('SET_HISTORY', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  createImage({ dispatch, commit }, image) {
    return HistoryService.postImage(image.id, image.formData).then(
      response => {
        dispatch('fetchHistories', image.workshop_id)
        //eslint-disable-next-line
        // console.log(response.data)
        commit('UPDATE_HISTORY', response.data[0])
      }
    )
  },
  fetchHistories({ commit }, id) {
    HistoryService.getHistories(id)
      .then(response => {
        commit('SET_HISTORIES', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  fetchAllHistories({ commit }) {
    HistoryService.getAllHistories()
      .then(response => {
        commit('SET_HISTORIES', response.data)
      })
      .catch(error => {
        throw error
      })
  }
  ,
  updateHistory({ commit }, history) {
    return HistoryService.putHistories(history)
      .then(() => {
        commit('UPDATE_HISTORY', history)
      })
      .catch(error => {
        throw error
      })
  },
  deleteHistory({ commit }, history) {
    return HistoryService.deleteHistories(history)
      .then(() => {
        commit('DELETE_HISTORY', history)
      })
      .catch(error => {
        throw error
      })
  }
}