<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de planes de intervención"
        >
          <p>Plan de intervención</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            small
            fab
            dark
            class="ml-2"
            @click="newItem"
            aria-label="Crear plan de intervención"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear plan de intervención</span>
            </v-tooltip>
          </v-btn>
          <InterventionPlanForm
            :day="day"
            :item="editedItem"
            v-model="dialog"
            :index="editedIndex"
            v-on:update:day="day = $event"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar plan"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        :items="interventionplans"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
        item-key
      >
        <template v-slot:item.player_id="{ item }">
          <div v-for="player in players" :key="player.id">
            <div v-if="player.id === item.player_id">
              {{ player.first_name }} {{ player.last_name }}
            </div>
          </div>
        </template>

        <template v-slot:item.videogame_id="{ item }">
          <div v-for="videogame in videogames" :key="videogame.id">
            <div v-if="videogame.id === item.videogame_id">
              {{ videogame.name }}
            </div>
          </div>
        </template>

        <template v-slot:item.start_date="{ item }">{{
          item.start_date.slice(0, 10)
        }}</template>
        <template v-slot:item.period_id="{ item }">
          <div v-for="period in periods" :key="period.id">
            <div v-if="period.id === item.period_id">{{ period.name }}</div>
          </div>
        </template>
        <template v-slot:item.ability_id="{ item }">
          <div v-for="ability in abilities" :key="ability.id">
            <div v-if="ability.id === item.ability_id">{{ ability.name }}</div>
          </div>
        </template>

        <template v-slot:item.days="{ item }">
          <div v-for="(day, index) in item.days" :key="index">
            {{ day.day }}
          </div>
        </template>

        <template v-slot:item.evaluate="{ item }">
          <div v-if="item.state === 'cancelado'">
            <v-btn
              disabled
              @click="evaluate(item)"
              icon
              :color="icon_color"
              small
              class="mr-5"
              aria-label="Botón evaluar deshabilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-file-document</v-icon>
                </template>
                <span>Evaluar</span>
              </v-tooltip>
            </v-btn>

            <v-btn
              disabled
              @click="stop(item)"
              icon
              :color="icon_color"
              small
              class="mr-5"
              aria-label="Botón suspender deshabilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-progress-close</v-icon>
                </template>
                <span>Suspender</span>
              </v-tooltip>
            </v-btn>

            <v-btn
              disabled
              @click="increase(item)"
              icon
              :color="icon_color"
              small
              class="mr-5"
              aria-label="Botón aumentar sesiones deshabilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-progress-upload</v-icon>
                </template>
                <span>Aumentar sesiones</span>
              </v-tooltip>
            </v-btn>
          </div>

          <div v-else>
            <v-btn
              @click="evaluate(item)"
              icon
              :color="icon_color"
              small
              class="mr-1"
              aria-label="Botón evaluar habilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-file-document</v-icon>
                </template>
                <span>Evaluar</span>
              </v-tooltip>
            </v-btn>

            <v-btn
              @click="stop(item)"
              icon
              :color="icon_color"
              small
              class="mr-1"
              aria-label="Botón suspender habilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-progress-close</v-icon>
                </template>
                <span>Suspender</span>
              </v-tooltip>
            </v-btn>

            <v-btn
              @click="increase(item)"
              icon
              :color="icon_color"
              small
              class="mr-1"
              aria-label="Botón aumentar sesiones habilitado"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-progress-upload</v-icon>
                </template>
                <span>Aumentar sesiones</span>
              </v-tooltip>
            </v-btn>

            <v-tooltip
              v-if="typeuser.type === 'manager'"
              bottom
              :color="tooltip_color"
            >
              <template v-slot:activator="{ on }">
                <router-link
                  aria-label="Enlace que redirige a la página de reporte de evaluaciones de plan de intervención"
                  :to="{
                    name: 'printeable-reports',
                    params: {
                      player_id: item.player_id,
                      id_iplan: item.id,
                      game_name: item.videogame_id,
                      ability_id: item.ability_id,
                    },
                  }"
                >
                  <v-icon class="mr-1" v-on="on" :color="link"
                    >mdi-file-document</v-icon
                  >
                </router-link>
              </template>
              <span>Reporte de evaluaciones</span>
            </v-tooltip>
            <v-tooltip v-else bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <router-link
                  aria-label="Enlace que redirige a la página de reporte de evaluaciones de plan de intervención"
                  :to="{
                    name: 'printeable-reports-tutor',
                    params: {
                      player_id: item.player_id,
                      id_iplan: item.id,
                      game_name: item.videogame_id,
                      ability_id: item.ability_id,
                    },
                  }"
                >
                  <v-icon class="mr-1" v-on="on" :color="link"
                    >mdi-file-document</v-icon
                  >
                </router-link>
              </template>
              <span>Reporte de evaluaciones</span>
            </v-tooltip>
            <EvaluationForm
              :item="editedItem"
              v-model="dialog_two"
              :index="editedIndex"
              :action="action"
            />
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div v-if="item.state === 'cancelado'">
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon
                  disabled
                  small
                  :color="icon_color"
                  v-on="on"
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil-outline</v-icon
                >
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon
                  disabled
                  small
                  :color="icon_color"
                  v-on="on"
                  @click="remove(item)"
                  @click.native="dialog3 = true"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  :color="icon_color"
                  v-on="on"
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil-outline</v-icon
                >
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  :color="icon_color"
                  v-on="on"
                  @click="remove(item)"
                  @click.native="dialog3 = true"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Eliminar</span>
            </v-tooltip>

            <v-dialog v-model="dialog3" width="350" :retain-focus="false" dark>
              <v-card :color="color" dark>
                <center class="pt-5">
                  <v-img src="/img/info.png" width="100"></v-img>
                </center>
                <v-card-text
                  class="text-center pt-2 body-1"
                  style="font-family: Helvetica"
                  >{{ error }}</v-card-text
                >

                <v-card-actions class="justify-center">
                  <v-btn
                    width="100"
                    :color="icon_color"
                    @click="dialog3 = false"
                    depressed
                    class="text-center text-capitalize body-1"
                    >Entendido</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import InterventionPlanForm from '../../components/manager/InterventionPlanForm'
import EvaluationForm from '../../components/manager/EvaluationForm'

export default {
  mixins: [deleteMixin],
  components: {
    InterventionPlanForm,
    EvaluationForm,
  },
  created() {
    this.typeuser = JSON.parse(localStorage.getItem('user'))

    if (this.typeuser.type === 'manager') {
      this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
    } else {
      this.$store.commit('layout/SET_LAYOUT', 'tutor-layout')
    }
    this.$store.dispatch(
      'interventionplan/fetchInterventionPlans',
      this.$route.params.id
    )
    this.$store.dispatch('videogame/fetchVideogames')
    this.$store.dispatch('player/fetchPlayers', this.$route.params.id)
    this.$store.dispatch('ability/fetchAbilities', this.$route.params.id)
    this.$store.dispatch('period/fetchPeriods', this.$route.params.id)
    this.$store.dispatch('evaluation/fetchEvaluations', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      interventionplans: (state) => state.interventionplan.intervention_plans,
      videogames: (state) => state.videogame.videogames,
      players: (state) => state.player.players,
      abilities: (state) => state.ability.abilities,
      qualification_scales: (state) =>
        state.qualificationscale.qualification_scales,
      periods: (state) => state.period.periods,
    }),
    error() {
      return this.$store.state.interventionplan.error
    },
  },

  data() {
    return {
      typeuser: '',
      search: '',
      num: '',
      dialog: false,
      dialog_two: false,
      dialog3: false,
      action: '',
      user: '',
      on: '',
      id: 0,
      usertype: '',
      plan_id: 0,
      headers: [
        {
          text: 'CÓDIGO',
          align: 'left',
          value: 'code',
        },
        {
          text: 'USUARIO',
          align: 'left',
          value: 'player_id',
        },
        {
          text: 'HABILIDAD',
          align: 'left',
          value: 'ability_id',
        },
        {
          text: 'VIDEOJUEGO',
          align: 'left',
          value: 'videogame_id',
        },

        {
          text: 'FECHA INICIO',
          align: 'left',
          value: 'start_date',
        },
        {
          text: 'PERIODO',
          align: 'left',
          value: 'period_id',
        },
        {
          text: 'SESIÓN',
          align: 'left',
          value: 'number_session',
        },
        {
          text: 'DÍAS',
          align: 'left',
          value: 'days',
        },
        {
          text: 'VALORACIONES',
          value: 'evaluate',
          align: 'center',
          width: '200px',
        },
        {
          text: 'ACCIONES',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'interventionplan/deleteInterventionPlan',
      editedIndex: -1,
      defaultItem: {
        id: null,
        organization_id: null,
        player_id: null,
        ability_id: null,
        videogame_id: null,
        qualification_scale_id: null,
        period_id: null,
        number_session: '',
        time: 0,
        start_date: '',
        code: '',
        days: '',
        state: false,
      },
      editedItem: {
        id: null,
        organization_id: null,
        player_id: null,
        ability_id: null,
        videogame_id: null,
        qualification_scale_id: null,
        period_id: null,
        number_session: '',
        time: 0,
        start_date: '',
        code: '',
        days: '',
        state: false,
      },
      day: [],
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.interventionplans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.day = this.editedItem.days.map((element) => element.day)
      this.dialog = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    remove(item) {
      this.$store.dispatch('interventionplan/deleteInterventionPlan', item)
    },
    evaluate(item) {
      this.editedIndex = this.interventionplans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.id = this.qualification_scales.filter(
        (element) => element.id === this.editedItem.qualification_scale_id
      )
      this.$store.dispatch(
        'qualificationcode/fetchQualification_Code',
        this.id[0].id
      )
      this.action = 'Evaluar'
      this.dialog_two = true
    },
    stop(item) {
      if (item.state === 'cancelado') {
        location.reload()
      } else {
        this.action = 'Suspender'
        this.editedIndex = this.interventionplans.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.id = this.qualification_scales.filter(
          (element) => element.id === this.editedItem.qualification_scale_id
        )
        this.$store.dispatch(
          'qualificationcode/fetchQualification_Code',
          this.id[0].id
        )
        this.dialog_two = true
      }
    },
    increase(item) {
      this.editedIndex = this.interventionplans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.id = this.qualification_scales.filter(
        (element) => element.id === this.editedItem.qualification_scale_id
      )
      this.$store.dispatch(
        'qualificationcode/fetchQualification_Code',
        this.id[0].id
      )
      this.action = 'Aumentar sesiones'
      this.dialog_two = true
    },
  },
}
</script>

<style scoped>
</style>