import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getSession(id) {
    return apiClient.get('/intervention-plans/' + id + '/sessions')
  },
  postSession(id, session) {
    return apiClient.post('/intervention-plans/' + id + '/sessions', session)
  },
  putSession(session) {
    return apiClient.put('/sessions/' + session.id, session)
  },
  deleteSession(session) {
    return apiClient.delete('/sessions' + session.id)
  }
}
