import GameGraphicReportService from '@/services/reports/GameGraphicReportService'

export const namespaced = true

export const state = {
    report: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    }
}

export const actions = {
    fetchGameGraphicreport({ commit }, item) {
        GameGraphicReportService.getGamesGraphicReports(item)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchGameGraphicFiltredreport({ commit }, item) {
        GameGraphicReportService.getGamesGraphicFiltredReports(item)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}