<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12" md="2" sm="2">
          <router-link
            class="mr-2"
            :to="{ name: 'gamecustomizer' }"
            :style="{ color: link }"
            >Personalizar Juegos</router-link
          >
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <router-link
            class="mr-5"
            :to="{ name: 'workshop' }"
            :style="{ color: link }"
            >/Talleres</router-link
          >
        </v-col>
        <v-col cols="12" md="8" sm="8"></v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de historias"
        >
          <p>Historias</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            fab
            small
            dark
            class="ml-2"
            @click="newItem"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear Historia</span>
            </v-tooltip>
          </v-btn>
          <HistoryForm
            :item="editedItem"
            v-model="dialog"
            :disabled="disabled"
            :index="editedIndex"
            :lamines="lamines"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Historia"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :headers="headers"
        :items="histories"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image"
            :src="item.image"
            height="80px"
            width="80px"
            aspect-ratio="2"
            class="ma-2 ml-5"
            contain
          ></v-img>
          <p v-else>cargando img</p>
        </template>
        <template v-slot:item.dificulty="{ item }">
          <div v-for="lamine in sequence" :key="lamine.id">
            <div v-if="lamine.history_id === item.id">
              <div v-if="lamine.lamines_number < 6">Fácil</div>
              <div v-else-if="lamine.lamines_number < 10">Normal</div>
              <div v-else>Difícil</div>
            </div>
          </div>
        </template>
        <template v-slot:item.published="{ item }">
          <div v-if="item.published === 1 || item.published === true">Sí</div>
          <div v-else>No</div>
        </template>
        <template v-slot:item.lamine_number="{ item }">
          <div v-if="item.id">
            <div v-if="selectedLamine(item.id) == false">
              <router-link
                aria-label="Enlace que redirige a la página de parametrizacion de Láminas"
                :style="{ color: icon_color }"
                :to="{
                  name: 'sequence',
                  params: { history_id: item.id },
                }"
              >
                <v-tooltip bottom :color="tooltip_color">
                  <template v-slot:activator="{ on }">
                    <p v-on="on" class="text-center ma-2">0</p>
                  </template>
                  <span>Añadir Lámina</span>
                </v-tooltip>
              </router-link>
            </div>
            <div v-else>
              <div v-for="lamine in sequence" :key="lamine.id">
                <div v-if="lamine.history_id === item.id">
                  <router-link
                    aria-label="Enlace que redirige a la página de parametrizacion de Láminas"
                    :style="{ color: icon_color }"
                    :to="{
                      name: 'sequence',
                      params: { history_id: item.id },
                    }"
                  >
                    <v-tooltip bottom :color="tooltip_color">
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="text-center ma-2">
                          {{ lamine.lamines_number }}
                        </p>
                      </template>
                      <span>Añadir Lámina</span>
                    </v-tooltip>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.vocabulary="{ item }">
          <div v-if="item.id">
            <div v-if="selected(item.id) == false">
              <router-link
                aria-label="Enlace que redirige a la página de parametrizacion de juego"
                :style="{ color: icon_color }"
                :to="{
                  name: 'vocabulary',
                  params: { history_id: item.id },
                }"
              >
                <v-tooltip bottom :color="tooltip_color">
                  <template v-slot:activator="{ on }">
                    <p v-on="on" class="text-center ma-2">0</p>
                  </template>
                  <span>Añadir Vocabulario</span>
                </v-tooltip>
              </router-link>
            </div>
            <div v-else>
              <div v-for="word in vocabulary" :key="word.id">
                <div v-if="word.history_id === item.id">
                  <router-link
                    :style="{ color: icon_color }"
                    aria-label="Enlace que redirige a la página de parametrizacion de juego"
                    :to="{
                      name: 'vocabulary',
                      params: { history_id: item.id },
                    }"
                  >
                    <v-tooltip bottom :color="tooltip_color">
                      <template v-slot:activator="{ on }">
                        <p v-on="on" class="text-center ma-2">
                          {{ word.words_number }}
                        </p>
                      </template>
                      <span>Añadir Vocabulario</span>
                    </v-tooltip>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip
            bottom
            :color="tooltip_color"
            v-if="
              selectedLamine(item.id) == false && selected(item.id) == false
            "
          >
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                @click="deleteItem(item, register, item.name)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color" v-else>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                @click="cantErraseMessage(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import HistoryForm from '../../components/laboral-inclusion/HistoryForm'

export default {
  mixins: [deleteMixin],
  components: {
    HistoryForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
    this.$store.dispatch(
      'history/fetchHistories',
      this.$route.params.workshop_id
    )
    this.$store.dispatch('vocabulary/fetchAllVocabulary')
    this.$store.dispatch('sequence/fetchAllLamines')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.primary,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      histories: (state) => state.history.histories,
      vocabulary: (state) => state.vocabulary.vocabulary,
      sequence: (state) => state.sequence.Sequence,
    }),
  },
  data() {
    return {
      history_id: '',
      search: '',
      dialog: false,
      disabled: false,
      headers: [
        {
          text: 'IMAGEN',
          align: 'left',
          value: 'image',
          width: '125px',
        },
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'name',
          width: '170px',
        },
        {
          text: 'DESCRIPCIÓN',
          align: 'left',
          value: 'description',
        },
        {
          text: 'VOCABULARIO',
          align: 'left',
          value: 'vocabulary',
        },
        {
          text: 'LÁMINAS',
          align: 'center',
          value: 'lamine_number',
        },
        {
          text: 'DIFICULTAD',
          align: 'left',
          value: 'dificulty',
        },
        {
          text: 'PUBLICADO',
          align: 'left',
          value: 'published',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'history/deleteHistory',
      editedIndex: -1,
      lamines: 0,
      defaultItem: {
        id: null,
        name: '',
        description: '',
        lamine_number: '',
      },
      editedItem: {
        id: null,
        name: '',
        description: '',
        lamine_number: '',
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.histories.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.lamines = this.numberOfLamines(item)
      this.dialog = true
      this.disabled = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.lamines = 0
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      this.disabled = false
    },
    numberOfLamines(item) {
      let number = 0
      for (var i = 0; i < this.sequence.length; i++) {
        if (item.id == this.sequence[i].history_id) {
          number = this.sequence[i].lamines_number
        }
      }
      return number
    },
    selected(id) {
      var is_id_there = false
      for (var i = 0; i < this.vocabulary.length; i++) {
        if (this.vocabulary[i].history_id === id) {
          is_id_there = true
          break
        }
      }
      return is_id_there
    },
    selectedLamine(id) {
      var is_id_there = false
      for (var i = 0; i < this.sequence.length; i++) {
        if (this.sequence[i].history_id === id) {
          is_id_there = true
          break
        }
      }
      return is_id_there
    },
    cantErraseMessage(item) {
      this.$swal({
        html:
          `<span style="color: #FBC02D; font-family: Helvetica">${item.name}</span>` +
          `<br>` +
          `<br>` +
          `<span style="color: white; font-family: Helvetica">No se puede eliminar esta historia, primero elimine las láminas y/o vocabulario relacionados a esta historia<span>`,
        type: 'warning',
        animation: false,
        background: '#0B5683',
        width: '350px',
        confirmButtonColor: '#FBC02D',
        showConfirmButton: true,
        confirmButtonText:
          "<span style='font-family: Helvetica'>Aceptar</span>",
      })
    },
  },
}
</script>

<style scoped>
</style>