<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12" md="2" sm="2">
          <router-link
            class="mr-2"
            :to="{ name: 'gamecustomizer' }"
            :style="{ color: link }"
            >Personalizar Juegos</router-link
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de talleres"
        >
          <p>Talleres</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            fab
            small
            dark
            class="ml-2"
            @click="newItem"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear Taller</span>
            </v-tooltip>
          </v-btn>
          <WorkshopForm
            :item="editedItem"
            v-model="dialog"
            :disabled="disabled"
            :index="editedIndex"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Taller"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :headers="headers"
        :items="workshops"
        :search="search"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image"
            :src="item.image"
            height="80px"
            width="80px"
            aspect-ratio="2"
            class="ma-2 ml-5"
            contain
          ></v-img>
          <p v-else>cargando img</p>
        </template>

        <template v-slot:item.state="{ item }">
          <div v-if="item.state === 1 || item.state === true">Sí</div>
          <div v-else>No</div>
        </template>

        <template v-slot:item.organization_id="{ item }">
          <div v-if="selected(item.id) == false">
            <router-link
              :style="{ color: icon_color }"
              aria-label="Enlace que redirige a la página de parametrizacion de juego"
              :to="{
                name: 'history',
                params: {
                  workshop_id: item.id,
                },
              }"
            >
              <v-tooltip bottom :color="tooltip_color">
                <template v-slot:activator="{ on }">
                  <span v-on="on">0</span>
                </template>
                <span>Crear Historia</span>
              </v-tooltip>
            </router-link>
          </div>
          <div v-else>
            <div v-for="history in histories_number" :key="history.id">
              <div v-if="history.workshop_id === item.id">
                <router-link
                  :style="{ color: icon_color }"
                  aria-label="Enlace que redirige a la página de parametrizacion de juego"
                  :to="{
                    name: 'history',
                    params: {
                      workshop_id: item.id,
                    },
                  }"
                >
                  <v-tooltip bottom :color="tooltip_color">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ history.history_number }}</span>
                    </template>

                    <span>Crear Historia</span>
                  </v-tooltip>
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip
            bottom
            :color="tooltip_color"
            v-if="selected(item.id) == false"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                @click="deleteItem(item, register, item.name)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color" v-else>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                @click="cantErraseMessage(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import WorkshopForm from '../../components/laboral-inclusion/WorkshopForm'

export default {
  mixins: [deleteMixin],
  components: {
    WorkshopForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
    this.$store.dispatch('workshop/fetchWorkshops', this.$route.params.id)
    this.$store.dispatch('history/fetchAllHistories')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.primary,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      workshops: (state) => state.workshop.workshops,
      histories_number: (state) => state.history.histories,
    }),
  },
  data() {
    return {
      search: '',
      dialog: false,
      disabled: false,
      headers: [
        {
          text: 'IMAGEN',
          align: 'left',
          value: 'image',
          width: '200px',
        },
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'name',
          width: '100px',
        },
        {
          text: 'DESCRIPCIÓN',
          align: 'left',
          value: 'description',
          width: '400px',
        },
        {
          text: 'HISTORIAS',
          align: 'left',
          value: 'organization_id',
          width: '100px',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'workshop/deleteWorkshops',
      editedIndex: -1,
      defaultItem: {
        id: null,
        organization_id: null,
        name: '',
        image: '',
        description: '',
        stories: '',
      },
      editedItem: {
        id: null,
        organization_id: null,
        name: '',
        image: '',
        description: '',
        stories: '',
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.workshops.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.disabled = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      this.disabled = false
    },
    selected(id) {
      var is_id_there = false
      for (var i = 0; i < this.histories_number.length; i++) {
        if (this.histories_number[i].workshop_id === id) {
          is_id_there = true
          break
        }
      }
      return is_id_there
    },
    cantErraseMessage(item) {
      this.$swal({
        html:
          `<span style="color: #FBC02D; font-family: Helvetica">${item.name}</span>` +
          `<br>` +
          `<br>` +
          `<span style="color: white; font-family: Helvetica">No se puede eliminar este Taller, primero elimine las historias relacionadas a este Taller<span>`,
        type: 'warning',
        animation: false,
        background: '#0B5683',
        width: '350px',
        confirmButtonColor: '#FBC02D',
        showConfirmButton: true,
        confirmButtonText:
          "<span style='font-family: Helvetica'>Aceptar</span>",
      })
    },
  },
}
</script>

<style scoped>
</style>