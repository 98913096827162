import QualificationCodeService from '@/services/admin/QualificationCodeService'

export const namespaced = true

export const state = {
  qualification_codes: [],
  qualification_code: {}
}

export const mutations = {
  UPDATE_QUALIFICATION_CODE(state, evaluationCode) {
    state.qualification_codes = [
      ...state.qualification_codes.filter(
        element => element.id !== evaluationCode.id
      ),
      evaluationCode
    ]
  },
  SET_QUALIFICATION_CODE(state, evaluationCode) {
    state.qualification_codes = evaluationCode
  },
  DELETE_QUALIFICATION_CODE(state, evaluationCode) {
    const index = state.qualification_codes.indexOf(evaluationCode)
    state.qualification_codes.splice(index, 1)
  }
}

export const actions = {
  createQualification_Code({ dispatch }, evaluationCode) {
    return QualificationCodeService.postQualification_Code(
      evaluationCode.id,
      evaluationCode.item
    )
      .then(() => {
        dispatch('fetchQualification_Code', evaluationCode.id)
      })
      .catch(error => {
        throw error
      })
  },
  fetchQualification_Code({ commit }, id) {
    QualificationCodeService.getQualification_Code(id)
      .then(response => {
        commit('SET_QUALIFICATION_CODE', response.data)
      })
      .catch(error => {
        throw error
      })
  },
  updateQualification_Code({ commit }, evaluationCode) {
    return QualificationCodeService.putQualification_Code(evaluationCode)
      .then(() => {
        commit('UPDATE_QUALIFICATION_CODE', evaluationCode)
      })
      .catch(error => {
        throw error
      })
  },
  deleteQualification_Code({ commit }, evaluationCode) {
    return QualificationCodeService.deleteQualification_Code(evaluationCode)
      .then(() => {
        commit('DELETE_QUALIFICATION_CODE', evaluationCode)
      })
      .catch(error => {
        throw error
      })
  }
}
