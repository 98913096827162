import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getAbilitiesGraphicReports(item) {
        //:organization_id/player/:player_id/graphic-report
        return apiClient.get('organization/' + item.organization_id + '/player/' + item.player_id + "/graphic-report")
    },
    getAbilitiesGraphicFiltredReports(item) {
        if (item.ability_id) {
            return apiClient.get('organization/' + item.organization_id + '/player/' + item.player_id + "/graphic-report" + "?ability_id=" + item.ability_id
                + "&initialPeriod=" + item.initialPeriod + "&finalPeriod=" + item.finalPeriod + "&initialYear=" + item.initialYear + "&finalYear=" + item.finalYear)
        }
        else {
            return apiClient.get('organization/' + item.organization_id + '/player/' + item.player_id + "/graphic-report?initialPeriod=" + item.initialPeriod +
                "&finalPeriod=" + item.finalPeriod + "&initialYear=" + item.initialYear + "&finalYear=" + item.finalYear)
        }
    }
}