export default [
  { id: 1, value: 1, option: '1' },
  { id: 2, value: 2, option: '2' },
  { id: 3, value: 3, option: '3' },
  { id: 4, value: 4, option: '4' },
  { id: 5, value: 5, option: '5' },
  { id: 6, value: 6, option: '6' },
  { id: 7, value: 7, option: '7' },
  { id: 8, value: 8, option: '8' },
  { id: 9, value: 9, option: '9' },
  { id: 10, value: 10, option: '10' },
  { id: 11, value: 11, option: '11' },
  { id: 12, value: 12, option: '12' },
  { id: 13, value: 13, option: '13' },
  { id: 14, value: 14, option: '14' },
  { id: 15, value: 15, option: '15' },
  { id: 16, value: 16, option: '16' },
  { id: 17, value: 17, option: '17' },
  { id: 18, value: 18, option: '18' },
  { id: 19, value: 19, option: '19' },
  { id: 20, value: 20, option: '20' },
  { id: 21, value: 21, option: '21' },
  { id: 22, value: 22, option: '22' },
  { id: 23, value: 23, option: '23' },
  { id: 24, value: 24, option: '24' },
  { id: 25, value: 25, option: '25' },
  { id: 26, value: 26, option: '26' },
  { id: 27, value: 27, option: '27' },
  { id: 28, value: 28, option: '28' },
  { id: 29, value: 29, option: '29' },
  { id: 30, value: 30, option: '30' }
]
