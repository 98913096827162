import OrganizationTypeService from '@/services/admin/OrganizationTypeService'

export const namespaced = true

export const state = {
  organizationTypes: [],
  organizationType: {}
}

export const mutations = {
  UPDATE_ORGANIZATION_TYPE(state, organizationType) {
    state.organizationTypes = [
      ...state.organizationTypes.filter(
        element => element.id !== organizationType.id
      ),
      organizationType
    ]
  },
  DELETE_ORGANIZATION_TYPE(state, organizationType) {
    const index = state.organizationTypes.indexOf(organizationType)
    state.organizationTypes.splice(index, 1)
  },
  SET_ORGANIZATIONS_TYPES(state, organizationTypes) {
    state.organizationTypes = organizationTypes
  },
  SET_ORGANIZATION_TYPE(state, organizationType) {
    state.organizationType = organizationType
  }
}

export const actions = {
  createOrganizationType({ dispatch }, organizationType) {
    return OrganizationTypeService.postOrganizationType(organizationType)
      .then(() => {
        dispatch('fetchOrganizationTypes')
      })
      .catch(error => {
        throw error
      })
  },
  updateOrganizationType({ commit }, organizationType) {
    return OrganizationTypeService.putOrganizationType(organizationType)
      .then(() => {
        commit('UPDATE_ORGANIZATION_TYPE', organizationType)
      })
      .catch(error => {
        throw error
      })
  },
  deleteOrganizationType({ commit }, organizationType) {
    return OrganizationTypeService.deleteOrganizationType(organizationType)
      .then(() => {
        commit('DELETE_ORGANIZATION_TYPE', organizationType)
      })
      .catch(error => {
        throw error
      })
  },
  fetchOrganizationTypes({ commit }) {
    OrganizationTypeService.getOrganizationTypes()
      .then(response => {
        commit('SET_ORGANIZATIONS_TYPES', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
