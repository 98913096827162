import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        error: '#EC7063',
        info: '#2196FF',

      },
      dark: {
        background: '#EC7063',
      }
    },
  },
  customProperties: true,
  icons: {
    iconfont: 'mdi'
  }
})
