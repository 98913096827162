export default [
    { id: 1, value: 2010, option: '2010' },
    { id: 2, value: 2012, option: '2011' },
    { id: 3, value: 2013, option: '2013' },
    { id: 4, value: 2014, option: '2014' },
    { id: 5, value: 2015, option: '2015' },
    { id: 6, value: 2016, option: '2016' },
    { id: 7, value: 2017, option: '2017' },
    { id: 8, value: 2018, option: '2018' },
    { id: 9, value: 2019, option: '2019' },
    { id: 10, value: 2020, option: '2020' },
    { id: 11, value: 2021, option: '2021' },
    { id: 12, value: 2022, option: '2022' },
    { id: 13, value: 2023, option: '2023' },
    { id: 14, value: 2024, option: '2024' },
    { id: 15, value: 2025, option: '2025' },
    { id: 16, value: 2026, option: '2026' },
    { id: 17, value: 2027, option: '2027' },
    { id: 18, value: 2028, option: '2028' },
    { id: 19, value: 2029, option: '2029' },
    { id: 20, value: 2030, option: '2030' },
    { id: 21, value: 2031, option: '2031' },
    { id: 22, value: 2032, option: '2032' },
    { id: 23, value: 2033, option: '2033' },
    { id: 24, value: 2034, option: '2034' },
    { id: 25, value: 2035, option: '2035' },
    { id: 26, value: 2036, option: '2036' },
    { id: 27, value: 2037, option: '2037' },
    { id: 28, value: 2038, option: '2038' },
    { id: 29, value: 2039, option: '2039' },
    { id: 30, value: 2040, option: '2040' },
    { id: 31, value: 2041, option: '2041' },
    { id: 32, value: 2042, option: '2042' },
    { id: 33, value: 2043, option: '2043' },
    { id: 34, value: 2044, option: '2044' },
    { id: 35, value: 2045, option: '2045' },
    { id: 36, value: 2046, option: '2046' },
    { id: 37, value: 2047, option: '2047' },
    { id: 38, value: 2048, option: '2048' },
    { id: 39, value: 2049, option: '2049' },
    { id: 40, value: 2050, option: '2050' },
    { id: 41, value: 2051, option: '2051' },
    { id: 42, value: 2052, option: '2052' },
    { id: 43, value: 2053, option: '2053' },
    { id: 44, value: 2054, option: '2054' },
    { id: 45, value: 2055, option: '2055' },
    { id: 46, value: 2056, option: '2056' },
    { id: 47, value: 2057, option: '2057' },
    { id: 48, value: 2058, option: '2058' },
    { id: 49, value: 2059, option: '2059' },
    { id: 50, value: 2060, option: '2060' },
]