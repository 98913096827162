<template>
  <v-app id="tutor" :style="background">
    <v-navigation-drawer
      v-model="drawer"
      :color="icon_color"
      dark
      width="238px"
      app
      clipped
    >
      <template v-slot:prepend>
        <div v-if="logoImage[0]">
          <div v-if="logoImage[0].logo_url != ''">
            <Menuheader
              :title="logoImage[0].name"
              :logo="logoImage[0].logo_url"
            />
          </div>
          <div v-else>
            <Menuheader :title="logoImage[0].name" />
          </div>
        </div>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          :to="option.path"
          @click.stop
        >
          <v-list-item-action>
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="this.tutor.plan === 1"
          :to="plan_intervention.path"
        >
          <v-list-item-action>
            <v-icon>{{ plan_intervention.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ plan_intervention.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark :color="color" class="pl-7 pr-7">
      <v-app-bar-nav-icon
        elevation="0"
        @click.stop="drawer = !drawer"
        aria-label="menu izquierdo del administrador del tutor"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <h1 class="title">Tutor</h1>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <Logoutcard :username="fullName" :photo="photo" />
    </v-app-bar>

    <v-main app>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Menuheader from '../../components/MenuHeader'
import Logoutcard from '../../components/LogoutCard'
import Footer from '../../components/Footer'

export default {
  components: {
    Menuheader,
    Logoutcard,
    Footer,
  },
  created() {
    this.tutor = JSON.parse(localStorage.getItem('user'))
    this.$store.dispatch('organization/fetchOrganizations')
  },
  data() {
    return {
      drawer: null,
      titleMenu: 'Tutor',
      tutor: {},
      photo: '',
      options: [
        {
          title: 'Reportes',
          icon: 'mdi-chart-line',
          path: { name: 'reports' },
        },
      ],
      plan_intervention: {
        title: 'Plan de intervención',
        icon: 'mdi-file-document-edit-outline',
        path: { name: 'tutorinterventionplans' },
      },
    }
  },
  computed: {
    background() {
      return {
        background: '#ABB2B9',
      }
    },
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color_bar,
      primary: (state) => state.layout.primary,
      userlogued: (state) => state.auth.user,
      organizations: (state) => state.organization.organizations,
    }),
    fullName() {
      return this.tutor.full_name
    },
    logoImage() {
      return this.organizations.filter(
        (element) => element.id == this.$route.params.id
      )
    },
  },
}
</script>