<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.first_name"
                  label="Nombre"
                  v-validate="'required|max:30|alpha_spaces'"
                  data-vv-name="first_name"
                  :error-messages="errors.collect('first_name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.last_name"
                  label="Apellido"
                  v-validate="'required|max:30|alpha_spaces'"
                  data-vv-name="last_name"
                  :error-messages="errors.collect('last_name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-menu
                  v-model="menu"
                  ref="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :color="icon_color"
                      :value="slice(item.birth_date)"
                      label="Fecha de nacimiento"
                      append-icon="mdi-calendar-month-outline"
                      readonly
                      v-on="on"
                      v-validate="'required'"
                      data-vv-name="birth_day"
                      :error-messages="errors.collect('birth_day')"
                      hint="obligatorio"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :color="icon_color"
                    v-model="item.birth_date"
                    ref="picker"
                    no-title
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1930-01-01"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.representative"
                  label="Nombre del contacto"
                  v-validate="'required|max:45|alpha_spaces'"
                  data-vv-name="representative"
                  :error-messages="errors.collect('representative')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  maxlength="10"
                  :color="icon_color"
                  v-model="item.telephone"
                  label="Celular del contacto"
                  v-validate="'numeric|digits: 10'"
                  data-vv-name="telephone"
                  :error-messages="errors.collect('telephone')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-textarea
                  class="mx-2"
                  v-model="item.diagnostic"
                  label="Necesidades específicas de apoyo"
                  rows="2"
                  :color="icon_color"
                  v-validate="'max:1024'"
                  data-vv-name="diagnostic"
                  :error-messages="errors.collect('diagnostic')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-textarea
                  class="mx-2"
                  v-model="item.characteristic"
                  label="Características"
                  rows="2"
                  :color="icon_color"
                  v-validate="'max:1024'"
                  data-vv-name="characteristic"
                  :error-messages="errors.collect('characteristic')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="6" sm="6">
                <p>Activar</p>
                <v-switch :color="icon_color" v-model="item.state"></v-switch>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.first_name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1 ? 'Nuevo usuario' : 'Editar usuario'
    },
    text() {
      return this.item.email_confirmation === 0
        ? '* El correo no está confirmado'
        : ''
    },
  },
  data() {
    return {
      menu: false,
      dialog: false,
      dictionary: {
        custom: {
          first_name: {
            required: () => 'El nombre no puede ser vacío',
            max: () => 'El nombre debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'Nombre debe contener solo letras',
          },
          last_name: {
            required: () => 'El apellido no puede ser vacío',
            max: () => 'El apellido debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'Apellido debe contener solo letras',
          },
          birth_day: {
            required: () => 'La fecha de nacimiento no puede ser vacío',
            numeric: () => 'La fecha de nacimiento debe contener solo números.',
          },
          diagnostic: {
            max: () => 'El campo debe contener máximo 1024 dígitos.',
          },
          characteristic: {
            max: () => 'El campo debe contener máximo 1024 dígitos.',
          },
          telephone: {
            digits: () => 'El celular debe contener 10 dígitos',
          },
          representative: {
            required: () => 'El campo contacto no puede ser vacío',
            max: () => 'El nombre debe ser máximo de 45 caracteres',
            alpha_spaces: () =>
              'El nombre del contacto debe contener solo letras',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    slice(item) {
      if (item) {
        return item.slice(0, 10)
      } else {
        return ''
      }
    },
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.item.birth_date = await new Date(this.item.birth_date)
            .toISOString()
            .substring(0, 10)
          this.$store.dispatch('player/updatePlayer', this.item)
        } else {
          this.$store.dispatch('player/createPlayer', {
            id: this.$route.params.id,
            item: this.item,
          })
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>