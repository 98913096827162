import apiClient from './configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  postUser(credentials) {
    return apiClient.post('/users/confirm', credentials)
  }
}
