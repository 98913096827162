<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="500px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col v-if="this.index > -1" cols="12" xs="6" sm="12">
                <v-file-input
                  v-model="file"
                  label="Imagen"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  v-validate="'size:2000'"
                  prepend-icon="mdi-camera"
                  :color="icon_color"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
              <v-col cols="12" xs="6" sm="12" v-else>
                <v-file-input
                  v-model="file"
                  label="Imagen"
                  show-size
                  accept="image/*"
                  data-vv-name="image"
                  :color="icon_color"
                  prepend-icon="mdi-camera"
                  v-validate="'required|size:2000'"
                  :error-messages="errors.collect('image')"
                  hint="obligatorio"
                  persistent-hint
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-text-field
                  :color="icon_color"
                  v-model="item.name"
                  label="Titulo"
                  data-vv-name="history_title"
                  v-validate="'required|max:30'"
                  :maxlength="max"
                  :error-messages="errors.collect('history_title')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="6" sm="12">
                <v-textarea
                  v-model="item.description"
                  label="Descripción"
                  auto-grow
                  :color="icon_color"
                  rows="2"
                  row-height="30"
                  maxlength="1024"
                  data-vv-name="description"
                  v-validate="'required|max:1024'"
                  :error-messages="errors.collect('description')"
                  hint="obligatorio"
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="6"
                sm="12"
                v-if="index > -1 && lamines < 13 && lamines > 2"
              >
                <p>Publicar</p>
                <v-switch
                  :color="icon_color"
                  v-model="item.published"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                xs="6"
                sm="12"
                v-else-if="index != -1"
                class="text-padding"
              >
                <p>Publicar</p>
                <v-switch :disabled="disabled"></v-switch>
                <span class="mb-0 blue--text">{{ text }}</span>
              </v-col>
              <v-col cols="12" xs="6" sm="12">
                <span class="text-left mb-0 blue--text">{{ information }}</span>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
    disabled: Boolean,
    lamines: Number,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      history: (state) => state.history.history,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.description[0]
    },
    formTitle() {
      return this.index === -1 ? 'Nueva Historia' : 'Editar Historia'
    },
    text() {
      let text =
        '* Para poder publicar una historia, el número de láminas no debe ser menor a 3 y mayor a 12'
      if (this.lamines > 2 && this.lamines < 13) {
        text = ''
      }
      return text
    },
  },
  created() {
    this.$store.dispatch(
      'history/fetchHistories',
      this.$route.params.workshop_id
    )
  },
  data() {
    return {
      file: [],
      max: 30,
      information:
        '* El tamaño de la imagen debe ser menor a 2MB, y con dimensión de 650px X 325px',
      fileRules: [
        (value) =>
          !value || value.size < 2000000 || 'La imagen debe ser menor de 2 MB!',
      ],
      dialog: false,
      dictionary: {
        custom: {
          history_title: {
            required: () => 'El título de la historia no puede ser vacío',
            max: () => 'El título debe ser máximo de 30 caracteres',
          },
          description: {
            required: () => 'La descripción no puede ser vacia',
            max: () => 'La descripción no debe exceder de 1024 caracteres',
          },
          image: {
            required: () => 'La imagen no puede ser vacia',
            size: () => 'El tamaño de la imagen debe ser menor a 2MB',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.file = []
      this.item.name = ''
      this.item.description = ''
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          let history = {
            id: this.item.id,
            image: this.item.image,
            name: this.item.name,
            description: this.item.description,
            published: this.item.published,
          }

          this.$store.dispatch('history/updateHistory', history)
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('history/createImage', {
                id: this.item.id,
                formData: formData,
                workshop_id: this.$route.params.workshop_id,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        } else {
          let history = {
            name: this.item.name,
            description: this.item.description,
            published: this.item.published,
          }
          await this.$store.dispatch('history/createHistory', {
            id: this.$route.params.workshop_id,
            item: history,
          })
          if (this.file != '') {
            //Upload Image
            let formData = new FormData()
            formData.append('file', this.file)
            try {
              await this.$store.dispatch('history/createImage', {
                id: this.history.insertId,
                workshop_id: this.$route.params.workshop_id,
                formData: formData,
              })
            } catch (err) {
              // eslint-disable-next-line
              console.log(err)
            }
          }
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
.text-padding {
  padding-bottom: 0px;
  line-height: 1.1;
}
</style>