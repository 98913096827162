import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getInterventionPlans(id) {
    return apiClient.get('/organizations/' + id + '/intervention-plans')
  },
  getInterventionPlansPerPlayer(id, player_id) {
    return apiClient.get(
      '/organizations/' + id + '/intervention-plans' + '?playerId=' + player_id
    )
  },
  postInterventionPlan(id, interventionplan) {
    return apiClient.post(
      '/organizations/' + id + '/intervention-plans',
      interventionplan
    )
  },
  putInterventionPlan(interventionplan) {
    return apiClient.put(
      '/intervention-plans/' + interventionplan.id,
      interventionplan
    )
  },
  deleteInterventionPlan(interventionplan) {
    return apiClient.delete('/intervention-plans/' + interventionplan.id)
  },
  putCancelInterventionPlan(interventionplan) {
    return apiClient.put(
      '/intervention-plans/' + interventionplan.id + '/cancel'
    )
  },
  putAddSessionInterventionPlan(interventionplan) {
    return apiClient.put(
      '/intervention-plans/' + interventionplan.id + '/addSessions',
      interventionplan
    )
  }
}
