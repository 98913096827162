import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
  plugins: [ChartJsPluginDataLabels],
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData, this.options
    )
  },
  methods: {
    print() {
      // grab the canvas and generate an image
      let image = this.$refs.canvas.toDataURL('image/png')
      // Emits an event with the image
      this.$emit('chart:print', image)
    }
  },
  watch: {
    options() {
      this.renderChart(this.chartData, this.options);
    }
  }
}
