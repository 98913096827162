import Vue from 'vue'
import Vuex from 'vuex'
import layout from './modules/layout'
import * as auth from './modules/authentication'
import * as confirm from './modules/confirmation'
import * as organizationtype from './modules/admin/organizationtype'
import * as administrator from './modules/admin/administrator'
import * as organization from './modules/admin/organization'
import * as videogame from './modules/admin/videogame'
import * as manager from './modules/admin/manager'
import * as tutor from './modules/manager/tutor'
import * as player from './modules/manager/player'
import * as interventionplan from './modules/manager/interventionplan'
import * as ability from './modules/manager/ability'
import * as qualificationscale from './modules/admin/qualificationscale'
import * as qualificationcode from './modules/admin/qualificationcode'
import * as evaluation from './modules/manager/evaluation'
import * as session from './modules/manager/session'
import * as dailyplan from './modules/manager/dailyplan'
import * as period from './modules/manager/period'
import * as workshop from './modules/laboral-inclusion/workshop'
import * as history from './modules/laboral-inclusion/history'
import * as vocabulary from './modules/laboral-inclusion/vocabulary'
import * as sequence from './modules/laboral-inclusion/sequence'
import * as abilityReport from './modules/reports/abilityReport'
import * as abilityGraphicReport from './modules/reports/abilityGraphicReport'
import * as abilityPerGameReport from './modules/reports/abilityPerGameReport'
import * as gameReport from './modules/reports/gameReport'
import * as globalGraphicReport from './modules/reports/globalGraphicReport'
import * as gameGraphicReport from './modules/reports/gameGraphicReport'
import * as interventionPlanReport from './modules/reports/interventionPlanReport'
import * as workReport from './modules/reports/workReport'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    auth,
    confirm,
    organizationtype,
    administrator,
    organization,
    manager,
    tutor,
    player,
    interventionplan,
    ability,
    videogame,
    qualificationscale,
    qualificationcode,
    evaluation,
    session,
    dailyplan,
    period,
    workshop,
    history,
    vocabulary,
    sequence,
    abilityReport,
    abilityGraphicReport,
    abilityPerGameReport,
    gameReport,
    globalGraphicReport,
    gameGraphicReport,
    interventionPlanReport,
    workReport
  }
})
