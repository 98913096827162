var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"tabla de usuarios"}},[_c('p',[_vm._v("Lista de usuarios")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar usuario"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","headers":_vm.headers,"items":_vm.players,"search":_vm.search,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.last_name",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{style:({ color: _vm.link }),attrs:{"to":{
                name: 'userplay',
                params: {
                  id: item.organization_id,
                  player_id: item.id,
                  name: item.first_name,
                  surname: item.last_name,
                },
              }}},[_vm._v("Iniciar sesión")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }