<template>
  <div>
    <v-avatar size="32px">
      <v-img
        :src="photo ? `${this.photo}` : require('../../public/img/user.png')"
        alt="avatar del usuario"
      ></v-img>
    </v-avatar>
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          elevation="0"
          small
          icon
          v-on="on"
          aria-label="desplegar tarjeta de información del usuario"
        >
          <v-icon small>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list dense rounded dark :color="icon_color" width="210px">
        <v-list-item :color="primary">
          <v-list-item-title
            class="text-center"
            :style="{ 'font-size': '15px' }"
            >{{ this.username }}</v-list-item-title
          >
        </v-list-item>
        <v-list-item class="text-start">
          <v-list-item-action>
            <v-btn
              class="ml-5 mt-n3"
              color="#1565C0"
              elevation="3"
              small
              min-height="35"
              dark
              @click="changePassword"
            >
              <v-icon left dark>mdi-lock-open</v-icon>Cambiar <br />contraseña
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="text-center">
          <v-list-item-action>
            <v-btn
              class="ml-3"
              color="#1565C0"
              elevation="3"
              small
              dark
              :style="{ 'background-color': link }"
              @click="logout"
            >
              <v-icon left dark>mdi-login</v-icon>Cerrar sesión
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    username: String,
    photo: String,
  },
  computed: {
    ...mapState({
      icon_color: (state) => state.layout.icon_color_bar,
      primary: (state) => state.layout.primary,
      link: (state) => state.layout.link,
    }),
  },
  methods: {
    logout() {
      localStorage.removeItem('user')
      this.$router.push({ name: 'home' })
    },
    changePassword() {
      if (JSON.parse(localStorage.getItem('user')).type === 'administrator') {
        this.$router.push({
          name: 'resetPassword',
          params: { id: JSON.parse(localStorage.getItem('user')).id },
        })
      } else {
        this.$router.push({ name: 'resetPassword' })
      }
    },
  },
}
</script>