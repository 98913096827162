import VideogameService from '@/services/admin/VideogameService'

export const namespaced = true

export const state = {
  videogames: [],
  videogame: {}
}

export const mutations = {
  UPDATE_VIDEOGAME(state, videogame) {
    state.videogames = [
      ...state.videogames.filter(element => element.id !== videogame.id),
      videogame
    ]
  },
  DELETE_VIDEOGAME(state, videogame) {
    const index = state.videogames.indexOf(videogame)
    state.videogames.splice(index, 1)
  },
  SET_VIDEOGAMES(state, videogames) {
    state.videogames = videogames
  },
  SET_VIDEOGAME(state, videogame) {
    state.videogame = videogame
  }
}

export const actions = {
  createVideogame({ dispatch }, videogame) {
    return VideogameService.postVideogame(videogame)
      .then(() => {
        dispatch('fetchVideogames')
      })
      .catch(error => {
        throw error
      })
  },
  updateVideogame({ commit }, videogame) {
    return VideogameService.putVideogame(videogame)
      .then(() => {
        commit('UPDATE_VIDEOGAME', videogame)
      })
      .catch(error => {
        throw error
      })
  },
  deleteVideogame({ commit }, videogame) {
    return VideogameService.deleteVideogame(videogame)
      .then(() => {
        commit('DELETE_VIDEOGAME', videogame)
      })
      .catch(error => {
        throw error
      })
  },
  fetchVideogames({ commit }) {
    VideogameService.getVideogames()
      .then(response => {
        commit('SET_VIDEOGAMES', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
