var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('div',{staticClass:"back"},[_c('v-row',{staticClass:"ml-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{style:({ color: _vm.link }),attrs:{"to":{ name: 'userlogin' }}},[_vm._v("Lista de usuarios")])],1)],1)],1),_c('br'),_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2"},[_vm._v(" "+_vm._s(this.$route.params.name)+" "+_vm._s(this.$route.params.surname)+" ")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar videojuego"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","headers":_vm.headers,"items":_vm.interventionplan,"search":_vm.search,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.videogame_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.videogames),function(videogame){return _c('div',{key:videogame.id},[(videogame.id === item.videogame_id)?_c('div',[_vm._v(" "+_vm._s(videogame.name)+" ")]):_vm._e()])})}},{key:"item.time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.time + ' ' + 'min')+" ")]}},{key:"item.days",fn:function(ref){
      var item = ref.item;
return _vm._l((item.days),function(day,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(day.day)+" ")])})}},{key:"item.number_session",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.number_session)+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(item.number_session === 0 || item.state === 'cancelado')?_c('div',[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"disabled":"","size":"30px","color":_vm.icon_color},on:{"click":function($event){return _vm.play(item)}}},on),[_vm._v("mdi-arrow-right-drop-circle-outline")])],1):_c('div',[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"size":"30px","color":_vm.icon_color},on:{"click":function($event){return _vm.play(item)}}},on),[_vm._v("mdi-arrow-right-drop-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Jugar")])]),_c('SessionForm',{attrs:{"item":_vm.editedItem,"index":_vm.editedIndex},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }