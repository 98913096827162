<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="tabla de usuarios"
        >
          <p>Usuarios</p>
        </caption>
        <template>
          <v-btn
            :color="icon_color"
            small
            fab
            dark
            class="ml-2"
            @click="newItem"
            aria-label="Crear usuario"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-plus</v-icon>
              </template>
              <span>Crear usuario</span>
            </v-tooltip>
          </v-btn>
          <PlayerForm
            :item="editedItem"
            v-model="dialog"
            :index="editedIndex"
          />
        </template>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar usuario"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        :items="players"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.last_name="{ item }">
          {{ item.last_name }} {{ item.first_name }}</template
        >
        <template v-slot:item.first_name="{ item }"> </template>

        <template v-slot:item.state="{ item }">
          <div v-if="item.state === 1 || item.state === true">Sí</div>
          <div v-else>No</div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                :color="icon_color"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil-outline</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <v-icon small :color="icon_color" v-on="on" @click="remove(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'
import PlayerForm from '../../components/manager/PlayerForm'

export default {
  mixins: [deleteMixin],
  components: {
    PlayerForm,
  },
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
    this.$store.dispatch('player/fetchPlayers', this.$route.params.id)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
      players: (state) => state.player.players,
    }),
  },
  data() {
    return {
      search: '',
      dialog: false,
      headers: [
        {
          text: 'NOMBRE',
          align: 'left',
          value: 'last_name',
        },
        {
          text: '',
          align: '',
          value: 'first_name',
          sortable: false,
          width: 0,
        },

        {
          text: 'REPRESENTANTE',
          align: 'left',
          value: 'representative',
        },
        {
          text: 'CELULAR',
          align: 'left',
          value: 'telephone',
        },
        {
          text: 'ACTIVO',
          align: 'left',
          value: 'state',
        },
        {
          text: 'ACCIONES',
          value: 'action',
          sortable: false,
          width: '100px',
          align: 'center',
        },
      ],
      register: 'player/deletePlayer',
      editedIndex: -1,
      defaultItem: {
        id: null,
        organization_id: null,
        first_name: '',
        last_name: '',
        age: '',
        representative: '',
        telephone: '',
        diagnostic: '',
        characteristic: '',
        photo: '',
        state: false,
      },
      editedItem: {
        id: null,
        organization_id: null,
        first_name: '',
        last_name: '',
        age: '',
        representative: '',
        telephone: '',
        diagnostic: '',
        characteristic: '',
        photo: '',
        state: false,
      },
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.players.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },
    fullName(f, l) {
      return f + l
    },
    remove(item) {
      if (item.state === 1 || item.state === true) {
        this.num = 1
        this.deleteItem(
          item,
          this.register,
          item.first_name + ' ' + item.last_name,
          this.num
        )
      } else {
        this.num = 0
        this.deleteItem(
          item,
          this.register,
          item.first_name + ' ' + item.last_name,
          this.num
        )
      }
    },
  },
}
</script>

<style scoped>
</style>