import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getWorkReport(id) {
        return apiClient.get('/player/' + id + '/work-report')
    },
    async getFilteredReport(item) {
        if (item.period_id) {
            return await apiClient.get('/player/' + item.player_id + "/work-report" + "?period_id=" +
                item.period_id)
        }
        else {
            return await apiClient.get('/player/' + item.player_id + "/work-report")
        }
    }
}