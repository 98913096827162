import WorkReportService from '@/services/reports/WorkReportService'

export const namespaced = true

export const state = {
    report: {},
    filteredReport: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    },
    SET_FILTERED_REPORT(state, filteredReport) {
        state.filteredReport = filteredReport
    },

}

export const actions = {
    fetchWorkReport({ commit }, id) {
        WorkReportService.getWorkReport(id)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchWorkFilteredReport({ commit }, item) {
        WorkReportService.getFilteredReport(item)
            .then(response => {
                commit('SET_FILTERED_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}