var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"tabla de usuarios"}},[_c('p',[_vm._v("Usuarios")])]),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":_vm.icon_color,"small":"","fab":"","dark":"","aria-label":"Crear usuario"},on:{"click":_vm.newItem}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-plus")])]}}])},[_c('span',[_vm._v("Crear usuario")])])],1),_c('PlayerForm',{attrs:{"item":_vm.editedItem,"index":_vm.editedIndex},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar usuario"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","search":_vm.search,"headers":_vm.headers,"items":_vm.players,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.last_name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" "+_vm._s(item.first_name))]}},{key:"item.first_name",fn:function(ref){
      var item = ref.item;
return undefined}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [(item.state === 1 || item.state === true)?_c('div',[_vm._v("Sí")]):_c('div',[_vm._v("No")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }