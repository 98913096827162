import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})
export default {
  getManagers(id) {
    return apiClient.get('/organizations/' + id + '/managers')
  },
  postManager(id, manager) {
    return apiClient.post('/organizations/' + id + '/managers', manager)
  },
  putManager(manager) {
    return apiClient.put('/managers/' + manager.id, manager)
  },
  deleteManager(manager) {
    return apiClient.delete('/managers/' + manager.id)
  }
}
