<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="900px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  :color="icon_color"
                  v-model="item.type"
                  :items="typevideogames"
                  label="Tipo"
                  item-text="option"
                  item-value="value"
                  dense
                  v-validate="'required'"
                  data-vv-name="typevideogames"
                  :error-messages="errors.collect('typevideogames')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  class="mt-n4"
                  :color="icon_color"
                  v-model="item.name"
                  label="Nombre del videojuego"
                  v-validate="'required|max:45'"
                  data-vv-name="name"
                  :error-messages="errors.collect('name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.url"
                  label="Url del videojuego"
                  v-validate="'required|max:1024'"
                  data-vv-name="url"
                  :error-messages="errors.collect('url')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-textarea
                  class="mx-2"
                  label="Descripción"
                  rows="2"
                  :color="icon_color"
                  v-model="item.description"
                  v-validate="'required|max:1024'"
                  data-vv-name="description"
                  :error-messages="errors.collect('description')"
                  hint="obligatorio"
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-textarea
                  class="mx-2"
                  label="Grupo"
                  rows="2"
                  :color="icon_color"
                  v-model="item.note"
                  v-validate="'required|max:1024'"
                  data-vv-name="note"
                  hint="obligatorio"
                  persistent-hint
                  :error-messages="errors.collect('note')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.author"
                  label="Nombre del autor"
                  v-validate="'required|max:45|alpha_spaces'"
                  data-vv-name="author"
                  :error-messages="errors.collect('author')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  :color="icon_color"
                  label="Activar"
                  v-model="item.state"
                ></v-switch>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import typevideogames from '../../Catalogue/typevideogames'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1 ? 'Nuevo videojuego' : 'Editar videojuego'
    },
  },
  data() {
    return {
      dialog: false,
      typevideogames: typevideogames,
      dictionary: {
        custom: {
          typevideogames: {
            required: () => 'Tipo de videojuego no puede ser vacío',
          },
          name: {
            required: () => 'Nombre no puede ser vacío',
            max: () => 'El nombre debe contener máximo 45 caracteres',
          },
          url: {
            max: () => 'La url debe contener máximo 1024 caracteres',
            required: () => 'La url no puede ser vacío',
          },
          description: {
            required: () => 'Descripción no puede ser vacío',
            max: () => 'La descripción debe contener máximo 1024 caracteres',
          },
          note: {
            max: () => 'Notas debe contener máximo 1024 caracteres',
          },
          author: {
            required: () => 'El nombre no puede ser vacío',
            max: () => 'EL nombre debe contener máximo 45 caracteres',
            alpha_spaces: () => 'El nombre del autor debe contener solo letras',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.$store.dispatch('videogame/updateVideogame', this.item)
        } else {
          this.$store.dispatch('videogame/createVideogame', this.item)
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>