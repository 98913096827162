import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
    NProgress.start()
    return config
})
apiClient.interceptors.response.use(response => {
    NProgress.done(true)
    return response
})

export default {
    getGameReport(id) {
        return apiClient.get('/player/' + id + '/gameReport')
    },
    async getFilteredReport(item) {
        if (item.game_id && item.period_id) {
            return await apiClient.get('/player/' + item.player_id + "/gameReport" + "?game_id=" + item.game_id
                + "&period_id=" + item.period_id)
        }
        else {
            if (item.game_id) {
                return await apiClient.get('/player/' + item.player_id + "/gameReport" + "?game_id=" + item.game_id)
            } else {
                if (item.period_id) {
                    return await apiClient.get('/player/' + item.player_id + "/gameReport" + "?period_id=" + item.period_id)
                }
                else {
                    return await apiClient.get('/player/' + item.player_id + '/gameReport')
                }
            }
        }
    }
}