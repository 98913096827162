<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.first_name"
                  label="Nombre"
                  v-validate="'required|max:30|alpha_spaces'"
                  data-vv-name="first_name"
                  :error-messages="errors.collect('first_name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.last_name"
                  label="Apellido"
                  v-validate="'required|max:30|alpha_spaces'"
                  data-vv-name="last_name"
                  :error-messages="errors.collect('last_name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  maxlength="10"
                  :color="icon_color"
                  v-model="item.telephone"
                  label="Celular"
                  v-validate="'numeric|digits:10'"
                  data-vv-name="telephone"
                  :error-messages="errors.collect('telephone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.email"
                  label="Correo"
                  v-validate="'required|email'"
                  data-vv-name="email"
                  :error-messages="errors.collect('email')"
                  :disabled="disabled"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                xs="6"
                sm="12"
                v-if="item.email_confirmation === 1"
              >
                <p>Activar</p>
                <v-switch :color="icon_color" v-model="item.state"></v-switch>
              </v-col>
              <v-col
                cols="12"
                xs="6"
                sm="12"
                v-else-if="index != -1 && item.email_confirmation === 0"
              >
                <p>Activar</p>
                <v-switch :disabled="disabled"></v-switch>
                <p>{{ text }}</p>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
      managers: (state) => state.manager.managers,
      organizations: (state) => state.organization.organizations,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.first_name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1
        ? 'Nuevo administrador de la organización'
        : 'Editar administrador de la organización'
    },
    text() {
      return this.item.email_confirmation === 0
        ? '* El correo no está confirmado'
        : ''
    },
    organizationName() {
      return this.organizations.filter(
        (element) => element.id == this.$route.params.id
      )
    },
  },
  data() {
    return {
      dialog: false,
      dictionary: {
        custom: {
          first_name: {
            required: () => 'El nombre no puede ser vacío',
            max: () => 'El nombre debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'Nombre debe contener solo letras',
          },
          last_name: {
            required: () => 'El apellido no puede ser vacío',
            max: () => 'El apellido debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'Apellido debe contener solo letras',
          },
          email: {
            required: () => 'El correo no puede ser vacío',
            email: () => 'El correo debe ser un correo válido',
          },
          telephone: {
            digits: () => 'El celular debe contener 10 dígitos',
            numeric: () => 'El campo debe contener solo números',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    managerMail() {
      return this.managers.filter((element) => element.email == this.item.email)
    },

    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.$store.dispatch('manager/updateManager', this.item)
        } else {
          if (!this.managerMail().length) {
            this.$store.dispatch('manager/createManager', {
              id: this.$route.params.id,
              item: this.item,
            })
          } else {
            this.userExistMessage(this.item)
          }
        }
        this.$validator.reset()
        this.close()
      }
    },
    userExistMessage(item) {
      this.$swal({
        html:
          `<span style="color: #0288D1; font-family: Helvetica">${item.email}</span>` +
          `<br>` +
          `<br>` +
          `<span style="color: white; font-family: Helvetica">El e-mail ingresado ya se encuentra registrado como administrador de la organización ` +
          `${this.organizationName[0].name}<span>`,
        type: 'error',
        animation: false,
        background: '#1e1e2f',
        width: '350px',
        confirmButtonColor: '#1E87F7',
        showConfirmButton: true,
        confirmButtonText:
          "<span style='font-family: Helvetica'>Aceptar</span>",
      })
    },
  },
}
</script>
<style scoped>
</style>