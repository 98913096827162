import AbilityGraphicReportService from '@/services/reports/AbilityGraphicReportService'

export const namespaced = true

export const state = {
    report: {}
}

export const mutations = {
    SET_REPORT(state, report) {
        state.report = report
    }
}

export const actions = {
    fetchAbilityGraphicreport({ commit }, item) {
        AbilityGraphicReportService.getAbilitiesGraphicReports(item)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchAbilityGraphicFiltredreport({ commit }, item) {
        AbilityGraphicReportService.getAbilitiesGraphicFiltredReports(item)
            .then(response => {
                commit('SET_REPORT', response.data)
            })
            .catch(error => {
                throw error
            })
    }
}