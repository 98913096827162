import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})
export default {
  getPlayers(id) {
    return apiClient.get('/organizations/' + id + '/players')
  },
  postPlayer(id, player) {
    return apiClient.post('/organizations/' + id + '/players', player)
  },
  putPlayer(player) {
    return apiClient.put('/players/' + player.id, player)
  },
  deletePlayer(player) {
    return apiClient.delete('/players/' + player.id)
  }
}
