import AdministratorService from '@/services/admin/AdministratorService'

export const namespaced = true

export const state = {
  administrators: [],
  administrator: {}
}

export const mutations = {
  UPDATE_ADMINISTRATOR(state, administrator) {
    state.administrators = [
      ...state.administrators.filter(
        element => element.id !== administrator.id
      ),
      administrator
    ]
  },
  DELETE_ADMINISTRATOR(state, administrator) {
    const index = state.administrators.indexOf(administrator)
    state.administrators.splice(index, 1)
  },
  SET_ADMINISTRATORS(state, administrators) {
    state.administrators = administrators
  },
  SET_ADMINISTRATOR(state, administrator) {
    state.administrator = administrator
  }
}

export const actions = {
  createAdministrator({ dispatch }, administrator) {
    return AdministratorService.postAdministrator(administrator)
      .then(() => {
        dispatch('fetchAdministrators')
      })
      .catch(error => {
        throw error
      })
  },
  updateAdministrator({ commit }, administrator) {
    return AdministratorService.putAdministrator(administrator)
      .then(() => {
        commit('UPDATE_ADMINISTRATOR', administrator)
      })
      .catch(error => {
        throw error
      })
  },
  deleteAdministrator({ commit }, administrator) {
    return AdministratorService.deleteAdministrator(administrator)
      .then(() => {
        commit('DELETE_ADMINISTRATOR', administrator)
      })
      .catch(error => {
        throw error
      })
  },
  fetchAdministrators({ commit }) {
    AdministratorService.getAdministrators()
      .then(response => {
        commit('SET_ADMINISTRATORS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
