import ManagerService from '@/services/admin/ManagerService'

export const namespaced = true

export const state = {
  managers: [],
  manager: {}
}
export const mutations = {
  UPDATE_MANAGER(state, manager) {
    state.managers = [
      ...state.managers.filter(element => element.id !== manager.id),
      manager
    ]
  },
  DELETE_MANAGER(state, manager) {
    const index = state.managers.indexOf(manager)
    state.managers.splice(index, 1)
  },
  SET_MANAGERS(state, managers) {
    state.managers = managers
  },
  SET_MANAGER(state, manager) {
    state.manager = manager
  }
}
export const actions = {
  createManager({ dispatch }, manager) {
    return ManagerService.postManager(manager.id, manager.item)
      .then(() => {
        dispatch('fetchManagers', manager.id)
      })
      .catch(error => {
        throw error
      })
  },
  updateManager({ commit }, manager) {
    return ManagerService.putManager(manager)
      .then(() => {
        commit('UPDATE_MANAGER', manager)
      })
      .catch(error => {
        throw error
      })
  },
  deleteManager({ commit }, manager) {
    return ManagerService.deleteManager(manager)
      .then(() => {
        commit('DELETE_MANAGER', manager)
      })
      .catch(error => {
        throw error
      })
  },
  fetchManagers({ commit }, id) {
    ManagerService.getManagers(id)
      .then(response => {
        commit('SET_MANAGERS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
