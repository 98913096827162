import apiClient from '../configServices'

export default {
  getLamines(id) {
    return apiClient.get('/histories/' + id + '/lamines')
  },
  getAllLamines() {
    return apiClient.get('/lamines')
  },
  postLamines(id, lamine) {
    return apiClient.post('/histories/' + id + '/lamines', lamine)
  },
  postImage(id, formData) {
    return apiClient.post('/lamines/uploads/' + id, formData)
  },
  putLamines(lamine) {
    return apiClient.put('/lamines/' + lamine.id, lamine)
  },
  deleteLamines(lamine) {
    return apiClient.delete('/lamines/' + lamine.id)
  }
}