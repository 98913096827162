const state = {
  layout: 'base-layout',
  color: '#0B5683',
  link: '#FBC02D',
  icon_color: '#FBC02D',
  icon_color_bar: '#1976D2',
  tooltip_color: '#0277BD',
  primary: '#F9E79F',
  purple: "#3b007e"
}

const mutations = {
  SET_LAYOUT(state, payload) {
    state.layout = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
