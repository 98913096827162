import SequenceService from '@/services/laboral-inclusion/SequenceService'

export const namespaced = true

export const state = {
    Sequence: [],
    lamine: {}
}
export const mutations = {
    SET_SEQUENCE(state, Sequence) {
        state.Sequence = Sequence
    },
    SET_LAMINE(state, lamine) {
        state.lamine = lamine
    },
    UPDATE_SEQUENCE(state, lamine) {
        state.Sequence = [
            ...state.Sequence.filter(
                element => element.id !== lamine.id
            ),
            lamine
        ]
    }
    ,
    DELETE_LAMINE(state, lamine) {
        const index = state.Sequence.indexOf(lamine)
        state.Sequence.splice(index, 1)
    }
}
export const actions = {
    createLamines({ dispatch, commit }, lamine) {
        return SequenceService.postLamines(lamine.id, lamine.item)
            .then(response => {
                dispatch('fetchLamines', lamine.id)
                commit('SET_LAMINE', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    createImage({ dispatch, commit }, image) {
        return SequenceService.postImage(image.id, image.formData).then(
            response => {
                dispatch('fetchLamines', image.history_id)
                //eslint-disable-next-line
                // console.log(response.data)
                commit('UPDATE_SEQUENCE', response.data[0])
            }
        )
    },
    fetchLamines({ commit }, id) {
        SequenceService.getLamines(id)
            .then(response => {
                commit('SET_SEQUENCE', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    fetchAllLamines({ commit }) {
        SequenceService.getAllLamines()
            .then(response => {
                commit('SET_SEQUENCE', response.data)
            })
            .catch(error => {
                throw error
            })
    },
    updateLamines({ commit }, lamine) {
        return SequenceService.putLamines(lamine)
            .then(() => {
                commit('UPDATE_SEQUENCE', lamine)
            })
            .catch(error => {
                throw error
            })
    },
    deleteLamines({ commit }, lamine) {
        return SequenceService.deleteLamines(lamine)
            .then(() => {
                commit('DELETE_LAMINE', lamine)
            })
            .catch(error => {
                throw error
            })
    }
}