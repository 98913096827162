<template>
  <div class="pa-10">
    <div class="back">
      <v-row no-gutters class="ml-4">
        <v-col cols="12">
          <router-link
            class="mr-2"
            :to="{ name: 'reports' }"
            :style="{'color': link} "
          >Reportes de usuarios</router-link>
        </v-col>
      </v-row>
    </div>
    <br />
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <p
          class="font-weight-light title pt-1"
          aria-label="tabla métricas"
        >Reportes de {{this.$route.params.name}} {{this.$route.params.surname}}</p>
        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar plan de intervención"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color}"
        dark
        :search="search"
        :headers="headers"
        :items="interventionplan"
        v-model="selected"
        :items-per-page="5"
        :footer-props="{'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5,10, -1],
        'show-current-page': true,
        'pagination': {}
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.videogame_id="{item}">
          <div v-for="videogame in videogames" :key="videogame.id">
            <div v-if="videogame.id === item.videogame_id">{{videogame.name}}</div>
          </div>
        </template>
        <template v-slot:item.videogame_id="{item}">
          <div v-for="videogame in videogames" :key="videogame.id">
            <div v-if="videogame.id === item.videogame_id">{{videogame.name}}</div>
          </div>
        </template>
        <template v-slot:item.session="{item}">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <router-link
                aria-label="Enlace que redirige a la página de reporte de sesiones del usuario"
                :to="{name: 'session-reports', params:{id_iplan:item.id, game_name: item.videogame_id,  ability_id: item.ability_id}}"
              >
                <v-icon class="mr-5" v-on="on" :color="icon_color">mdi-file-document</v-icon>
              </router-link>
            </template>
            <span>Tabla de sesiones</span>
          </v-tooltip>

          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <router-link
                aria-label="Enlace que redirige a la página de reporte gráfico de sesiones del usuario"
                :to="{name: 'session-graphic', params:{  id_iplan:item.id, game_name: item.videogame_id,    ability_id: item.ability_id}}"
              >
                <v-icon class="mr-5" v-on="on" :color="icon_color">mdi-poll-box</v-icon>
              </router-link>
            </template>
            <span>Gráfico de sesiones</span>
          </v-tooltip>
        </template>
        <template v-slot:item.id="{item}">
          <v-tooltip bottom :color="tooltip_color">
            <template v-slot:activator="{ on }">
              <router-link
                aria-label="Enlace que redirige a la página de reporte de evaluación del usuario"
                :to="{name: 'printeable-reports', params:
            {
              id_iplan:item.id,
              game_name: item.videogame_id,
              ability_id: item.ability_id
            }
            }"
                :style="{'color': icon_color} "
              >
                <v-icon class="mr-5" v-on="on" :color="icon_color">mdi-file-document</v-icon>
              </router-link>
            </template>
            <span>Tabla de evaluaciones</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import deleteMixin from '../../mixins/deletemixin'

export default {
  mixins: [deleteMixin],

  created() {
    this.$store.commit('layout/SET_LAYOUT', 'tutor-layout')
    this.$store.dispatch('interventionplan/fetchInterventionPlansPerPlayer', {
      id: this.$route.params.id,
      player_id: this.$route.params.player_id
    })
    this.$store.dispatch('videogame/fetchVideogames')
  },
  computed: {
    ...mapState({
      color: state => state.layout.color,
      link: state => state.layout.link,
      icon_color: state => state.layout.icon_color,
      tooltip_color: state => state.layout.tooltip_color,
      videogames: state => state.videogame.videogames,
      interventionplan: state => state.interventionplan.intervention_plans
    })
  },
  data() {
    return {
      search: '',
      selected: [],
      dialog: false,
      disabled: false,
      headname: this.$route.params.surname,
      headers: [
        {
          text: 'CÓDIGO PLAN DE INTERVENCIÓN',
          align: 'left',
          value: 'code'
        },
        { text: 'VIDEOJUEGO', value: 'videogame_id' },
        { text: 'REPORTE DE SESIÓN', value: 'session' },
        {
          text: 'REPORTE DE EVALUACIÓN',
          value: 'id',
          align: 'right',
          sortable: false
        }
      ]
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.evaluationCode.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.disabled = true
    },
    newItem() {
      this.$validator.reset()
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = true
      this.disabled = false
    },
    remove(item) {
      if (item.state === 1 || item.state === true) {
        this.num = 1
        this.deleteItem(item, this.register, item.value, this.num)
      } else {
        this.num = 0
        this.deleteItem(item, this.register, item.value, this.num)
      }
    }
  }
}
</script>

<style scoped>
</style>