<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  :color="icon_color"
                  v-model="item.name"
                  label="Nombre"
                  v-validate="'required|max:50'"
                  data-vv-name="period_name"
                  :error-messages="errors.collect('period_name')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  class="mt-5"
                  v-validate="'required'"
                  data-vv-name="year"
                  v-model="item.year"
                  :items="years"
                  label="Año"
                  item-text="option"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  :error-messages="errors.collect('year')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  class="mt-5"
                  v-validate="'required'"
                  data-vv-name="period"
                  v-model="item.period"
                  :items="numberList"
                  label="Periodos"
                  item-text="option"
                  item-value="value"
                  no-data-text="No hay datos ingresados"
                  dense
                  :error-messages="errors.collect('period')"
                  hint="obligatorio"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import numberList from '../../Catalogue/numberList.js'
import years from '../../Catalogue/years'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.name[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      this.checkDefault()
      return this.index === -1 ? 'Nuevo periodo' : 'Editar periodo'
    },
    text() {
      return this.item.email_confirmation === 0
        ? '* El correo no está confirmado'
        : ''
    },
  },
  data() {
    return {
      dialog: false,
      years: years,
      numberList: numberList,
      dictionary: {
        custom: {
          period_name: {
            required: () => 'El nombre no puede ser vacío',
            max: () => 'El nombre debe ser máximo de 50 caracteres',
          },
          year: {
            required: () => 'El año no puede ser vacío',
          },
          period: {
            required: () => 'El periodo no puede ser vacío',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    checkDefault() {
      if (this.item.year == '') {
        var yearString = new Date().toISOString().substr(0, 4)
        this.item.year = parseInt(yearString)
      }
    },
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.$store.dispatch('period/updatePeriod', this.item)
        } else {
          this.$store.dispatch('period/createPeriod', {
            id: this.$route.params.id,
            item: this.item,
          })
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>