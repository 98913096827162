import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getQualification_Code(id) {
    return apiClient.get('/qualification-scales/' + id + '/qualification-codes')
  },
  postQualification_Code(id, evaluationCode) {
    return apiClient.post(
      '/qualification-scales/' + id + '/qualification-codes',
      evaluationCode
    )
  },
  putQualification_Code(evaluationCode) {
    return apiClient.put(
      '/qualification-codes/' + evaluationCode.id,
      evaluationCode
    )
  },
  deleteQualification_Code(evaluationCode) {
    return apiClient.delete('/qualification-codes/' + evaluationCode.id)
  }
}
