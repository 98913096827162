<template>
  <v-list dense>
    <v-list-item two-line>
      <v-list-item-avatar size="32" color="#1E1E2F">
        <v-img
          :src="
            logo
              ? `${this.logo}`
              : require('../../public/img/loginAssets/hummingbird.png')
          "
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title style="font-size: 16px">{{
          title
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    title: String,
    logo: String,
  },
}
</script>