var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"Perzonalización de juego"}},[_c('p',[_vm._v("Personalizar Juegos")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar juego"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados"},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de parametrizacion de juego","to":{
            name: 'workshop',
          }}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-5",attrs:{"color":_vm.icon_color}},on),[_vm._v("mdi-tools")])]}}])},[_c('span',[_vm._v("Personalizar")])])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }