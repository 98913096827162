<template>
  <div class="pt-4">
    <h1 class="title font-weight-light pl-2" style="color: #3b007e"></h1>
    <div class="white--text pt-12">
      <v-card
        class="mx-auto"
        :color="color"
        max-width="475"
        elevation="24"
        @submit.prevent
      >
        <v-card-title
          class="ml-12 align-center fill-height display-1 font-weight-bold"
          :style="{ color: 'white' }"
          >Cambio de contraseña</v-card-title
        >
        <v-divider dark class="mx-4"></v-divider>
        <v-card-actions class="pt-10">
          <v-text-field
            maxlength="16"
            v-validate="{
              required: true,
              min: 8,
              max: 16,
              regex: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
            }"
            v-model="credentials.password"
            @click:append="showpass = !showpass"
            :append-icon="showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showpass ? 'text' : 'password'"
            label="Contraseña"
            ref="password"
            data-vv-name="password"
            :error-messages="errors.collect('password')"
            outlined
            filled
            dense
            dark
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            maxlength="16"
            v-validate="'required|confirmed:password'"
            v-model="confirmpassword"
            :append-icon="showpass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            label="Confirmar contraseña"
            data-vv-name="confirmation"
            :error-messages="errors.collect('confirmation')"
            outlined
            filled
            dense
            dark
            :color="icon_color"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-btn block :color="icon_color" dark @click="submit">Enviar</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-card-text :style="{ color: link }" class="text-center"
            >Por favor ingresa tu nueva contraseña</v-card-text
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  created() {
    var layout_name
    if (JSON.parse(localStorage.getItem('user')).type == 'manager') {
      layout_name = 'organization'
    } else {
      layout_name = JSON.parse(localStorage.getItem('user')).type
    }
    var layout = layout_name + '-layout'
    this.$store.commit('layout/SET_LAYOUT', layout)
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
  },
  data() {
    return {
      credentials: {
        id: JSON.parse(localStorage.getItem('user')).id,
        password: '',
        typeUser: JSON.parse(localStorage.getItem('user')).type,
      },
      confirmpassword: '',
      showpass: false,
      dictionary: {
        custom: {
          password: {
            required: () => 'Contraseña no puede ser vacío',
            min: () => 'La contraseña debe tener mínimo 8 caracteres',
            max: () => 'La contraseña debe tener máximo 16 caracteres',
            regex: () =>
              'Contraseña debe tener almenos una letra mayúscula, una minúscula y un número.',
          },
          confirmation: {
            required: () => 'Confirmación contraseña no puede ser vacío',
            confirmed: () => 'Las contraseñas no coinciden',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        this.$store.dispatch('confirm/confirm', this.credentials).then(() => {
          this.$swal({
            html:
              '<p style="color: white; font-family: Helvetica">Contraseña confirmada</p>' +
              '<br>',
            type: 'success',
            animation: false,
            borderImageSlice: '100',
            background: '#1e1e2f',
            width: '350px',
            confirmButtonColor: '#1E87F7',
            showConfirmButton: true,
            confirmButtonText:
              "<span style='font-family: Helvetica' href='/'>Aceptar</span>",
          }).then(function () {
            window.history.back()
          })
        })
      }
    },
  },
}
</script>

<style scoped>
#login-card {
  border: 20px solid transparent;
  border-image-width: 50px;
  padding: 5px;
  border-image-source: url('/img/loginAssets/border.png');
  border-image-slice: 100;
  border-radius: 200;
}
</style>