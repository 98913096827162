<template>
  <v-app id="administrator" :style="background">
    <v-navigation-drawer
      v-model="drawer"
      :color="icon_color"
      dark
      width="255px"
      app
      clipped
    >
      <template v-slot:prepend>
        <Menuheader :title="titleMenu" />
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-group prepend-icon="mdi-domain" value="true" :color="primary">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Organización</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(subOption, index) in subOptions"
              :key="index"
              :to="subOption.path"
              @click.stop
            >
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ subOption.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-group>

        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          :to="option.path"
          @click.stop
        >
          <v-list-item-action>
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark :color="color" class="pl-7 pr-7">
      <v-app-bar-nav-icon
        elevation="0"
        @click.stop="drawer = !drawer"
        aria-label="botón del menu izquierdo del administrador de la plataforma"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <h1 class="title">Administrador de la Plataforma</h1>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <Logoutcard :username="fullName" :photo="photo" />
    </v-app-bar>

    <v-main app>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Menuheader from '../../components/MenuHeader'
import Logoutcard from '../../components/LogoutCard'
import Footer from '../../components/Footer'

export default {
  components: {
    Menuheader,
    Logoutcard,
    Footer,
  },
  created() {
    this.admin = JSON.parse(localStorage.getItem('user'))
  },
  data() {
    return {
      admin: {},
      drawer: null,
      titleMenu: 'Ludominga',
      photo: '',
      options: [
        {
          title: 'Videojuegos',
          icon: 'mdi-controller-classic-outline',
          path: { name: 'videogames' },
        },
        {
          title: 'Administradores',
          icon: 'mdi-account',
          path: { name: 'administrators' },
        },
        {
          title: 'Métricas de evaluación',
          icon: 'mdi-file-document',
          path: { name: 'qualification-scale' },
        },
      ],
      subOptions: [
        {
          title: 'Tipos de organizaciones',
          path: { name: 'organizationstypes' },
        },
        {
          title: 'Organizaciones',
          path: { name: 'organizations' },
        },
      ],
    }
  },
  computed: {
    background() {
      return {
        background: '#ABB2B9',
      }
    },

    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color_bar,
      primary: (state) => state.layout.primary,
      userlogued: (state) => state.auth.user,
    }),
    fullName() {
      return this.admin.full_name
    },
  },
}
</script>