import ConfirmService from '../../services/ConfirmService'

export const namespaced = true

export const state = {
  credentials: {}
}

export const mutations = {
  SET_CREDENTIALS(state, credentials) {
    state.credentials = credentials
  }
}

export const actions = {
  confirm({ commit }, credentials) {
    return ConfirmService.postUser(credentials)
      .then(response => {
        // eslint-disable-next-line
        console.log(response.data)
        commit('SET_CREDENTIALS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
