import TutorService from '@/services/manager/TutorService'

export const namespaced = true

export const state = {
  tutors: [],
  tutor: {}
}
export const mutations = {
  UPDATE_TUTOR(state, tutor) {
    state.tutors = [
      ...state.tutors.filter(element => element.id !== tutor.id),
      tutor
    ]
  },
  DELETE_TUTOR(state, tutor) {
    const index = state.tutors.indexOf(tutor)
    state.tutors.splice(index, 1)
  },
  SET_TUTORS(state, tutors) {
    state.tutors = tutors
  },
  SET_TUTOR(state, tutor) {
    state.tutor = tutor
  }
}
export const actions = {
  createTutor({ dispatch }, tutor) {
    return TutorService.postTutor(tutor.id, tutor.item)
      .then(() => {
        dispatch('fetchTutors', tutor.id)
      })
      .catch(error => {
        throw error
      })
  },
  updateTutor({ commit }, tutor) {
    return TutorService.putTutor(tutor)
      .then(() => {
        commit('UPDATE_TUTOR', tutor)
      })
      .catch(error => {
        throw error
      })
  },
  deleteTutor({ commit }, tutor) {
    return TutorService.deleteTutor(tutor)
      .then(() => {
        commit('DELETE_TUTOR', tutor)
      })
      .catch(error => {
        throw error
      })
  },
  fetchTutors({ commit }, id) {
    TutorService.getTutors(id)
      .then(response => {
        commit('SET_TUTORS', response.data)
      })
      .catch(error => {
        throw error
      })
  }
}
