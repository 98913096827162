var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10"},[_c('v-card',{attrs:{"color":_vm.color,"dark":"","elevation":"24"}},[_c('v-card-title',[_c('caption',{staticClass:"font-weight-light title pt-2",attrs:{"aria-label":"tabla de planes de intervención"}},[_c('p',[_vm._v("Plan de intervención")])]),[_c('v-btn',{staticClass:"ml-2",attrs:{"color":_vm.icon_color,"small":"","fab":"","dark":"","aria-label":"Crear plan de intervención"},on:{"click":_vm.newItem}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-plus")])]}}])},[_c('span',[_vm._v("Crear plan de intervención")])])],1),_c('InterventionPlanForm',{attrs:{"day":_vm.day,"item":_vm.editedItem,"index":_vm.editedIndex},on:{"update:day":function($event){_vm.day = $event}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar plan"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{style:({ 'background-color': _vm.color }),attrs:{"dark":"","search":_vm.search,"headers":_vm.headers,"items":_vm.interventionplans,"items-per-page":5,"footer-props":{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [5, 10, -1],
        'show-current-page': true,
        pagination: {},
      },"no-results-text":"No se encuentran resultados","no-data-text":"No hay datos ingresados","item-key":""},scopedSlots:_vm._u([{key:"item.player_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.players),function(player){return _c('div',{key:player.id},[(player.id === item.player_id)?_c('div',[_vm._v(" "+_vm._s(player.first_name)+" "+_vm._s(player.last_name)+" ")]):_vm._e()])})}},{key:"item.videogame_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.videogames),function(videogame){return _c('div',{key:videogame.id},[(videogame.id === item.videogame_id)?_c('div',[_vm._v(" "+_vm._s(videogame.name)+" ")]):_vm._e()])})}},{key:"item.start_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.start_date.slice(0, 10)))]}},{key:"item.period_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.periods),function(period){return _c('div',{key:period.id},[(period.id === item.period_id)?_c('div',[_vm._v(_vm._s(period.name))]):_vm._e()])})}},{key:"item.ability_id",fn:function(ref){
      var item = ref.item;
return _vm._l((_vm.abilities),function(ability){return _c('div',{key:ability.id},[(ability.id === item.ability_id)?_c('div',[_vm._v(_vm._s(ability.name))]):_vm._e()])})}},{key:"item.days",fn:function(ref){
      var item = ref.item;
return _vm._l((item.days),function(day,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(day.day)+" ")])})}},{key:"item.evaluate",fn:function(ref){
      var item = ref.item;
return [(item.state === 'cancelado')?_c('div',[_c('v-btn',{staticClass:"mr-5",attrs:{"disabled":"","icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón evaluar deshabilitado"},on:{"click":function($event){return _vm.evaluate(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Evaluar")])])],1),_c('v-btn',{staticClass:"mr-5",attrs:{"disabled":"","icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón suspender deshabilitado"},on:{"click":function($event){return _vm.stop(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-progress-close")])]}}],null,true)},[_c('span',[_vm._v("Suspender")])])],1),_c('v-btn',{staticClass:"mr-5",attrs:{"disabled":"","icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón aumentar sesiones deshabilitado"},on:{"click":function($event){return _vm.increase(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-progress-upload")])]}}],null,true)},[_c('span',[_vm._v("Aumentar sesiones")])])],1)],1):_c('div',[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón evaluar habilitado"},on:{"click":function($event){return _vm.evaluate(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document")])]}}],null,true)},[_c('span',[_vm._v("Evaluar")])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón suspender habilitado"},on:{"click":function($event){return _vm.stop(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-progress-close")])]}}],null,true)},[_c('span',[_vm._v("Suspender")])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":_vm.icon_color,"small":"","aria-label":"Botón aumentar sesiones habilitado"},on:{"click":function($event){return _vm.increase(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-progress-upload")])]}}],null,true)},[_c('span',[_vm._v("Aumentar sesiones")])])],1),(_vm.typeuser.type === 'manager')?_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reporte de evaluaciones de plan de intervención","to":{
                  name: 'printeable-reports',
                  params: {
                    player_id: item.player_id,
                    id_iplan: item.id,
                    game_name: item.videogame_id,
                    ability_id: item.ability_id,
                  },
                }}},[_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.link}},on),[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Reporte de evaluaciones")])]):_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('router-link',{attrs:{"aria-label":"Enlace que redirige a la página de reporte de evaluaciones de plan de intervención","to":{
                  name: 'printeable-reports-tutor',
                  params: {
                    player_id: item.player_id,
                    id_iplan: item.id,
                    game_name: item.videogame_id,
                    ability_id: item.ability_id,
                  },
                }}},[_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.link}},on),[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Reporte de evaluaciones")])]),_c('EvaluationForm',{attrs:{"item":_vm.editedItem,"index":_vm.editedIndex,"action":_vm.action},model:{value:(_vm.dialog_two),callback:function ($$v) {_vm.dialog_two=$$v},expression:"dialog_two"}})],1)]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(item.state === 'cancelado')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"disabled":"","small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":"","small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.remove(item)}},nativeOn:{"click":function($event){_vm.dialog3 = true}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.tooltip_color},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.icon_color},on:{"click":function($event){return _vm.remove(item)}},nativeOn:{"click":function($event){_vm.dialog3 = true}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]),_c('v-dialog',{attrs:{"width":"350","retain-focus":false,"dark":""},model:{value:(_vm.dialog3),callback:function ($$v) {_vm.dialog3=$$v},expression:"dialog3"}},[_c('v-card',{attrs:{"color":_vm.color,"dark":""}},[_c('center',{staticClass:"pt-5"},[_c('v-img',{attrs:{"src":"/img/info.png","width":"100"}})],1),_c('v-card-text',{staticClass:"text-center pt-2 body-1",staticStyle:{"font-family":"Helvetica"}},[_vm._v(_vm._s(_vm.error))]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"text-center text-capitalize body-1",attrs:{"width":"100","color":_vm.icon_color,"depressed":""},on:{"click":function($event){_vm.dialog3 = false}}},[_vm._v("Entendido")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }