import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getAdministrators() {
    return apiClient.get('/administrators')
  },

  getAdministrator(id) {
    return apiClient.get('/administrators/' + id)
  },

  postAdministrator(administrator) {
    return apiClient.post('/administrators', administrator)
  },

  putAdministrator(administrator) {
    return apiClient.put('/administrators/' + administrator.id, administrator)
  },

  deleteAdministrator(administrator) {
    return apiClient.delete('/administrators/' + administrator.id)
  }
}
