import apiClient from '../configServices'
import NProgress from 'nprogress'

apiClient.interceptors.request.use(config => {
  NProgress.start()
  NProgress.set(1)
  return config
})
apiClient.interceptors.response.use(response => {
  NProgress.done(true)
  return response
})

export default {
  getWorkshops(id) {
    return apiClient.get('/organizations/' + id + '/workshops')
  },
  postWorkshop(id, workshop) {
    return apiClient.post('/organizations/' + id + '/workshops', workshop)
  },
  postImage(id, formData) {
    return apiClient.post('/workshops/uploads/' + id, formData)
  },
  putworkshop(workshop) {
    return apiClient.put('/workshops/' + workshop.id, workshop)
  },
  deleteWorkshop(workshop) {
    return apiClient.delete('/workshops/' + workshop.id)
  }
}