<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="800px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-container>
          <form action>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.value"
                  label="Valor"
                  v-validate="'required|max:30'"
                  data-vv-name="value"
                  :error-messages="errors.collect('value')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :color="icon_color"
                  v-model="item.description"
                  label="Descripción"
                  v-validate="'required|max:50'"
                  data-vv-name="description"
                  :error-messages="errors.collect('description')"
                  hint="obligatorio"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn :color="link" text @click="save">Guardar</v-btn>
        <v-btn :color="link" text @click="close">Cancelar</v-btn>
        <p style="visibility: hidden; font-size: 0.01px">
          {{ reloadValidador }}
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    index: Number,
    item: Object,
    value: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      icon_color: (state) => state.layout.icon_color,
      link: (state) => state.layout.link,
    }),
    reloadValidador() {
      if (this.index === -1) this.$validator.reset()
      return this.item.value[0]
    },
    formTitle() {
      if (this.index === -1) this.$validator.reset()
      return this.index === -1 ? 'Nuevo valor' : 'Editar valor'
    },
  },
  data() {
    return {
      dialog: false,
      dictionary: {
        custom: {
          value: {
            required: () => 'El valor  no puede ser vacío',
            max: () => 'El valor debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'valor  debe contener solo letras',
          },
          description: {
            required: () => 'La descripción no puede ser vacío',
            max: () => 'La descripción debe ser máximo de 30 caracteres',
            alpha_spaces: () => 'La descripción debe contener solo letras',
          },
        },
      },
    }
  },
  mounted() {
    this.$validator.localize('es', this.dictionary)
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.$validator.reset()
      this.$emit('input', false)
    },
    async save() {
      let isValidForm = await this.$validator.validateAll()
      if (isValidForm) {
        if (this.index > -1) {
          this.$store.dispatch(
            'qualificationcode/updateQualification_Code',
            this.item
          )
        } else {
          this.$store.dispatch('qualificationcode/createQualification_Code', {
            id: this.$route.params.id,
            item: this.item,
          })
        }
        this.$validator.reset()
        this.close()
      }
    },
  },
}
</script>
<style scoped>
</style>