<template>
  <div class="pa-10">
    <v-card :color="color" dark elevation="24">
      <v-card-title>
        <caption
          class="font-weight-light title pt-2"
          aria-label="Perzonalización de juego"
        >
          <p>Personalizar Juegos</p>
        </caption>

        <div class="flex-grow-1"></div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar juego"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :style="{ 'background-color': color }"
        dark
        :search="search"
        :headers="headers"
        :items="items"
        :items-per-page="5"
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Registros por página',
          'items-per-page-options': [5, 10, -1],
          'show-current-page': true,
          pagination: {},
        }"
        no-results-text="No se encuentran resultados"
        no-data-text="No hay datos ingresados"
      >
        <template v-slot:item.id>
          <router-link
            aria-label="Enlace que redirige a la página de parametrizacion de juego"
            :to="{
              name: 'workshop',
            }"
          >
            <v-tooltip bottom :color="tooltip_color">
              <template v-slot:activator="{ on }">
                <v-icon class="mr-5" :color="icon_color" v-on="on"
                  >mdi-tools</v-icon
                >
              </template>
              <span>Personalizar</span>
            </v-tooltip>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  created() {
    this.$store.commit('layout/SET_LAYOUT', 'organization-layout')
  },
  computed: {
    ...mapState({
      color: (state) => state.layout.color,
      link: (state) => state.layout.link,
      icon_color: (state) => state.layout.icon_color,
      tooltip_color: (state) => state.layout.tooltip_color,
    }),
  },
  data() {
    return {
      items: [{ name: 'LudoEmpleo', id: '' }],
      search: '',
      dialog: false,
      headers: [
        {
          text: 'JUEGO',
          align: 'left',
          value: 'name',
        },
        {
          text: 'PERSONALIZAR',
          value: 'id',
          sortable: false,
          width: '250px',
          align: 'left',
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>