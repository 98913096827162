import Vue from 'vue'
import Router from 'vue-router'

//General Views
import Home from '../src/views/general/Home'
import Login from '../src/views/general/Login'
import ConfirmPassword from '../src/views/general/ConfirmPassword'
import ListVideogame from '../src/views/general/ListVideogame'
import ForgetPassword from '../src/views/general/ForgetPassword'
import ResetPassword from '../src/views/general/ResetPassword'

//Administrator views
import Organizations from '../src/views/admin/Organizations'
import OrganizationTypes from '../src/views/admin/OrganizationTypes'
import Videogames from '../src/views/admin/Videogames'
import Administrators from '../src/views/admin/Administrators'
import QualificationScales from '../src/views/admin/QualificationScales'
import Managers from '../src/views/admin/Managers'
import QualificationCode from '../src/views/admin/QualificationCode'

//Organizations views
import Tutors from '../src/views/manager/Tutors'
import Players from '../src/views/manager/Players'
import InterventionPlans from '../src/views/manager/InterventionPlans'
import Abilities from '../src/views/manager/Abilities'
import DailyPlans from '../src/views/manager/DailyPlans'
import GameCustomizer from '../src/views/manager/GameCustomizer'
import Periods from '../src/views/manager/Periods'
import GlobalReport from '../src/views/manager/GlobalReport'

//Laboral Inclucion views
import LaboralInclusion from '../src/views/laboral-inclusion/LaboralInclusion'
import Histories from '../src/views/laboral-inclusion/Histories'
import Vocabulary from '../src/views/laboral-inclusion/Vocabulary'
import SequenceOrder from '../src/views/laboral-inclusion/SequenceOrder'

//tutors views
import LoginUser from '../src/views/tutor/LoginUser'
import PlayUser from '../src/views/tutor/PlayUser'
// import InterventionPlanTutor from '../src/views/tutor/InterventionPlanTutor'
import Reports from '../src/views/tutor/Reports'
import PlayerReports from '../src/views/tutor/PlayerReports'
import PrinteableReport from '../src/views/tutor/PrinteableReport'
import SessionReport from '../src/views/tutor/SessionReport'
import SessionGraphicReport from '../src/views/tutor/SessionGraphicReport'
import AbilityReport from '../src/views/tutor/AbilityReport'
import AbilityGraphicReport from '../src/views/tutor/AbilityGraphicReport'
import GameReport from '../src/views/tutor/GameReport'
import GameGraphicReport from '../src/views/tutor/GameGraphicReport'
import AbilityPerGameReport from '../src/views/tutor/AbilityPerGameReport'
import InterventionPlanReport from '../src/views/tutor/InterventionPlanReport'
import WorkReport from '../src/views/tutor/WorkReport'


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //Routes for general views
    {
      path: '/',
      name: 'home',
      components: { default: Home }
    },
    {
      path: '/users/auth',
      name: 'login',
      components: { default: Login }
    },
    {
      path: '/users/confirm/',
      name: 'confirmpassword',
      components: { default: ConfirmPassword }
    },
    {
      path: '/users/:id/reset-password/',
      name: 'resetPassword',
      components: { default: ResetPassword }
    },
    {
      path: '/users/forget-password',
      name: 'forgetpassword',
      components: { default: ForgetPassword }
    },
    {
      path: '/videogameslist',
      name: 'videogameslist',
      components: { default: ListVideogame }
    },
    //Routes for globaladministrator views
    {
      path: '/organizations',
      name: 'organizations',
      components: { default: Organizations }
    },
    {
      path: '/organizationtypes',
      name: 'organizationstypes',
      components: { default: OrganizationTypes }
    },
    {
      path: '/videogames',
      name: 'videogames',
      components: { default: Videogames }
    },
    {
      path: '/administrators',
      name: 'administrators',
      components: { default: Administrators }
    },
    {
      path: '/qualification-scale',
      name: 'qualification-scale',
      components: { default: QualificationScales }
    },
    {
      path: '/evaluations/:id/qualification-code',
      name: 'qualification-code',
      components: { default: QualificationCode }
    },
    {
      path: '/organizations/:id/managers',
      name: 'managers',
      components: { default: Managers }
    },
    //Routes for organizationadministrator views
    {
      path: '/organizations/:id/tutors',
      name: 'tutors',
      components: { default: Tutors }
    },
    {
      path: '/organizations/:id/players',
      name: 'players',
      components: { default: Players }
    },
    {
      path: '/organizations/:id/intervention-plans',
      name: 'interventionplans',
      components: { default: InterventionPlans }
    },
    {
      path: '/organizations/:id/abilities',
      name: 'abilities',
      components: { default: Abilities }
    },
    {
      path: '/organizations/:id/dailyplans',
      name: 'dailyplans',
      components: { default: DailyPlans }
    },
    {
      path: '/organizations/:id/gamecustomizer',
      name: 'gamecustomizer',
      components: { default: GameCustomizer }
    },
    {
      path: '/organizations/:id/periods',
      name: 'periods',
      components: { default: Periods }
    },

    //Laboral Inclusion Views
    {
      path: '/organization/:id/gamecustomizer/workshop',
      name: 'workshop',
      components: { default: LaboralInclusion }
    },
    {
      path: '/organization/:id/gamecustomizer/workshop/:workshop_id/history',
      name: 'history',
      components: { default: Histories }
    },
    {
      path:
        '/organization/:id/gamecustomizer/workshop/:workshop_id/history/:history_id/vocabulary',
      name: 'vocabulary',
      components: { default: Vocabulary }
    },
    {
      path:
        '/organization/:id/gamecustomizer/workshop/:workshop_id/history/:history_id/sequence',
      name: 'sequence',
      components: { default: SequenceOrder }
    },
    {
      path:
        '/organization/:id/globalReport',
      name: 'globalReport',
      components: { default: GlobalReport }
    },
    //Routes for tutors views
    {
      path: '/tutor/:id/userlogin',
      name: 'userlogin',
      components: { default: LoginUser }
    },
    {
      path: '/tutor/:id/userplays/:player_id',
      name: 'userplay',
      components: { default: PlayUser }
    },
    {
      path: '/tutor/:id/reports',
      name: 'reports',
      components: { default: Reports }
    },
    {
      path: '/tutor/:id/intervention_plans',
      name: 'tutorinterventionplans',
      components: { default: InterventionPlans }
    },
    {
      path: '/tutor/:id/reports/:player_id&:name&:surname',
      name: 'player-reports',
      components: { default: PlayerReports }
    },
    //evaluation report
    {
      path:
        '/organizations/:id/intervention-plans/reports/:player_id/printeable/:id_iplan&:game_name&:ability_id',
      name: 'printeable-reports',
      components: { default: PrinteableReport }
    },
    //evaluation report tutor
    {
      path:
        '/tutor/:id/intervention-plans/reports/:player_id/printeable/:id_iplan&:game_name&:ability_id',
      name: 'printeable-reports-tutor',
      components: { default: PrinteableReport }
    },
    {
      path:
        '/tutor/:id/reports/:player_id&:name&:surname/session/:id_iplan&:game_name&:ability_id',
      name: 'session-reports',
      components: { default: SessionReport }
    },
    {
      path:
        '/tutor/:id/reports/:player_id&:name&:surname/session-graphic/:id_iplan&:game_name&:ability_id',
      name: 'session-graphic',
      components: { default: SessionGraphicReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/abilityReport/:period_id',
      name: 'abilityReport',
      components: { default: AbilityReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/abilityGraphicReport/:period_id',
      name: 'abilityGraphicReport',
      components: { default: AbilityGraphicReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/gameReport/',
      name: 'gameReport',
      components: { default: GameReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/gameGraphicReport/:period_id',
      name: 'gameGraphicReport',
      components: { default: GameGraphicReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/abilityPerGameReport/',
      name: 'abilityPerGameReport',
      components: { default: AbilityPerGameReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/interventionPlanReport/',
      name: 'interventionPlanReport',
      components: { default: InterventionPlanReport }
    },
    {
      path: '/tutor/:id/reports/:player_id/WorkReport/:period_id',
      name: 'workReport',
      components: { default: WorkReport }
    },
    //other views
    {
      path: '*',
      redirect: { name: 'home' }
    }
  ]
})
